/**
 * @author Lorenzo Acciarri - JEF
 * @email lorenzo.acciarri@jef.it
 * @create date 2022-09-28 17:53:24
 * @modify date 2022-09-28 17:53:24
 * @desc File relativo alle utilità specifiche del progetto
 */
export function deviceBackground(deviceName: string) {
    /*  */
    switch (deviceName) {
        case 'COMPAAN':
            return 'bg-[#195EA4]'
        case 'SENSARA':
            return 'bg-[#f7be4d]'
        case 'WHIZ_PAD':
            return 'bg-[#ff5a5a]'
        case 'WHIZ_TOYS':
            return 'bg-[#2b76ff]'
        case 'KOMPY_PICO':
            return 'bg-[#c7f9cc]'
        case 'TIPR':
            return 'bg-[#9dd1ff]'
        case 'COGVISAI':
            return 'bg-[#ea65ff]'
        case 'TESSA':
            return 'bg-[#ea65ff]'
    }
}
export function getImageNameFromDeviceName(deviceName: string) {

    switch (deviceName) {
        case 'COMPAAN':
            return 'Compaan.png'
        case 'SENSARA':
            return 'white-sensara-removebg-preview.png'
        case 'WHIZ_PAD':
            return 'whiz_pad.png'
        case 'WHIZ_TOYS':
            return 'whiz_toys.png'
        case 'KOMPY_PICO':
            return 'pico-products.png'
        case 'TIPR':
            return 'tipr.png'
        case 'COGVISAI':
            return 'cogvisAI.png'
        case 'TESSA':
            return 'tessa.png'

    }
}