const pushServerPublicKey = 'BHEKw8x4MLgrlxzMjg7u5d_yBNnqivxsiqdLTIr0-RQX1DIqscDvUA9x2HtdIIpuzvKcCQ0ceCBd1atgwDHHB_o';

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "Notification" in window &&  "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  /*if(!isPushNotificationSupported()) {
    throw new Error('Push notifications not supported');
  }*/
  return  await Notification.requestPermission();
}

/**
 *
 */
function registerServiceWorker(scriptURL: string | URL, options?: RegistrationOptions) {
  return navigator.serviceWorker.register(scriptURL, options);
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: pushServerPublicKey
  });
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    })
    .catch((erro)=>{alert(JSON.stringify(erro)); return null});
}

export function onServiceWorkerMessage(callback: (event: MessageEvent) => void) {
  return navigator.serviceWorker.ready.then(() => navigator.serviceWorker.addEventListener('message', callback));
}

export type PushSubscription = {
  endpoint: string,
  expirationTime: EpochTimeStamp
}

export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
};