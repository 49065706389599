import {
  BarController,
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { format, parseISO } from "date-fns";
import { Chart } from "react-chartjs-2";
import { linear } from "regression";
import {
  DEVICE_DATA_PARSED
} from "../../../../../services/bff/device-queries";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
export function BarPlusRegressionDailyAvg({
  data,
  title,
  extraField,
}: {
  data: DEVICE_DATA_PARSED<any>[];
  title: string;
  extraField: string;
}) {
  // TODO FILL THE GAPS
  const dayMap = data.reduce((acc, curr) => {
    const date = format(parseISO(curr.dateTime), "yyyy-MM-dd");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(curr.extra[extraField]);
    return acc;
  }, {} as Record<string, number[]>);
  const dayMapAvg = Object.entries(dayMap).reduce((acc, [key, val]) => {
    acc[key] = val.reduce((sum, one) => (one += sum), 0) / val.length;
    return acc;
  }, {} as Record<string, number>);

  const regression = linear(
    Object.values(dayMapAvg)
      .filter((val) => val && val > 0)
      .map((val, i) => [i, val])
  );
  const config: ChartData = {
    // type: 'scatter',
    datasets: [
      {
        data: regression.points.map(([, idx]) => regression.predict(idx)[0]),
        type: "line",
        label: "Linear Regression",
        borderColor: "red",
      },
      {
        data: Object.values(dayMapAvg),
        type: "bar",
        label: "Events",
        backgroundColor: "blue",
      },
    ],
    labels: Object.keys(dayMap),
  };
  return (
    <Chart
      type="bar"
      options={{
        plugins: {
          title: {
            display: true,
            text: title,
          },
        },
      }}
      data={config}
    />
  );
}
