import { gql } from '@apollo/client';

export type PushSubscriptionInput = {
  deviceId: string,
  expirationTime?: number,
  pushSubscriptionJSON: string
}

export const SET_PUSH_SUBSCRIPTION = gql`
  mutation SetPushSubription($deviceId: String!, $expirationTime: Int, $pushSubscriptionJSON: String!) {
    subscribeToNotifications(pushSubscription: {deviceId: $deviceId, expirationTime: $expirationTime, pushSubscriptionJSON: $pushSubscriptionJSON })
  }
`