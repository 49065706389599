import { gql } from "@apollo/client";

type CREATE_CLIENT_TYPE = {
  createClient: {
    id: string,
    name: string;
    surname: string;
  };
}

export const SET_NOTE = gql`
  mutation SetNote($description: String!, $timestamp: AWSDateTime!, $clientId: ID!, $noteType: NoteType!) {
    setNote(note: {description: $description, noteType: $noteType, timestamp: $timestamp}, clientId: $clientId) {
      description
      noteType
      timestamp
    }
}`