import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Device } from "../../../../model/device.model";
import {
  DEVICE_DATA,
  DEVICE_DATA_PARSED,
} from "../../../../services/bff/device-queries";
import { useTranslationHook } from "../../../../translations/traductios";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import dot from "./dot-single.svg";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
const DotIcon = L.icon({
  iconUrl: dot,
});
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export function KompyPicoData({
  data,
}: {
  device: Pick<
    Device,
    "clientId" | "deviceName" | "deviceId" | "start_time" | "end_time"
  >;
  from: Date;
  to: Date;
  data: DEVICE_DATA[];
}) {
  const t = useTranslationHook();
  // TODO mappa e grafico cammina
  const parsedData = data.map((one) => ({
    ...one,
    extra: JSON.parse(one.extra),
  })) as DEVICE_DATA_PARSED<{ latitude: string; longitude: string }>[];
  const lastKnownPosition = parsedData[parsedData.length - 1];
  return (
    <div className="shadow border h-fit border-[#dedede] bg-white rounded col-start-2 col-end-5 mt-5 p-5 kompy">
      <div className="hall-title font-bold">{t("_deviceData")}</div>
      <MapContainer
        center={[
          parseFloat(lastKnownPosition.extra.latitude),
          parseFloat(lastKnownPosition.extra.longitude),
        ]}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          icon={DefaultIcon}
          position={{
            lat: parseFloat(lastKnownPosition.extra.latitude),
            lng: parseFloat(lastKnownPosition.extra.longitude),
          }}
        >
          <Popup>Last Seen: {lastKnownPosition.dateTime}</Popup>
        </Marker>
        {parsedData
          .filter((one, idx) => idx < parsedData.length - 1)
          .map((one) => (
            <Marker
              icon={DotIcon}
              position={{
                lat: parseFloat(one.extra.latitude),
                lng: parseFloat(one.extra.longitude),
              }}
            >
              <Popup>At {one.dateTime}</Popup>
            </Marker>
          ))}
      </MapContainer>
    </div>
  );
}
