import { useCallback, useEffect, useState } from "react";
//the function to call the push server: https://github.com/Spyna/push-notification-demo/blob/master/front-end-react/src/utils/http.js

import {
  askUserPermission, createNotificationSubscription,
  getUserSubscription, isPushNotificationSupported, onServiceWorkerMessage, registerServiceWorker
} from "../services/push-notifications";
import { useTranslationHook } from "../translations/traductios";
//import all the function created to manage the push notifications
type NotificationSetupError = {
  name: string,
  message: string,
  code?: number
}
const pushNotificationSupported = isPushNotificationSupported();
//first thing to do: check if the push notifications are supported by the browser
export default function usePushNotifications(setup?: {
  scriptURL: string | URL,
  options?: RegistrationOptions,
  onNotification?: (event: MessageEvent) => void,
}) {
    
  const t = useTranslationHook()
  const { scriptURL, options, onNotification } = {
    ...{
      scriptURL: '/sw.js',
      onNotification: useCallback(() => { }, []),
    },
    ...setup,
  }
  let notification = undefined
  if(/iPhone|iPad/.test(navigator.userAgent) ){
    notification = window['Notification']
  }else{
    notification = Notification

  }
  const [userConsent, setSuserConsent] = useState(pushNotificationSupported ? "default" : notification);
  //to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
  //We initialize the userConsent with that value
  const [userSubscription, setUserSubscription] = useState<PushSubscription | null>(null);
  const [error, setError] = useState<false | null | NotificationSetupError>(null);
  const [loading, setLoading] = useState(true);
  const [lastMessage, setLastMessage] = useState<MessageEvent | undefined>(undefined);

  //to manage async actions

  useEffect(() => {
    if (!pushNotificationSupported) {
      return;
    }
    setLoading(true);
    setError(false);
    registerServiceWorker(scriptURL, options).then((hat) => {
      setLoading(false);
      onServiceWorkerMessage(
        event => {
          console.log(t("_recievedMessage"), event);
          setLastMessage(event);
          onNotification(event)
        }
      )
    }).catch(err => {
      console.log(err)
    });
  }, [scriptURL, options, onNotification]);
  //if the push notifications are supported, registers the service worker
  //this effect runs only the first render

  useEffect(() => {
    if(!pushNotificationSupported) {
      return;
    }
    setLoading(true);
    setError(false);
    const getExixtingSubscription = async () => {
      const existingSubscription = await getUserSubscription();
      setUserSubscription(existingSubscription);
      setLoading(false);
    };
    getExixtingSubscription();  
    
  }, []);
  //Retrieve if there is any push notification subscription for the registered service worker
  // this use effect runs only in the first render

  /**
   * define a click handler that asks the user permission,
   * it uses the setSuserConsent state, to set the consent of the user
   * If the user denies the consent, an error is created with the setError hook
   */
  const askUserPermissionAction = () => {
    if(!pushNotificationSupported) {
      return;
    }
    setLoading(true);
    setError(false);
    return askUserPermission().then(consent => {
      setSuserConsent(consent);
      if (consent !== "granted") {
        setError({
          name: t("_consentDenied"),
          message: t("_youDeniedtheConsent..."),
          code: 0
        });
      }
      setLoading(false);
      return consent;
    });
  };
  //

  /**
   * define a click handler that creates a push notification subscription.
   * Once the subscription is created, it uses the setUserSubscription hook
   */
  const susbribeToPushNotification = async () => {
    if(!pushNotificationSupported) {
      return null;
    }
    /* Non so se è giusto */
    if(!userSubscription){
      setLoading(true);
      setError(false);
      try {
        const subscription = await createNotificationSubscription()
        setUserSubscription(subscription);
        setLoading(false);
        return subscription;
      } catch (err: any) {
        console.error(t("_coudn'tCreateTheNotificationSubscription"), err, t("_name:"), err.name, t("_message:"), err.message, t("_code:"), err.code);
        setError(err as Error);
        setLoading(false);
      };
    }
  };

  /**
   * returns all the stuff needed by a Component
   */
  return {
    userConsent,
    pushNotificationSupported,
    askUserPermissionAction: askUserPermissionAction,
    susbribeToPushNotification: susbribeToPushNotification,
    userSubscription,
    error,
    loading,
    lastMessage,
  };
}