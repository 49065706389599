import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Snackbar, styled } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { Fragment, useState } from "react";
import { Client, NotificationType } from "../../model/client.model";
import { SET_NOTIFICATION_TYPE } from "../../services/bff/client-mutations";
import { useTranslationHook } from "../../translations/traductios";
import Spinner from "./LoadingSpinner";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export function ClientNotificationSetup({ client }: { client: Client }) {

  const t = useTranslationHook()
  const [isRequestPending, setIsRequestPending] = useState<boolean>(false)
  const [opened, setOpened] = useState(false);
  const [notificationType, setNotificationType] = useState<NotificationType>(
    client.notificationType || NotificationType.NULL
  );
  const [snackBar, setSnackBar] = useState<
    | {
      opened: boolean;
      message: string;
    }
    | undefined
  >(undefined);
  let [setNotificationTypeMutation] = useMutation(
    SET_NOTIFICATION_TYPE,
    {
      onCompleted: async (data) => {
        setNotificationType(data.setClientNotificationType.notificationType);
      },
      onError: (error) => {
        setSnackBar({
          opened: true,
          message: t("_errorDuringOperation" ),
        });
      },
    }
  );

  const handleNotificationTypeSelected = (
    notificationType: NotificationType
  ) => {
    setOpened(false);
    setIsRequestPending(true)
    setNotificationTypeMutation({
      variables: {
        clientId: client.id,
        notificationType,
      },
    }).then(()=>{
      setIsRequestPending(false)
    });
  };

  return (
    <>
      <HtmlTooltip
        className="ml-5"
        open={opened}
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        title={
          <Fragment>
            {/* <Typography color="inherit">Tooltip with HTML</Typography>
                                        <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                                        {"It's very engaging. Right?"} */}
            <li>
              <ul>
                <button
                  onClick={() => {
                    handleNotificationTypeSelected(NotificationType.ALL);
                  }}
                  className={`flex p-2 my-1 w-full rounded haal-notification-item ${notificationType === NotificationType.ALL
                      ? "haal-notification-item-active"
                      : "haal-notification-item"
                    } items-center text-gray-400`}
                >
                  <FontAwesomeIcon
                    className="h-6 w-6"
                    icon={["fas", "bell"]}
                  ></FontAwesomeIcon>
                  <div className="haal-title ml-3"> {t("_allNotifications")} </div>
                </button>
              </ul>
              <ul>
                <button
                  onClick={() => {
                    handleNotificationTypeSelected(NotificationType.ONLY_ALARM);
                  }}
                  className={`flex p-2 my-1 w-full rounded haal-notification-item ${notificationType === NotificationType.ONLY_ALARM
                      ? "haal-notification-item-active"
                      : "haal-notification-item"
                    } items-center text-gray-400`}
                >
                  <FontAwesomeIcon
                    className="h-6 w-6"
                    icon={["fas", "exclamation"]}
                  ></FontAwesomeIcon>
                  <div className="haal-title ml-3"> {t("_OnlyRelevant")} </div>
                </button>
              </ul>
              <ul>
                <button
                  onClick={() => {
                    handleNotificationTypeSelected(NotificationType.NULL);
                  }}
                  className={`flex p-2 my-1 w-full rounded haal-notification-item ${notificationType === NotificationType.NULL
                      ? "haal-notification-item-active"
                      : "haal-notification-item"
                    } items-center text-gray-400`}
                >
                  <FontAwesomeIcon
                    className="h-6 w-6 text-gray-400"
                    icon={["fas", "bell-slash"]}
                  ></FontAwesomeIcon>
                  <div className="haal-title ml-3"> {t("_noNotifications")} </div>
                </button>
              </ul>
            </li>
          </Fragment>
        }
      >
        <button className="ml-4">
          {notificationType === NotificationType.ALL && (
            <>
              {
                isRequestPending === true && (
                  <Spinner />
                )
              }
              {
                isRequestPending === false && (
                  <FontAwesomeIcon
                    className="h-6 w-6 text-gray-400"
                    icon={["fas", "bell"]}
                  ></FontAwesomeIcon>
                )
              }
            </>
          )}
          {notificationType === NotificationType.ONLY_ALARM && (
            <>
              {
                isRequestPending=== true && (
                  <Spinner/>
                )
              }
              {
                isRequestPending=== false&&(
                <FontAwesomeIcon
                className="h-6 w-6 text-gray-400"
                icon={["fas", "exclamation"]}
              ></FontAwesomeIcon>
                )
              }
            </>
          )}
          {notificationType === NotificationType.NULL && (
            <>
              {isRequestPending=== true&&(
                <Spinner />
              )}
              {isRequestPending===false&&(
                <FontAwesomeIcon
                className="h-6 w-6 text-gray-400"
                icon={["fas", "bell-slash"]}
              ></FontAwesomeIcon>
              )}
            </>
          )}
          {/* <FontAwesomeIcon className="h-6 w-6 text-gray-300" icon={['fas', 'bell']}></FontAwesomeIcon> */}
        </button>
      </HtmlTooltip>
      {snackBar && (
        <Snackbar
          style={{
            backgroundColor: "#1976d2",
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackBar.opened}
          onClose={() => {
            setSnackBar({
              message: "",
              opened: false,
            });
          }}
          autoHideDuration={2000}
          message={snackBar.message}
        />
      )}
    </>
  );
}
