import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Spinner() {
  return (
    // <div style={{ width: '20px', margin: '10', display: 'block' }}>
    //   <ClipLoader color="silver" size={30}/>
    // </div>
    <FontAwesomeIcon
      className={`h-6 w-6 spinner-color`}
      icon={["fas", "spinner"]}
      spin={true}
    />
  );
};

export default Spinner;