import { useQuery } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useState } from "react"
import { Client } from "../../../model/client.model"
import { User } from "../../../model/user.model"
import { USER_CAREGIVERS, USER_CAREGIVERS_TYPE } from "../../../services/bff/caregivers-queries"
import { cellStyle } from "../../../services/haal-mui-theme/haal-mui-theme"
import { useTranslationHook } from "../../../translations/traductios"
import { cleanEnum } from "../../../utils/utils"
import { ShareClient } from "./ShareClient"

export function ClientCaregivers(
    props: {
        client: Client
    }
) {

    const t = useTranslationHook()
    
    const [userCaregivers, setUserCaregivers] = useState<User[]>([])
    const { loading, error, data } = useQuery<USER_CAREGIVERS_TYPE>(USER_CAREGIVERS, {
        variables: {
            id: props.client.id
        },
        onCompleted: (data) => {
            /* TODO: DA QUESTO DEVO ESCLUDERE QUELLI GIA' ASSEGNATI */
            const tmpCaregivers = data.getClient.careGivers.map((el) => {
                return { ...el }
            }) as User[]
            setUserCaregivers(tmpCaregivers)
            /* setUserCaregivers([]); */
        },
        onError: (err) => {
            console.log(err)
        }
    });
    const [addNewCaregiverModalOpenState, setAddNewCaregiverModalOpenState] = useState<boolean>(false)
    return (
        <>
            <div className="flex justify-between items-center">

                <div className="haal-title">
                    {t("_caregivers")}
                </div>

                <button onClick={() => {
                    setAddNewCaregiverModalOpenState(true)
                }}>
                    <div className="icon-wrapper h-10 w-10 border border-[#dedede] black mr-4" style={{ margin: 0 }}>
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </div>
                </button>
            </div>

            <div className="mt-5">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} style={{ fontWeight: 'bold' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ ...cellStyle }}>{t("_username")}</TableCell>
                                <TableCell style={{ ...cellStyle }} align="left">{t("_email")}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {loading &&

                                < TableRow className="bg-[#F7F7F7]">
                                    <TableCell align="center">
                                        <div className="flex items-center">
                                            <Skeleton variant="circular" width={40} height={40} />
                                            <Skeleton className="w-full ml-5" height={30} variant="text" sx={{ fontSize: '1rem' }} />
                                        </div>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Skeleton height={30} variant="text" sx={{ fontSize: '1rem' }} />
                                    </TableCell>
                                </TableRow>
                            }
                            {userCaregivers.map((e: User) => {

                                return (
                                    < TableRow className="bg-[#F7F7F7]">
                                        <TableCell>
                                            <div className="flex items-center">

                                                <div className={`icon-wrapper h-12 w-12 border border-[#dedede] black mr-5`}>
                                                    <img className="h-10" src={process.env.PUBLIC_URL + '/garegiver-male.png'} alt="" />
                                                </div>
                                                <div className="haal-subtitle font-bold">
                                                    {cleanEnum(e.username)}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div className="haal-subtitle">
                                                {e?.email}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
            {/* <div className="my-2">
                {caregivers.length === 0 &&
                    <div className="haal-subtitle">No caregivers assigned.</div>
                }
                {caregivers.length > 0 &&
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name Surname</TableCell>
                                    <TableCell align="right">Email</TableCell>
                                    <TableCell align="right">Age</TableCell>
                                    <TableCell align="right">Role</TableCell>
                                    <TableCell align="right">Location</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow
                                    key={1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Lorem, ipsum.
                                    </TableCell>
                                    <TableCell align="right">Lorem, ipsum.</TableCell>
                                    <TableCell align="right">Lorem, ipsum.</TableCell>
                                    <TableCell align="right">Lorem, ipsum.</TableCell>
                                    <TableCell align="right">Lorem, ipsum.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div> */}
            {addNewCaregiverModalOpenState &&

                <ShareClient caregiversAlreadyAssigned={userCaregivers} close={() => {

                    setAddNewCaregiverModalOpenState(false)

                    /* *
                    * *Aggiorno Il client corrente 
                    */
                    /* listClients().then(clients => {
                        this.setState({
                            clients,
                            patientsFiltered: clients
                        })
                    })
                     this.render() */

                }} />
            }
        </>
    )
}