import { gql } from "@apollo/client";
import { Accident, Client, ClientCognitiveStatus, ClientPhysicalStatus, ClientSleepStatus, ClientStatus, ClientWellbeingStatus, EmergencyContact, Note, NotificationType } from "../../model/client.model";
import { Device } from "../../model/device.model";
import { Situation } from "../../model/situation.model";
import { User } from "../../model/user.model";
import { DateToString } from "../../model/utils";
import { getApolloClient } from "../GraphQL/apollo-client";
export const LIST_CLIENTS = gql`
    query listClients($onlyPreferred: Boolean!){
        listClients(onlyPreferred: $onlyPreferred) {
            items {
                id,
                anagraphic {
                    name
                    surname
                    age
                    weight
                    location
                    locationType
                    dementiaLevel
                    gender
                    profilePhotoURL
                    profileAvatar
                },
                preferred
                notificationType
                
            }
            nextToken
        }
    }
`;
export type LIST_CLIENTS_TYPE = {
    listClients: {
        items: Client[],
        nextToken: string
    };
};
export type LIST_CLIENTS_ARGUMENTS_TYPE = {
    onlyPreferred: boolean
};

/*  
* Function that retrives the list of the clients.
*///(onlyPreferred: $onlyPreferred)
export const listClients = (onlyPreferred?: boolean): Promise<{
    items: Client[], nextToken?: string
}> => {

    return getApolloClient().query<LIST_CLIENTS_TYPE>({
        query: LIST_CLIENTS,
        variables: {
            onlyPreferred: onlyPreferred || false
        }
    }).then(({ error, loading, data }) => {
        return new Promise((resolve, reject) => {
            const clients = data.listClients
            resolve(clients)
        })
    });
}
/* type Note = GenericNote & Omit<Note,'timestamp'> & {
    noteType: NoteType
    timestamp: string
    description: string
    author: User
    client: Client
} */
export type SingleClientT = Client & {
    devices: Pick<Device, 'clientId' | 'deviceName' | 'deviceId' | 'start_time' | 'end_time'>[]
    accidents: Accident[]
    notes: Note[]
    emergencyContacts?: EmergencyContact[]
    notificationType?: NotificationType
    situations: {
        uid: User['sub']
        clientId: SingleClientT['id']
        timestamp: Situation['timestamp']
        eventType: Situation['eventType']
        respondStatus: Situation['respondStatus']
        author: User
        source?: string
    }[],
    wellbeingStatus: {
        items: Omit<DateToString<ClientWellbeingStatus, 'date'>, 'type'>[]
    },
    sleepStatus: {
        items: Omit<DateToString<ClientSleepStatus, 'date'>, 'type'>[]
    },
    physicalStatus: {
        items: Omit<DateToString<ClientPhysicalStatus, 'date'>, 'type'>[]
    },
    cognitiveStatus: {
        items: Omit<DateToString<ClientCognitiveStatus, 'date'>, 'type'>[]
    },
}

export type GET_CLIENT_TYPE = {
    getClient: SingleClientT,
};
/* devices {
    name
    deviceId
    timestamp
} */

export const GET_CLIENT_QUERY = gql`
    query GetClient($id: String!){
        getClient(id: $id){
            id
            anagraphic {
                name
                surname
                age
                weight
                dementiaLevel
                born
                profilePhotoURL
                profileAvatar
                gender
                location
                locationType
            }
            notes {
                description
                noteType
                timestamp
                author {
                    email
                    given_name
                    sub
                    family_name
                    username
                }
            }
            situations{
                clientId
                timestamp
                respondStatus
                eventType
                author {
                    email
                    given_name
                    sub
                    family_name
                    username
                }
            }
            emergencyContacts{
                name
                phone
                timestamp
            }
            devices{
                clientId
                deviceName
                deviceId
                start_time
                end_time
            }
            sleepStatus: status(type: SLEEP, limit: 100) {
                items {
                    date
                    status
                    urgency
                }
            }
            wellbeingStatus: status(type: WELLBEING, limit: 100) {
                items {
                    date
                    status
                    urgency
                }
            }
            physicalStatus: status(type: PHYSICAL, limit: 100) {
                items {
                    date
                    status
                    urgency
                }
            }
            cognitiveStatus: status(type: COGNITIVE, limit: 100) {
                items {
                    date
                    status
                    urgency
                }
            }
            preferred
            notificationType
        }
    }`;
export type GET_CLIENT_CAREGIVERS_TYPE = {
    caregivers: User[]
}
export const GET_CLIENT_DEVICES = gql`
query GetClient($id: String!){
    getClient(id: $id){
        id
        anagraphic {
                name
                surname
                age
                weight
                dementiaLevel
                born
                gender
                location
                profilePhotoURL
                profileAvatar
        }
        devices{
            deviceName
            deviceId
            start_time
            end_time
        }
    }
}`;

export const GET_CLIENT_CAREGIVERS = gql`
query GetClientCaregivers($id: String!){
    getClient(id: $id){
        caregivers{
            username
            email
        }
    }
}`
