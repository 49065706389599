/*fernanda's convertion class to function */

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { faArrowLeft, faArrowRight, faCalendarAlt, faCog, faExclamationCircle, faHome, faMobile, faSignOut, faTv, faUser } from "@fortawesome/free-solid-svg-icons"
import { Alert } from "@mui/material"
import { Navigate, Route, Routes } from "react-router-dom"
import { DevicesList } from "../DevicesList/DevicesList"
import Home from "../Home/Home"
import { Info } from "../Info/Info"
import { Profile } from "../Profile/Profile"
import { SingleClient } from "../SingleClient/SingleClient"
import { Activities } from "../SingleClient/pieces/Activities"
import PersistentDrawerLeft from "./pieces/MuiDrawer"

library.add(
  fab,
  faUser,
  faHome,
  faCalendarAlt,
  faCog,
  faExclamationCircle,
  faArrowLeft,
  faArrowRight,
  faTv,
  faMobile,
  faSignOut
);

function Desktop() {

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route element={<PersistentDrawerLeft />}>
          <Route path="home" element={<Home />} />
          <Route path="profile" element={<Profile />} />
          <Route path="info" element={<Info />} />
          <Route path="devices" element={<DevicesList />} />
          <Route path="single-profile">
            <Route path=":clientId">
              <Route path="" element={<SingleClient />} />              
            </Route>
          </Route>
          <Route path="alert" element={<Alert />} />
        </Route>


      </Routes>
    </>
  )
}
export default Desktop;