import { OverwrittenWithStartAndEnd } from "../utils/utils";
import { Client } from "./client.model";

export type Device = {
    clientId: string;
    userId: string
    deviceName: DeviceName
    lastStatus: any
    deviceId: string
    start_time: string
    end_time: string
    assignor: string | undefined
    client?: {
        anagraphic: {
            name: Client['anagraphic']['name']
            surname: Client['anagraphic']['surname']
        }
    }
}
export type DeviceOverided = OverwrittenWithStartAndEnd<Device>

export enum DeviceName {
    SENSARA = "SENSARA",
    KOMPY_PICO = "KOMPY_PICO",
    WHIZ_PAD = "WHIZ_PAD",
    COMPAAN = "COMPAAN",
    TIPR = "TIPR",
    COGVISAI = "COGVISAI",
    WHIZ_TOYS = "WHIZ_TOYS",
    TESSA = "TESSA",
}