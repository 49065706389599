import { useMutation, useQuery as useQueryApollo } from "@apollo/client";
import {
    library
} from '@fortawesome/fontawesome-svg-core';
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faArchive, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Modal, Snackbar, Tab, Tabs } from "@mui/material";
import Typography from '@mui/material/Typography';
import { parse } from "date-fns";
import parseISO from "date-fns/fp/parseISO";
import queryString from 'query-string';
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Client, ClientCognitiveStatus, ClientPhysicalStatus, ClientSleepStatus, ClientStatus, ClientWellbeingStatus, Note, NoteOverided } from "../../model/client.model";
import { Situation } from "../../model/situation.model";
import { ARCHIVE_CLIENT } from "../../services/bff/client-mutations";
import { GET_CLIENT_QUERY, GET_CLIENT_TYPE, SingleClientT } from "../../services/bff/client-queries";
import { modalMuiStyle, textBold } from "../../services/haal-mui-theme/haal-mui-theme";
import { useTranslationHook } from "../../translations/traductios";
import { PreferredButton } from "../Home/pieces/ClientPreferredButton";
import { ClientNotificationSetup } from '../shared/ClientNotificaionSetup';
import { CreateUpdateClient } from "../shared/CreateUpdateClient";
import { ClientCaregivers } from "./pieces/ClientCaregivers";
import { ClientDevices } from "./pieces/ClientDevices";
import { ClientInfo } from "./pieces/ClientInfo";
import { ClientNotes } from "./pieces/ClientNotes";
import { ClientSituations } from "./pieces/ClientSituations";
import { StatusesDiagram } from "./pieces/StatusesDiagram";
import { Activities } from "./pieces/Activities";

library.add(
    fab,
    faPencil,
    faArchive
)
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    sx: {
        [key: string]: string | number
    };
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, sx, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ ...sx }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export function SingleClient() {
    //Sezione relativa al controllo di un dispositivo selezionato, utile se l'utente viene dalla pagina dei disposotivi
    let navigate = useNavigate();
    const params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();


    const t = useTranslationHook()

    const query = queryString.parse(window.location.search)
    const [client, setClient] = useState<SingleClientT | undefined>(undefined)
    const [currentSearch, setCurrentSearch] = useState<string>('')
    //const [noteModalState, setNoteModalState] = useState<boolean>(true)
    const [noteClient, setNoteClient] = useState<NoteOverided[]>([])
    const [situations, setSituations] = useState<Situation[]>([])
    const [showLegendaModalOpenState, setShowLegendaModalOpenState] = useState<boolean>(false)
    const [wellbeingStatus, setWellbeingStatus] = useState<ClientWellbeingStatus | undefined>(undefined);
    const [physicalStatus, setPhysicalStatus] = useState<ClientPhysicalStatus | undefined>(undefined);
    const [cognitiveStatus, setCognitiveStatus] = useState<ClientCognitiveStatus | undefined>(undefined);
    const [sleepStatus, setSleepStatus] = useState<ClientSleepStatus | undefined>(undefined);

    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)

    const [updateClientModalOpenState, setUpdateClientModalOpenState] = useState<boolean>(false)
    const [archiveClientModalOpenState, setArchiveClientModalOpenState] = useState<boolean>(false)

    // Unpacking and retrieve id
    const id = params.clientId as Client['id']
    //let client: GET_CLIENT_TYPE['getClient'] | undefined;

    const [tabValue, setTabValue] = useState(query['deviceId'] !== undefined ? 1 : 0); //Nel caso in cui è stato passato un deviceId nei query params allora vado direttamente alla tab

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { error } = useQueryApollo<GET_CLIENT_TYPE>(GET_CLIENT_QUERY, {
        variables: { id },
        onCompleted: (data) => {
            let tmpClient = {
                ...data?.getClient
            }
            let tmpNotes = tmpClient.notes
            const notes: NoteOverided[] = tmpNotes.map((current: Note) => ({
                ...current,
                timestamp: parseISO(current.timestamp)
            }))
            if (tmpClient.anagraphic?.born && typeof tmpClient.anagraphic?.born === "string") {
                const tmpBorn = parse(tmpClient.anagraphic?.born, "yyyy-MM-dd", new Date())
                setClient({
                    ...tmpClient,
                    anagraphic: {
                        ...tmpClient.anagraphic,
                        born: tmpBorn
                    },
                    accidents: [],
                })
            } else {
                setClient({
                    ...tmpClient,
                    accidents: [],
                })
            }
            setNoteClient(notes);
            setSituations(tmpClient.situations);
        }
    });

    useEffect(() => {
        if (!client?.wellbeingStatus.items[0]) {
            setWellbeingStatus(undefined);
        } else {
            setWellbeingStatus({
                ...client?.wellbeingStatus.items[0],
                type: "WELLBEING",
                date: parseISO(client?.wellbeingStatus.items[0].date)
            });
        }
        if (!client?.cognitiveStatus.items[0]) {
            setCognitiveStatus(undefined);
        } else {
            setCognitiveStatus({
                ...client?.cognitiveStatus.items[0],
                type: "COGNITIVE",
                date: parseISO(client?.cognitiveStatus.items[0].date)
            });
        }
        if (!client?.physicalStatus.items[0]) {
            setPhysicalStatus(undefined);
        } else {
            setPhysicalStatus({
                ...client?.physicalStatus.items[0],
                type: "PHYSICAL",
                date: parseISO(client?.physicalStatus.items[0].date)
            });
        }
        if (!client?.sleepStatus.items[0]) {
            setSleepStatus(undefined);
        } else {
            setSleepStatus({
                ...client?.sleepStatus.items[0],
                type: "SLEEP",
                date: parseISO(client?.sleepStatus.items[0].date)
            });
        }

    }, [client]);

    if (error) {
        //alert(t("_erroreCaricamentoUtente"))
        console.log(error)
    }
    let [archiveClient, archivedClient] = useMutation(ARCHIVE_CLIENT, {
        onCompleted: (archivedClient) => {
            setSnackBar({
                opened: true,
                message: t("_errorArchivingClient")
            })
            setTimeout(() => {
                setArchiveClientModalOpenState(false)
            }, 2500)
            navigate("../")
        },
        onError: () => {
            setSnackBar({
                opened: true,
                message: t("_errorDeletingClient")
            })
        }
    });
    const handleArchiveClient = () => {
        if (client) {
            archiveClient(({
                variables: {
                    clientId: client.id
                }
            }));
            setArchiveClientModalOpenState(false);
        }
    }
    const statusClick = useCallback((typeStatus: ClientStatus['type']) => {
        setTabValue(3)

    }, [])
    return (
        <div className="px-5">
            {client &&
                <>
                    <div className="flex justify-between my-5">
                        <div className="flex items-center my-2">
                            <Link to="/home">
                                <div className="icon-wrapper h-10 w-10 border mr-4">
                                    <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                                </div>
                            </Link>
                            <div className="haal-title mr-4">{client?.anagraphic?.name}</div>
                            <PreferredButton client={client} />
                            <ClientNotificationSetup client={client}></ClientNotificationSetup>
                        </div>

                        <div className="flex">
                            <button onClick={() => {
                                setUpdateClientModalOpenState(true)
                            }}>
                                <div className="icon-wrapper h-10 w-10 border border-[#dedede] black !mr-2" style={{ margin: 0 }}>
                                    <FontAwesomeIcon icon={['fas', 'pencil']} />
                                </div>
                            </button>
                            <button onClick={() => {
                                setArchiveClientModalOpenState(true)
                            }}>
                                <div className="icon-wrapper h-10 w-10 border border-[#dedede] black" style={{ margin: 0 }}>
                                    <FontAwesomeIcon icon={['fas', 'archive']} />
                                </div>
                            </button>
                        </div>
                    </div>
                    <Divider />
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-start-1">
                            <ClientInfo client={client} />
                            <ClientSituations client={client} situations={situations} />
                            <ClientNotes client={client} notes={noteClient} />
                        </div>
                        <div className="col-start-2 col-end-5 mt-5">
                            <div className="shadow border h-fit border-[#dedede] bg-white rounded">

                                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab style={{ ...textBold }} label={t("_home")} {...a11yProps(0)} />
                                    <Tab style={{ ...textBold }} label={t("_devices")} {...a11yProps(1)} />
                                    <Tab style={{ ...textBold }} label={t("_careGivers")} {...a11yProps(2)} />
                                    <Tab style={{ ...textBold }} label={t("_activities")} {...a11yProps(3)} />
                                </Tabs>
                                <Divider />
                                <TabPanel value={tabValue} sx={{ p: '2px 2.5px 15px 2.5px' }} index={0}>
                                    <div className="flex justify-between mb-4" style={{ backgroundColor: "#195EA4" }}>
                                        <div className="client-status" >
                                            <p>
                                                {t("_clientStatus")} <br /> {t("_theClientHas...")}
                                            </p>
                                        </div>
                                        <IconButton style={{ backgroundColor: "#195EA4", borderRadius: "0px", color: "white" }} onClick={() => { setShowLegendaModalOpenState(!showLegendaModalOpenState) }}>
                                            <InfoIcon sx={{ m: '4px 10px' }} />
                                        </IconButton>
                                    </div>
                                    {showLegendaModalOpenState && (

                                        <div>
                                            <Box sx={modalMuiStyle} style={{ zIndex: 999 }}>
                                                <div>
                                                    <div className="flex justify-between items-center my-3" >
                                                        <div className="haal-title mr-4">{t("_clientStatusInformation")}</div>
                                                        <Button>
                                                            <CloseIcon onClick={() => {
                                                                setShowLegendaModalOpenState(false)
                                                            }} />
                                                        </Button>
                                                    </div>
                                                    <div className="haal-subtitle underline my-2">{t("_theColorOfTheCircles...")}</div>
                                                    <div className="">
                                                        <div className="haal-title mr-4">{t("_caption")}</div>
                                                        <div className="mt-3 grid grid-cols-5">
                                                            <div className="flex items-center my-2">
                                                                <div className="haal-client-status-circle-wrapper">
                                                                    <div className="haal-client-status-common-circle haal-client-status-circle-red"></div>
                                                                </div>
                                                                <div className="haal-client-status-label"> {t("_critical")} </div></div>
                                                            <div className="flex items-center my-2">
                                                                <div className="haal-client-status-circle-wrapper">
                                                                    <div className="haal-client-status-common-circle haal-client-status-circle-orange"></div>
                                                                </div>
                                                                <div className="haal-client-status-label"> {t("_average")} </div></div>
                                                            <div className="flex items-center my-2">
                                                                <div className="haal-client-status-circle-wrapper">
                                                                    <div className="haal-client-status-common-circle haal-client-status-circle-blue"></div>
                                                                </div>
                                                                <div className="haal-client-status-label"> {t("_discreet")} </div></div>
                                                            <div className="flex items-center my-2">
                                                                <div className="haal-client-status-circle-wrapper">
                                                                    <div className="haal-client-status-common-circle haal-client-status-circle-green"></div>
                                                                </div>
                                                                <div className="haal-client-status-label"> {t("_good")} </div></div>
                                                            <div className="flex items-center my-2">
                                                                <div className="haal-client-status-circle-wrapper">
                                                                    <div className="haal-client-status-common-circle haal-client-status-circle-grey"></div>
                                                                </div>
                                                                <div className="haal-client-status-label"> {t("_unknown")} </div></div>

                                                            <div className="flex items-center my-2">
                                                                <div className="haal-client-status-circle-wrapper">
                                                                    <div className="haal-client-status-common-circle haal-client-status-circle-size-big haal-client-status-circle-black"></div>
                                                                </div>
                                                                <div className="haal-client-status-label"> {t("_highLevel")}<br /> {t("_ofUrgency")}</div></div>
                                                            <div className="flex items-center my-2">
                                                                <div className="haal-client-status-circle-wrapper">
                                                                    <div className="haal-client-status-common-circle haal-client-status-circle-size-medium haal-client-status-circle-black"></div>
                                                                </div>
                                                                <div className="haal-client-status-label"> {t("_middleLevel")} <br /> {t("_ofUrgency")} </div></div>
                                                            <div className="flex items-center my-2">
                                                                <div className="haal-client-status-circle-wrapper">
                                                                    <div className="haal-client-status-common-circle haal-client-status-circle-size-small haal-client-status-circle-black"></div>
                                                                </div>
                                                                <div className="haal-client-status-label"> {t("_lowLevel")} <br /> {t("_ofUrgency")} </div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box >
                                        </div>
                                    )}

                                    <StatusesDiagram
                                        statusClick={statusClick}
                                        gender={client.anagraphic.gender}
                                        profilePhotoURL={client.anagraphic.profilePhotoURL}
                                        profileAvatar={client.anagraphic.profileAvatar}
                                        wellbeingStatus={wellbeingStatus}
                                        sleepStatus={sleepStatus}
                                        physicalStatus={physicalStatus}
                                        cognitiveStatus={cognitiveStatus}
                                    />
                                </TabPanel>
                                <TabPanel value={tabValue} sx={{ p: 3 }} index={1}>
                                    <ClientDevices devices={client.devices} updateDevices={() => {

                                    }} />
                                </TabPanel>
                                <TabPanel value={tabValue} sx={{ p: 3 }} index={2}>
                                    <ClientCaregivers client={client} />
                                </TabPanel>
                                <TabPanel value={tabValue} sx={{ p: 3 }} index={3}>
                                    <Activities />
                                </TabPanel>
                            </div>
                            <div className="shadow border h-fit border-[#dedede] bg-white rounded" style={{ margin: "8px auto" }}>
                                <div className="flex justify-between position-fixed ">
                                    <div className="red-decorator " />
                                    <div className="inline-flex flex-col py-1" style={{ fontSize: '10.5pt' }}>
                                        {t("_pleaseBeAwareThat...")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {snackBar &&
                <Snackbar
                    style={{
                        backgroundColor: '#1976d2',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackBar.opened}
                    onClose={() => {
                        setSnackBar({
                            message: '',
                            opened: false,
                        })
                    }}
                    autoHideDuration={2000}
                    message={snackBar.message}
                />
            }
            {/* MODALE PER LA MODIFICA DEL CLIENT */}
            {updateClientModalOpenState && (
                < Modal
                    open={updateClientModalOpenState}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <CreateUpdateClient client={client} close={() => {

                        setUpdateClientModalOpenState(false)

                        /* *
                        * *Aggiorno Il client corrente 
                        */
                        /* listClients().then(clients => {
                            this.setState({
                                clients,
                                patientsFiltered: clients
                            })
                        })
                         this.render() */

                    }} />


                </Modal>
            )
            }
            {archiveClientModalOpenState && (
                <Dialog
                    open={archiveClientModalOpenState}
                    /* TransitionComponent={Transition} */
                    keepMounted
                    onClose={() => { setArchiveClientModalOpenState(false) }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    {/* <DialogTitle>{`Are you sure you want to delete all informations about ${client.anagraphic.name + " " + client.anagraphic.surname}?`}</DialogTitle> */}
                    <DialogTitle>{t("_areYouSureArchive...", { "name": client!.anagraphic.name, "surname": client!.anagraphic.surname })}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t("_thisActionWillAlsoArchive...")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setArchiveClientModalOpenState(false) }}> {t("_close")} </Button>
                        <Button className="haal-delete-button" onClick={() => { handleArchiveClient() }}> {t("_archive")} </Button>
                    </DialogActions>
                </Dialog>
            )
            }


        </div >
    )
}