import { useQuery } from '@apollo/client';
import { AccountCircleRounded } from '@mui/icons-material';
import InfoIcon from "@mui/icons-material/Info";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MemoryRoundedIcon from '@mui/icons-material/MemoryRounded';
import MenuIcon from '@mui/icons-material/Menu';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import TvRoundedIcon from '@mui/icons-material/TvRounded';
import { Badge, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { LIST_CAREGIVER_NOTIFICATIONS, LIST_CAREGIVER_NOTIFICATIONS_TYPE } from '../../../services/bff/caregivers-queries';
import { useTranslationHook } from '../../../translations/traductios';
import { NotificationList } from '../../shared/NotificationList';
import { PushNotificationSupport } from '../../shared/PushNotificationSupport';
import AccountMenu from './AccountMenu';
import LanguagesMenu from './LanguagesMenu';

const drawerWidth = 350;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { data, loading } = useQuery<LIST_CAREGIVER_NOTIFICATIONS_TYPE>(LIST_CAREGIVER_NOTIFICATIONS);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const t = useTranslationHook()

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} >
        <Toolbar className="haal-b-bg">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <Badge badgeContent={loading ? undefined : data?.listNotifications.items.length} color="error" showZero max={100}>
              <MenuIcon />
            </Badge>
          </IconButton>

          <Typography variant="h6" noWrap className="w-full flex haal-subtitle p-3 justify-center text-white ">
            {t("_HAALDashbordClientInsightsFromMultipleDevice")}
          </Typography>
          <LanguagesMenu />
          <AccountMenu />
          <Box>
            <PushNotificationSupport />
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'var(--haal-blue)'
          },

        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton className="menu-links" onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        {/*Clients notification*/}
        <div className="px-5" style={{ height: '58%' }}> {/*paddingLeft&Right*/}
          <NotificationList />
        </div>
        <div className={`flex flex-col sidebar-icon-wrapper  sidebar-menu px-5`} style={{ height: '35%' }}>
          <List className='menu-links'>
            <ListItem disablePadding>
              <ListItemButton component={Link} to={"/"}>
                <ListItemIcon>
                  <HomeRoundedIcon sx={{ fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary={t("_home")} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to={"/devices"}>
                <ListItemIcon>
                  <MemoryRoundedIcon sx={{ fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary={t("_devices")} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon sx={{ fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary={t("_platformSettings")} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ReportProblemRoundedIcon sx={{ fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary={t("_helpdesk")} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to={"/tv"}>
                <ListItemIcon>
                  <TvRoundedIcon sx={{ fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary={t("_tvMode")} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to={"/profile"}>
                <ListItemIcon>
                  <AccountCircleRounded sx={{ fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary={t("_profile")} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to={"/info"}>
                <ListItemIcon>
                  <InfoIcon sx={{ fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary={t("_info")} />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        {/* 
        <Divider />
        <List>
          {['Home', 'Devices', 'Platform Settings', 'Helpdesk', 'TV Mode'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>

                <ListItemIcon>
                  {index === 0 && <HomeRoundedIcon />}
                  {index === 1 && <MemoryRoundedIcon />}
                  {index === 2 && <SettingsIcon />}
                  {index === 3 && <ReportProblemRoundedIcon />}
                  {index === 4 && <TvRoundedIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />

              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        {/*from layout */}
        <Outlet />
        {/* <div
          className={`w-full h-full rounded ${open
            ? "padding-for-sidebar-open"
            : "padding-for-sidebar-close"
            }`}
        >
        </div> */}

      </Main>
    </Box>
  );
}