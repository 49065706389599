import {
  BarController,
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  ScatterController,
  ScatterDataPoint,
  Title,
  Tooltip,
} from "chart.js";
import { format, parseISO } from "date-fns";
import { Chart } from "react-chartjs-2";
import { Device } from "../../../../model/device.model";
import {
  DEVICE_DATA,
  LIST_DEVICE_DATA_TYPE,
} from "../../../../services/bff/device-queries";
import { useTranslationHook } from "../../../../translations/traductios";
import { BarPlusRegressionDailyCount } from "./generics/BarPlusRegressionDailyCount";
ChartJS.register(
  LinearScale,
  ScatterController,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title
);

export function SensaraData({
  data,
}: {
  device: Pick<
    Device,
    "clientId" | "deviceName" | "deviceId" | "start_time" | "end_time"
  >;
  from: Date;
  to: Date;
  data: DEVICE_DATA[];
}) {
  const t = useTranslationHook();

  return (
    <div className="shadow border h-fit border-[#dedede] bg-white rounded col-start-2 col-end-5 mt-5 p-5">
      <div className="hall-title font-bold">{t("_deviceData")}</div>
      <ToiletEvent data={data}></ToiletEvent>
      <EatingEvent data={data}></EatingEvent>
      <AsleepAwake data={data}></AsleepAwake>
    </div>
  );
}

function AsleepAwake({ data }: { data: DEVICE_DATA[] }) {
  const scaleMap = data
    .filter(
      (one) =>
        one.subType === "SLEEPING_ASLEEP" || one.subType === "SLEEPING_AWAKE"
    )
    .map((one) => parseISO(one.dateTime))
    .reduce((acc, date) => {
      acc.add(format(date, "yyyy-MM-dd"));
      return acc;
    }, new Set<string>());

  const scale = [...scaleMap].reduce((acc, curr, idx) => {
    acc[curr] = idx;
    return acc;
  }, {} as Record<string, number>);
  const scaleKeys = Object.keys(scale);
  const asleepMap: ScatterDataPoint[] = data
    .filter((one) => one.subType === "SLEEPING_ASLEEP")
    .map((one) => parseISO(one.dateTime))
    .map((date) => ({
      y: scale[format(date, "yyyy-MM-dd")] as number,
      x: parseInt(format(date, "HH")),
    }));
  const awakeMap: ScatterDataPoint[] = data
    .filter((one) => one.subType === "SLEEPING_AWAKE")
    .map((one) => parseISO(one.dateTime))
    .map((date) => ({
      y: scale[format(date, "yyyy-MM-dd")] as number,
      x: parseInt(format(date, "HH")),
    }));
  const chartData: ChartData<"scatter"> = {
    labels: Object.keys(scale),
    datasets: [
      {
        data: asleepMap,
        label: "Asleep",
        borderColor: "blue",
        backgroundColor: "blue",
      },
      {
        data: awakeMap,
        label: "Awake",
        borderColor: "yellow",
        backgroundColor: "yellow",
      },
    ],
  };

  return (
    <Chart
      type="scatter"
      options={{
        plugins: {
          title: {
            display: true,
            text: "Sleep Distribution",
          },
        },
        scales: {
          x: { max: 23 },
          y: {
            ticks: {
              callback: (value: string | number, index: number) =>
                scaleKeys[index],
            },
          },
        },
      }}
      data={chartData}
    ></Chart>
  );
}

function ToiletEvent({
  data,
}: {
  data: LIST_DEVICE_DATA_TYPE["getClient"]["device"]["data"];
}) {
  const toiletingMap = data.filter((one) => one.subType === "TOILETING");
  return (
    <BarPlusRegressionDailyCount
      title="Toilet Events"
      data={toiletingMap}
    ></BarPlusRegressionDailyCount>
  );
}

function EatingEvent({
  data,
}: {
  data: LIST_DEVICE_DATA_TYPE["getClient"]["device"]["data"];
}) {
  const toiletingMap = data.filter((one) => one.subType === "EATING_GENERAL");
  return (
    <BarPlusRegressionDailyCount
      title="Eating Events"
      data={toiletingMap}
    ></BarPlusRegressionDailyCount>
  );
}
