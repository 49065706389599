import { useLazyQuery, useQuery } from "@apollo/client";
import { CircularProgress, Fab, Modal } from "@mui/material";
import {
  LIST_CAREGIVERS,
  LIST_CAREGIVERS_TYPE,
} from "../../../services/bff/caregivers-queries";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { User } from "../../../model/user.model";
import { cellStyle } from "../../../services/haal-mui-theme/haal-mui-theme";
import { useState } from 'react';
import CreateUser from '../../shared/CreateUser';
import AddIcon from '@mui/icons-material/Add'
import { ivalidateApolloCache } from "../../../services/GraphQL/apollo-client";
import Spinner from "../../shared/LoadingSpinner";
import { useTranslationHook } from "../../../translations/traductios";

export default function UsersList() {
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const { error, data, loading } =
    useQuery<LIST_CAREGIVERS_TYPE>(LIST_CAREGIVERS);
  const [fetchSecondQueryData, { data: secondQueryData, loading: secondQueryLoading, error: secondQueryError }] = useLazyQuery(LIST_CAREGIVERS)

  const t = useTranslationHook()

  return (
    <div>
    {
      loading ?
      <div className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div> :
      <>
        <div className="haal-title"> {t("_careGiversList")} </div>
        <div className="mt-5">
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              style={{ fontWeight: "bold" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell style={{ ...cellStyle }}>Sub</TableCell> */}
                  <TableCell style={{ ...cellStyle }} align="center">
                    {t("_name")}
                  </TableCell>
                  <TableCell style={{ ...cellStyle }} align="center">
                  {t("_surname")}
                  </TableCell>
                  {/* <TableCell style={{ ...cellStyle }} align="center">
                  {t("_email")}
                  </TableCell> */}
                </TableRow>
              </TableHead>
  
              <TableBody>
                {data?.listCaregivers.length === 0 && (
                  <TableRow className="bg-[#F7F7F7]">
                    <TableCell colSpan={5} align="center">
                      <div className="haal-title"> {t("_noCaregiversFound")} </div>
                    </TableCell>
                  </TableRow>
                )}
                {data?.listCaregivers.map((user: User) => (
                  <TableRow className="bg-[#F7F7F7]" key={user.sub}>
                    {/* <TableCell>{user.sub}</TableCell> */}
                    <TableCell align="center">{user?.given_name || t("_unknown")}</TableCell>
                    <TableCell align="center">{user?.family_name || t("_unknown")}</TableCell>
                    {/* <TableCell align="center">{user?.email || t("_unknown")}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="haal-overlay-container">
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => setCreateUserModalOpen(true)}
          >
            <AddIcon />
          </Fab>
          {createUserModalOpen && (
            <Modal
              open={createUserModalOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              < CreateUser close={(newUser: any) => {
                if (newUser) {
                  ivalidateApolloCache()
                }
                setCreateUserModalOpen(false)
              }} />
            </Modal>
          )}
  
        </div>
      </>
    }
    </div>
  );
}
