import { gql } from "@apollo/client";
import { CaregiverNotification, User } from "../../model/user.model";
import { getApolloClient } from "../GraphQL/apollo-client";

export type LIST_CAREGIVERS_TYPE = {
    listCaregivers: User[],
};
export const LIST_CAREGIVERS = gql`
    query ListCaregivers{
        listCaregivers{
            sub
            username
            email
            family_name
            given_name
        }
    }`;

export const ASSIGN_CLIENT = gql`
    mutation AssignClient($clientId: ID!, $userId: ID!, $expire: AWSDateTime){
        assignClient(clientId: $clientId, userId: $userId, expire:$expire){
            uid
            clientId
        }
    }
`

export const CREATE_CAREGIVER = gql`
    mutation CreateCaregiver($username: String!, $email: String!, $given_name: String, $family_name: String){
        createCaregiver(username: $username, email: $email, given_name: $given_name, family_name: $family_name){
            sub
            given_name
            family_name
            username
            email
        }
    }

`

export type CREATE_CAREGIVER_TYPE = {
    createCaregiver: Pick<User, 'sub'>
}
export type CREATE_CAREGIVER_INPUT = {
    username: string;
    email: string;
    given_name: string;
    family_name: string;
}

export type USER_CAREGIVERS_TYPE = {
    getClient: {
        careGivers: {
            sub: User['sub'],
            username: User['username'],
            given_name: string
            family_name: string
            email: string
        }[]
    }
}
export const USER_CAREGIVERS = gql`
query UserCaregivers($id: String!){
    getClient(id:$id) {
        careGivers {
            sub,
            username,
            given_name,
            family_name,
            email,
        }
    }
}
`

export const LIST_CAREGIVER_NOTIFICATIONS = gql`
    query listNotifications{
        listNotifications {
            items {
                uid
                timestamp
                status
                description
                title
            }
            nextToken
        }
    }
`;

export const ARCHIVE_CAREGIVER_NOTIFICATIONS = gql`
    mutation archieveNotification($timestamp: AWSDateTime!) {
        archieveNotification(timestamp: $timestamp)
    }
`;
export type ARCHIVE_CAREGIVER_NOTIFICATION_TYPE = {
    archieveNotification: boolean;
};

export type LIST_CAREGIVER_NOTIFICATIONS_TYPE = {
    listNotifications: {
        items: CaregiverNotification[],
        nextToken: string
    };
};
/*  
* Function that retrives the list of the clients.
*///(onlyPreferred: $onlyPreferred)
export const listNotification = (): Promise<{
    items: CaregiverNotification[], nextToken?: string
}> => {

    return getApolloClient().query<LIST_CAREGIVER_NOTIFICATIONS_TYPE>({
        query: LIST_CAREGIVER_NOTIFICATIONS
    }).then(({ error, loading, data }) => {
        return new Promise((resolve, reject) => {
            const clients = data.listNotifications
            resolve(clients)
        })
    });
}