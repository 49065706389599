import { Button, Snackbar, TextField } from "@mui/material";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import { User } from "../../model/user.model";
import { getCognitoUserWithSession, getUserAttributes } from "../../services/auth/auth";
import { useTranslationHook } from "../../translations/traductios";

export function Profile() {
    const t = useTranslationHook()
    const [caregiverName, setCaregiverName] = useState<User["family_name"] | null>(null);
    const [caregiverSurname, setCaregiverSurname] = useState<User["family_name"] | null>(null);
    const [requestPending, setRequestPending] = useState(false)
    const [reqError, setReqError] = useState<string | undefined>(undefined);
    const [snackBar, setSnackBar] = useState<{
        message: string,
        opened: boolean
    } | undefined>(undefined)
    useEffect(() => {
        getUserAttributes().then((attrs) => {
            const familyNameAttr = attrs.find(
                (el: CognitoUserAttribute) => el.Name === 'family_name'
            );
            
            const givenNameAttr = attrs.find(
                (el: CognitoUserAttribute) => el.Name === 'given_name'
            );
            
            
            setCaregiverName(familyNameAttr?.Value || null);
            setCaregiverSurname(givenNameAttr?.Value || null);

        });
    }, []);
    const handleSubmit = async (event: any) => {
        event.preventDefault()
        setRequestPending(true)
        const attributeList: any[] = [];
        const nameAttribute = {
            Name: 'family_name',
            Value: caregiverName as string
        };
        const surnameAttribute = {
            Name: 'given_name',
            Value: caregiverSurname as string
        };
        var attr1 = new CognitoUserAttribute(nameAttribute);
        var attr2 = new CognitoUserAttribute(surnameAttribute);
        attributeList.push(attr1);
        attributeList.push(attr2);

        getCognitoUserWithSession().then((cognitoUser) => {
            cognitoUser?.updateAttributes(attributeList, function (err, result) {
                setRequestPending(false)
                if (err) {
                    setReqError(err?.message || `${err?.name || 'Unknown Error'}`)
                    console.log(err)
                } else {
                    setSnackBar({
                        opened: true,
                        message: t("_profileInfoChangedSuccessfully"),
                        // shoudNavigateBackOnClose: true
                    })
                }
            }
            );
        })
    }
    return (
        <>
            <div className="w-full">
                <form className="flex flex-col" onSubmit={(event) => handleSubmit(event)} action="">

                    <div className="grid grid-cols-2 gap-4">
                        <TextField
                            autoComplete="off"
                            disabled={requestPending}
                            name={t("_name")}
                            onChange={(event) => setCaregiverName(event.currentTarget.value)}
                            label={t("_insertName")}
                            value={caregiverName}
                            placeholder={t("_insertName")}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            autoComplete="off"
                            disabled={requestPending}
                            name={t("_surname")}
                            onChange={(event) => setCaregiverSurname(event.currentTarget.value)}
                            label={t("_surname")}
                            value={caregiverSurname}
                            placeholder={t("_insertSurname")}
                            InputLabelProps={{ shrink: true }}
                        />

                    </div>
                    <div className="flex justify-end mt-3">
                        <Button disabled={requestPending || !caregiverName || caregiverName === '' || !caregiverSurname || caregiverSurname === ''} type="submit" variant="contained">
                            {t("_save")}
                        </Button>
                    </div>
                </form>
                {reqError &&
                    <div className="text-2 text-red-500 font-bold">
                        {reqError}
                    </div>
                }
            </div>
            {snackBar && snackBar.opened &&
                <Snackbar
                    style={{
                        backgroundColor: '#1976d2',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackBar.opened}
                    onClose={() => {
                        setSnackBar({
                            ...snackBar,
                            opened: false
                        })
                    }}
                    autoHideDuration={2000}
                    message={snackBar.message}
                />
            }
        </>

    )
}
