import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Client } from "../../model/client.model";
import { useTranslationHook } from "../../translations/traductios";
import { UrgentSituations } from "../Home/pieces/UrgentSituations";
//import { listClients } from "../../services/haal-mui-theme/dao/client";
function createData(
    id: number,
    name: string,
    surname: string,
    isOnPreference: boolean,
    situation: string,
    time: string,
    location: number,
    status: string,
    resStatus: string,
    idk: number,
    responsiblePerson: string,
) {
    return { id, name, surname, isOnPreference, situation, time, location, status, resStatus, idk, responsiblePerson };
}
export function TvMode() {
    
    const t = useTranslationHook()

    const [date, setDate] = useState<string>(dayjs(new Date()).format('DD MMM YYYY'))
    const [hour, setHour] = useState<string>(dayjs(new Date()).format('HH:mm'))
    setInterval(() => {
        if (dayjs(new Date()).format('DD MMM YYYY') !== date) {
            setDate(dayjs(new Date()).format('DD MMM YYYY'))
        }
        if (dayjs(new Date()).format('HH:mm') !== hour) {
            setHour(dayjs(new Date()).format('HH:mm'))
        }
    }, 1000)
    return (
        <div className="p-5">
            <div className="my-5">
                <div className="flex justify-between my-5">
                    <div className="flex">
                        <div className="haal-title mr-2">{date}</div>
                        <div className="haal-title">{hour}</div>
                    </div>
                    <a className="flex underline" href="/home">
                        <div className="haal-title ">{t("_goHome")}</div>
                        <FontAwesomeIcon className="inline-block align-middle ml-2" icon={['fas', 'home']} />
                    </a>
                </div>
                <UrgentSituations />
            </div>
        </div>
    )
}


export default TvMode;

