import { ClientForSituation } from "../services/bff/situation-querie"
import { Client } from "./client.model"
import { User } from "./user.model"

export type Situation = {
    clientId: String
    client: ClientForSituation    
    eventType: String
    respondStatus: SituationStatus
    timestamp: Date
    description: String
    author: User
    source?: string
}

export enum SituationStatus {
    PENDING = "PENDING",
    TAKEN_CHARGE = "TAKEN_CHARGE",
    MONITORING = "MONITORING",
    /* SOLVED = "SOLVED", */
}
//t("_countDownSecondsSituations", { "countDownSeconds": countDownSeconds.toString() })