import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, formatISO, isBefore, subDays } from "date-fns";
import { useMemo, useState } from "react";
import { Device } from "../../../model/device.model";
import { GET_CLIENT_QUERY } from "../../../services/bff/client-queries";
import {
  DELETE_DEVICE,
  UPDATE_DEVICE,
} from "../../../services/bff/device-mutation";
import {
  LIST_DEVICE_DATA,
  LIST_DEVICE_DATA_TYPE,
} from "../../../services/bff/device-queries";
import { useTranslationHook } from "../../../translations/traductios";
import { enumToTranslate } from "../../../utils/utils";
import { HallSnackBar } from "../../shared/HallSnackBar/HallSnackBar";
import { CogvisAiData } from "./DevicesData/CogvisAiData";
import { KompyPicoData } from "./DevicesData/KompyPicoData";
import { SensaraData } from "./DevicesData/SensaraData";
import { TessaData } from "./DevicesData/TessaData";
import { TiprData } from "./DevicesData/TiprData";
import { WhizPadData } from "./DevicesData/WhizPadData";
import { WhizToysData } from "./DevicesData/WhizToysData";
export function ClientDevicePage(props: {
  device: Pick<
    Device,
    "clientId" | "deviceName" | "deviceId" | "start_time" | "end_time"
  >;
  clientId: string;
}) {
  const { device } = props;

  const t = useTranslationHook();
  const [archiveDeviceModalOpenState, setArchiveDeviceModalOpenState] =
    useState<boolean>(false);
  const [deleteDeviceModalOpenState, setDeleteDeviceModalOpenState] =
    useState<boolean>(false);
  const yesterday = useMemo(() => subDays(new Date(), 1), []);
  const [to, setTo] = useState<Date>(yesterday);
  const [from, setFrom] = useState<Date>(subDays(to, 30));
  const [snackBar, setSnackBar] = useState<
    | {
        opened: boolean;
        message: string;
      }
    | undefined
  >(undefined);
  let [updateDevice] = useMutation(UPDATE_DEVICE, {
    refetchQueries: [GET_CLIENT_QUERY],
    onCompleted: (data) => {
      setSnackBar({
        opened: true,
        message: t("_archiveDeviceSuccess"), //METTI TRADUZIONE
      });
      setTimeout(() => {
        setArchiveDeviceModalOpenState(false);
      }, 2500);
      data = undefined;
    },
    onError: (error) => {
      setSnackBar({
        opened: true,
        message: t("_archiveDeviceError"), //METTI TRADUZIONE
      });
    },
  });
  let [deleteDevice] = useMutation(DELETE_DEVICE, {
    refetchQueries: [GET_CLIENT_QUERY],
    onCompleted: (data) => {
      /* ivalidateApolloCache() */
      setSnackBar({
        opened: true,
        message: t("_deleteDeviceSuccess"), //METTI TRADUZIONE
      });
      setTimeout(() => {
        setDeleteDeviceModalOpenState(false);
      }, 2500);
    },
    onError: (error) => {
      setSnackBar({
        opened: true,
        message: t("_deleteDeviceError"), //METTI TRADUZIONE
      });
    },
  });
  const handleArchiveDeviceSubmit = () => {
    const end_time = format(new Date() as Date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
    //device.end_time = format(new Date() as Date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
    updateDevice({
      variables: {
        clientId: props.clientId,
        deviceId: device.deviceId,
        end_time,
      },
    });
  };
  const handleDeleteDeviceSubmit = () => {
    deleteDevice({
      variables: {
        clientId: props.clientId,
        deviceId: device.deviceId,
      },
    });
  };
  return (
    <div className="">
      <Divider />
      <div className="flex justify-between items-center mt-5">
        <div className="haal-title uppercase">
          {t(enumToTranslate(device?.deviceName)) || t("_unknown")}
        </div>
        <div className="flex">
          {!device?.end_time ||
            (!isBefore(device?.end_time as unknown as Date, new Date()) && (
              <Tooltip title={t("_archive")}>
                <button
                  onClick={() => {
                    setArchiveDeviceModalOpenState(true);
                  }}
                >
                  <div
                    className="icon-wrapper h-10 w-10 border border-[#dedede] black"
                    style={{ margin: 0 }}
                  >
                    <FontAwesomeIcon icon={["fas", "door-closed"]} />
                  </div>
                </button>
              </Tooltip>
            ))}
          <Tooltip title="Delete">
            <button
              className="ml-5"
              onClick={() => {
                setDeleteDeviceModalOpenState(true);
              }}
            >
              <div
                className="icon-wrapper h-10 w-10 border border-[#dedede] black"
                style={{ margin: 0 }}
              >
                <FontAwesomeIcon
                  className="inline-block align-middle haal-danger-text"
                  icon={["fas", "trash"]}
                />
              </div>
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="shadow border border-[#dedede] bg-white rounded mt-2 py-5">
          <div className="w-full border-[#dedede] px-5">
            <div className="grid grid-cols-3">
              <div className="flex flex-col my-1">
                <div className="haal-subtitle underline">
                  {" "}
                  {t("_deviceCode")}{" "}
                </div>
                <div className="haal-subtitle font-bold mt-1">
                  {device?.deviceId || t("_unknown")}{" "}
                </div>
              </div>
              <div className="flex flex-col my-1">
                <div className="haal-subtitle underline">
                  {" "}
                  {t("_assignedOn")}{" "}
                </div>
                <div className="haal-subtitle font-bold mt-1">
                  {format(
                    device?.start_time as unknown as Date,
                    "dd-MM-yyyy HH:mm:ss"
                  ) || t("_unknown")}
                </div>
              </div>
              {device?.end_time &&
                isBefore(device?.end_time as unknown as Date, new Date()) && (
                  <div className="flex flex-col my-1">
                    <div className="haal-subtitle underline">
                      {t("_pickedUpOn")}
                    </div>
                    <div className="haal-subtitle font-bold mt-1">
                      {format(
                        device?.end_time as unknown as Date,
                        "dd-MM-yyyy HH:mm:ss"
                      ) || t("_unknown")}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-6">
          <div className="flex">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disableMaskedInput={true}
                label={"From"}
                maxDate={to}
                inputFormat="dd/MM/yyyy"
                value={from}
                onChange={(event) => {
                  if (event && event instanceof Date) {
                    setFrom(event);
                  }
                }}
                renderInput={(from) => (
                  <TextField
                    disabled
                    autoComplete="off"
                    name="born"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    {...from}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                className="!ml-6"
                disableMaskedInput={true}
                label={"To"}
                maxDate={yesterday}
                inputFormat="dd/MM/yyyy"
                value={to}
                onChange={(event) => {
                  if (event && event instanceof Date) {
                    setTo(event);
                  }
                }}
                renderInput={(to) => (
                  <TextField
                    disabled
                    autoComplete="off"
                    name="born"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    {...to}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <DeviceSwitch from={from} to={to} device={device} />
        </div>
      </div>
      <Dialog
        open={archiveDeviceModalOpenState}
        /* TransitionComponent={Transition} */
        keepMounted
        onClose={() => {
          setArchiveDeviceModalOpenState(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t("_archiveDevice")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("_archiveDeviceText")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setArchiveDeviceModalOpenState(false);
            }}
          >
            {" "}
            {t("_close")}{" "}
          </Button>
          <Button
            className="haal-delete-button"
            onClick={() => {
              handleArchiveDeviceSubmit();
            }}
          >
            {" "}
            {t("_archive")}{" "}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDeviceModalOpenState}
        /* TransitionComponent={Transition} */
        keepMounted
        onClose={() => {
          setDeleteDeviceModalOpenState(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t("_deleteDevice")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("_deleteDeviceText")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDeviceModalOpenState(false);
            }}
          >
            {" "}
            {t("_close")}{" "}
          </Button>
          <Button
            className="haal-delete-button"
            onClick={() => {
              handleDeleteDeviceSubmit();
            }}
          >
            {" "}
            {t("_delete")}{" "}
          </Button>
        </DialogActions>
      </Dialog>
      {snackBar && snackBar.opened && (
        <HallSnackBar
          message={snackBar.message}
          onClose={() => {
            setSnackBar({
              message: "",
              opened: false,
            });
          }}
        />
      )}
    </div>
  );
}

function DeviceSwitch(props: {
  device: Pick<
    Device,
    "clientId" | "deviceName" | "deviceId" | "start_time" | "end_time"
  >;
  from: Date;
  to: Date;
}) {
  const { device, from, to } = props;
  const toDateIso = formatISO(to);
  const sinceDateIso = formatISO(from);

  const { loading, error, data } = useQuery<LIST_DEVICE_DATA_TYPE>(
    LIST_DEVICE_DATA,
    {
      canonizeResults: false,
      variables: {
        clientId: device.clientId,
        deviceId: device.deviceId,
        sinceDate: sinceDateIso,
        toDate: toDateIso,
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const t = useTranslationHook();
  if (loading) {
    return <p>{t("_loading")}</p>;
  }
  if (error) {
    return (
      <p>
        {error.name} {error.message}
      </p>
    );
  }
  if (data) {
    if(data.getClient.device.data.length === 0) {
      return <p> {t("_noData")} </p>;
    }
    switch (props.device.deviceName) {
      case "WHIZ_PAD":
        return (
          <WhizPadData
            from={from}
            to={to}
            device={props.device}
            data={data.getClient.device.data}
          />
        );
      case "WHIZ_TOYS":
        return (
          <WhizToysData
            from={from}
            to={to}
            device={props.device}
            data={data.getClient.device.data}
          />
        );
      case "SENSARA":
        return (
          <SensaraData
            from={from}
            to={to}
            device={props.device}
            data={data.getClient.device.data}
          />
        );
      case "KOMPY_PICO":
        return (
          <KompyPicoData
            from={from}
            to={to}
            device={props.device}
            data={data.getClient.device.data}
          />
        );
      case "TIPR":
        return (
          <TiprData
            from={from}
            to={to}
            device={props.device}
            data={data.getClient.device.data}
          />
        );
      case "COGVISAI":
        return (
          <CogvisAiData
            from={from}
            to={to}
            device={props.device}
            data={data.getClient.device.data}
          />
        );
      case "TESSA":
        return (
          <TessaData
            from={from}
            to={to}
            device={props.device}
            data={data.getClient.device.data}
          />
        );
    }
  }

  return <p> {t("_workInProgress")} </p>;
}
