import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Device } from "../../../../model/device.model";
import { DEVICE_DATA, DEVICE_DATA_PARSED } from "../../../../services/bff/device-queries";
import { useTranslationHook } from "../../../../translations/traductios";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export function TessaData({
  data,
}: {
  device: Pick<
    Device,
    "clientId" | "deviceName" | "deviceId" | "start_time" | "end_time"
  >;
  from: Date;
  to: Date;
  data: DEVICE_DATA[];
}) {
  const t = useTranslationHook();
  const parsedData = data.map((one) => ({
    ...one,
    extra: JSON.parse(one.extra),
  })) as DEVICE_DATA_PARSED<{
    question: string;
    answer: string;
    planned: string;
    message: string | null;
    execution_id: number;
  }>[];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };

  return (

    <div className="shadow border h-fit border-[#dedede] bg-white rounded col-start-2 col-end-5 mt-5 p-5">
      <div className="hall-title font-bold">{t("_deviceData")}</div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="!font-bold">Question</TableCell>
              <TableCell className="!font-bold">Answer</TableCell>
              <TableCell className="!font-bold !text-right">TIme</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(parsedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((item) => (
              <TableRow key={item.dateTime}>
                <TableCell>{item.extra.question}</TableCell>
                <TableCell>{item.extra.answer}</TableCell>
                <TableCell className="!text-right">{format(parseISO(item.dateTime), "yyyy-MM-dd")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={parsedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}
