import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import { SingleClientT } from "../../../services/bff/client-queries";
import { modalMuiStyle } from "../../../services/haal-mui-theme/haal-mui-theme";
import CloseIcon from '@mui/icons-material/Close';
import { Client, Gender } from "../../../model/client.model";
import { useState } from "react";
import { User } from "../../../model/user.model";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_CAREGIVERS_TYPE, LIST_CAREGIVERS, ASSIGN_CLIENT } from "../../../services/bff/caregivers-queries";
import { ivalidateApolloCache } from "../../../services/GraphQL/apollo-client";
import { useParams } from "react-router-dom";
import { useTranslationHook } from "../../../translations/traductios";

export function ShareClient(props: {
    caregiversAlreadyAssigned: User[],
    close: () => void
}) {
    
    const t = useTranslationHook()

    const { caregiversAlreadyAssigned } = props
    const [caregivers, setCaregivers] = useState<User[]>([])
    const [selectedCaregiver, setSelectedCaregiver] = useState<User | null>(null)

    const params = useParams(); // Unpacking and retrieve id
    const clientId: Client['id'] | User['sub'] | undefined = params.clientId
    if (!clientId) {

    }
    const disableUI = !clientId //disabilito la UI nel caso in cui per qualche motivo non ho il clientid
    const handleSubmit = (event: any) => {
        event.preventDefault();
        assignClient(({
            variables: {
                clientId: clientId,
                userId: selectedCaregiver?.sub,
            }
        }));
        //assignClient(clientId: ID!, userId: ID!, expire: AWSDateTime): Boolean
    }
    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)

    const { loading, error, data } = useQuery<LIST_CAREGIVERS_TYPE>(LIST_CAREGIVERS, {
        onCompleted: (data) => {
            /* TODO: DA QUESTO DEVO ESCLUDERE QUELLI GIA' ASSEGNATI */
            setCaregivers(data.listCaregivers || []);
        },
        onError: (err) => {
            console.log(err)
        }
    });
    let [assignClient, assignClientRes] = useMutation(ASSIGN_CLIENT, {
        onCompleted: (data) => {
            ivalidateApolloCache()

            setSnackBar({
                opened: true,
                message: t("_clientAssigned")
            })
            setTimeout(() => {
                props.close()
            }, 2500)
        },
        onError: (error) => {
            setSnackBar({
                opened: true,
                message: t("_errorAssigningClient")
            })
        }
    });

    return (
        <>
            <Box sx={modalMuiStyle} >
                <>
                    <div className="flex justify-between items-center my-3" >
                        <div className="haal-title mr-4">{t("_shareClient")}</div>
                        <Button>
                            <CloseIcon onClick={() => {
                                props.close()
                            }} />
                        </Button>
                    </div>
                    <form onSubmit={(event) => handleSubmit(event)} >

                        <FormControl fullWidth>
                            <Autocomplete
                                disabled={disableUI}
                                id="country-select-demo"
                                sx={{ width: 300 }}
                                options={caregivers}
                                autoHighlight
                                value={selectedCaregiver}
                                getOptionLabel={(caregiver) => caregiver?.username}
                                getOptionDisabled={(caregiver) => {
                                    const founded = caregiversAlreadyAssigned.find((e: User) => {
                                        return e.username === caregiver.username
                                    })
                                    return !!founded
                                }}
                                onChange={(event, newValue) => {
                                    setSelectedCaregiver(newValue)
                                }}
                                renderOption={(props, caregiver) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <div className="icon-wrapper h-7 w-7 bg-[#858484] mr-4">
                                            <img alt="" className="hall-client-profile-image" src={process.env.PUBLIC_URL + '/garegiver-male.png'}></img>

                                        </div>
                                        <div className="haal-subtitle font-bold">
                                            {caregiver?.username}
                                        </div>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        autoComplete={t("_off")}
                                        {...params}
                                        value={() => {
                                            console.log(t("_aa"))
                                        }}
                                        label={t("_chooseACaregiver")}

                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: t("_newPassword"), // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <div className="w-full flex justify-end my-3">
                            <Button disabled={!selectedCaregiver} type="submit" variant="contained">{t("_assign")}</Button>
                        </div>
                    </form>
                </>
            </Box>
            {snackBar &&
                <Snackbar
                    style={{
                        backgroundColor: '#1976d2',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackBar.opened}
                    onClose={() => {
                        setSnackBar({
                            message: '',
                            opened: false,
                        })
                    }}
                    autoHideDuration={2000}
                    message={snackBar.message}
                />
            }
        </>
    )
}