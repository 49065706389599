import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO } from "date-fns";
import { FormEvent, useEffect, useState } from "react";
import { Situation, SituationStatus } from "../../../model/situation.model";
import { GET_CLIENT_QUERY, SingleClientT } from "../../../services/bff/client-queries";
import { CREATE_SITUATION } from "../../../services/bff/situation-mutations";
import { modalMuiStyle } from "../../../services/haal-mui-theme/haal-mui-theme";
import { useTranslationHook } from "../../../translations/traductios";
import { enumToTranslate } from "../../../utils/utils";
import { HallSnackBar } from "../../shared/HallSnackBar/HallSnackBar";
export function ClientSituations(
    props: {
        client: SingleClientT
        situations: Situation[]
    }
) {

    const t = useTranslationHook()
    const { client, situations } = props
    const clientId = client.id
    const [addSituationModalOpenState, setAddSituationModalOpenState] = useState<boolean>(false)
    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)


    const [eventType, setEventType] = useState<Situation["eventType"] | undefined>(undefined)
    const [respondStatus, setRespondStatus] = useState<Situation["respondStatus"] | undefined>(undefined)
    const [date, setDate] = useState<Date | null>(new Date())
    const [hour, setHour] = useState<Date | null>(new Date())
    //addSituationModalOpenState
    useEffect(() => {
        if (addSituationModalOpenState) {
            setDate(new Date())
            setHour(new Date())
        }
    }, [addSituationModalOpenState]);
    const [description, setDescription] = useState<string | undefined>(undefined)
    const isNotValid = !eventType || !respondStatus

    const disableUI = false
    const [createSituation] = useMutation(CREATE_SITUATION, {
        refetchQueries: [GET_CLIENT_QUERY],
        onCompleted: (data) => {
            /* ivalidateApolloCache() */

            setSnackBar({
                opened: true,
                message: t("_situationCreated")
            })
            resetForm()
            setTimeout(() => {
                setAddSituationModalOpenState(false)
            }, 2500)
        },
        onError: (error) => {
            setSnackBar({
                opened: true,
                message: t("_errorCreatingSituation")
            })
        }
    });
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log([eventType, respondStatus, date, hour?.getHours()])
        createSituation(({
            variables: {
                clientId,
                eventType,
                respondStatus,
                timestamp: date,
                description
            }
        }));

    }

    const resetForm = () => {
        setEventType(undefined)
        setRespondStatus(undefined)
        setDate(null)
        setHour(null)
        setDescription(undefined)
    }

    return (
        <div className="mt-5">
            <div className="flex items-center justify-between">
                <div className="haal-title">
                    {t("_urgentSituations")}
                </div>
                <button onClick={() => {
                    setAddSituationModalOpenState(true)
                }}>
                    <div className="icon-wrapper h-10 w-10 border border-[#dedede] black mr-4" style={{ margin: 0 }}>
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </div>
                </button>
            </div>
            <div className="mt-5">

                <div className="">
                    {situations.length === 0 && (
                        <div className="hall-subti">
                            {t("_noSituationsFounded")}
                        </div>
                    )}
                    {situations.map((situation) => {
                        let situationBg = ''

                        switch (situation.respondStatus) {
                            case 'PENDING':
                                situationBg = 'haal-respond-situation-pending'
                                break;
                            case 'TAKEN_CHARGE':
                                situationBg = 'haal-respond-situation-taken-charge'
                                break;
                            case 'MONITORING':
                                situationBg = 'haal-respond-situation-monitoring'
                                break;
                            /* case 'SOLVED':
                                situationBg = 'haal-respond-situation-solved'
                                break; */
                            default:
                                situationBg = 'bg-trasparent'
                                break;
                        }
                        return (
                            <Tooltip title={t(enumToTranslate(situation.respondStatus))} placement="right">
                                <button className="w-full">

                                    <div className={situationBg + " cursor-default flex-col rounded mb-1 border-b-2 border-grey-300 px-5 py-2"}>
                                        <div className="hall-subtitle flex items-center">
                                            {t("_event")}
                                            <div className="font-bold ml-2">
                                                {t(`_${situation.eventType.toLowerCase()}`)}
                                            </div>
                                        </div>
                                        <div className="hall-subtitle flex">
                                            {t("_on")}:
                                            <div className="font-bold ml-2">
                                                {format(parseISO(situation.timestamp as unknown as string), "dd-MM-yyyy HH:mm:ss")}
                                            </div>
                                        </div>
                                        {/* <div className="hall-subtitle flex-col my-1">
                                    Respond Status:
                                    <div className="font-bold">
                                        {situation.respondStatus}
                                    </div>
                                </div> */}

                                    </div>
                                </button>
                            </Tooltip>


                        )
                    })}
                </div>
            </div>
            {addSituationModalOpenState && (

                <div>
                    <Box sx={modalMuiStyle} style={{ zIndex: 999 }}>
                        <div>
                            <div className="flex justify-between items-center my-3" >
                                <div className="haal-title mr-4">{t("_createUrgentSituation")}</div>
                                <Button>
                                    <CloseIcon onClick={() => {
                                        resetForm()
                                        setAddSituationModalOpenState(false)
                                    }} />
                                </Button>
                            </div>

                            <form onSubmit={(event) => handleSubmit(event)} >
                                <div className="grid grid-cols-2 gap-4">
                                    <FormControl fullWidth>
                                        <InputLabel id="eventType">{t("_selectEventType")}</InputLabel>
                                        <Select
                                            required
                                            className="w-full"
                                            disabled={false}
                                            labelId="eventType"
                                            id="eventType"
                                            label={t("_eventType")}
                                            type="text"
                                            value={eventType}
                                            onChange={(event) => {
                                                setEventType(event.target.value)
                                            }}
                                        >
                                            <MenuItem value={undefined}>{t("_selectOne")}</MenuItem>
                                            <MenuItem value={"FALL"}>{t("_fall")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="respondStatus">{t("_selectRespondStatus")}</InputLabel>
                                        <Select
                                            required
                                            className="w-full"
                                            disabled={false}
                                            labelId="respondStatus"
                                            id="respondStatus"
                                            label={t("_respondStatus")}
                                            type="text"
                                            value={respondStatus}
                                            onChange={(event) => {
                                                setRespondStatus(event.target.value as unknown as SituationStatus)
                                            }}
                                        >
                                            <MenuItem value={undefined}>{t("_selectOne")}</MenuItem>
                                            <MenuItem value={SituationStatus['PENDING']}>{t("_pending")}</MenuItem>
                                            <MenuItem value={SituationStatus['TAKEN_CHARGE']}>{t("_takenCharge")}</MenuItem>
                                            <MenuItem value={SituationStatus['MONITORING']}>{t("_monitoring")}</MenuItem>
                                            {/* <MenuItem value={SituationStatus['SOLVED']}>Solved</MenuItem> */}
                                        </Select>
                                    </FormControl>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label={t("_situationOf(MM/dd/YYY):")}
                                            disabled={false}
                                            inputFormat="dd/MM/yyyy"
                                            value={date}
                                            maxDate={new Date()}
                                            onChange={(event) => {
                                                if (event && event instanceof Date) {
                                                    setDate(event)
                                                }
                                            }
                                            }
                                            renderInput={(assignedOn) => <TextField autoComplete="off" name="assignedOn" {...assignedOn} />}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            label={t("_hour")}
                                            value={hour}
                                            ampm={false}
                                            onChange={(newHour) => {
                                                setHour(newHour);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        autoComplete="off"
                                        type="desription"
                                        name="desription"
                                        disabled={disableUI}
                                        onChange={(event) => setDescription(event.currentTarget.value)}
                                        value={description}
                                        label={t("_addMoreEventInfo")} placeholder={t("_addMoreEventInfo")}
                                    />

                                </div>
                                <div className="w-full flex justify-end my-3">
                                    <Button disabled={isNotValid} type="submit" variant="contained">
                                        {t("_createSituation")}
                                    </Button>
                                </div>
                            </form>


                        </div>
                    </Box >
                    {
                        snackBar && snackBar.opened &&
                        <HallSnackBar
                            message={snackBar.message}
                            onClose={() => {
                                setSnackBar({
                                    message: '',
                                    opened: false,
                                })
                            }} />
                    }
                </div >
            )
            }
        </div >
    )
}