// function StatusCircle({ size }: { size: 's' | 'm' | 'l' }) {
//     // const size = props.size;
//     // const { size } = props;
//     return <div>{size}</div>

import { CSSProperties } from "react";
import { useTranslationHook } from "../../../translations/traductios";
import { useParams, useSearchParams } from "react-router-dom";
import { Client } from "../../../model/client.model";

// }
export enum Urgency {
  HIGH = "200px",
  MEDIUM = "150px",
  LOW = "100px",
}
export enum Severity {
  BAD = "#cc3232",
  WARNING = "#e7b416",
  GOOD = "#1e34f8",
  PERFECT = "#2dc937",
}
export enum Type {
  PHYSICAL = "Physical Status",
  SLEEP = "Sleep Activity",
  COGNITIVE = "Cognitive Status",
  WELLBEING = "General Well Being",
}

export type StatusCircleProps = {
  urgency?: keyof typeof Urgency;
  severity?: keyof typeof Severity; //patientstate
  type: keyof typeof Type;
};
export function StatusCircle({ urgency, severity, type }: StatusCircleProps) {

  const t = useTranslationHook()
  const bubbleStyle: CSSProperties = {
    zIndex: 4,
    borderRadius: "100%",
  };
  
  let typeTraduction = ''
  switch (type) {
    //"" | "PHYSICAL" | "COGNITIVE" | "SLEEP"
    case t("_physical"):
    case "PHYSICAL":
      typeTraduction = t("_physical");
      break;
    case t("_sleep"):
    case "SLEEP":
      typeTraduction = t("_sleep");
      bubbleStyle.right = 0;
      break;
    case t("_cognitive"):
    case "COGNITIVE":
      typeTraduction = t("_cognitive");
      bubbleStyle.bottom = 0;
      break;
    case t("_wellBEING"):
    case "WELLBEING":
      typeTraduction = t("_wellBEING");
      bubbleStyle.right = 0;
      bubbleStyle.bottom = 0;
      break;
  }
  
  let [searchParams, setSearchParams] = useSearchParams();

  const getBackgroundColor = () => {
    if (type === "SLEEP") {
      /* if (whiteListClietsId.find((one) => one === id)) {
        return "#cc3232"
      }else  */if (severity) {
        return Severity[severity]
      } else {
        return "#bfbdbd"
      }
    } else {
     /*  if (whiteListClietsId.find((one) => one === id)) {
        return "#2dc937"
      }else  */if (severity) {
        return Severity[severity]
      } else {
        return "#bfbdbd"
      }
    }
  }
  return (
    <div
      onClick={()=>{setSearchParams({activityCode: `${type}`})}}
      className="absolute"
      style={{
        width: urgency ? Urgency[urgency] : Urgency.MEDIUM,
        height: urgency ? Urgency[urgency] : Urgency.MEDIUM,
        /* backgroundColor: severity ? Severity[severity] : "#bfbdbd", */
        backgroundColor: getBackgroundColor(),
        ...bubbleStyle,
      }}
    >
      <div className="status-circle-content">{typeTraduction}</div>
    </div>
  );
}
