import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
    ClientCognitiveStatus,
    ClientPhysicalStatus,
    ClientSleepStatus,
    ClientStatus,
    ClientWellbeingStatus
} from "../../../model/client.model";
import { SingleClientT } from "../../../services/bff/client-queries";
import { StatusCircle } from "./StatusCircle";

export function StatusesDiagram(props: {
  statusClick: (typeStatus: ClientStatus['type'])=>void,
  gender: SingleClientT["anagraphic"]["gender"];
  profilePhotoURL: SingleClientT["anagraphic"]["profilePhotoURL"];
  profileAvatar: SingleClientT["anagraphic"]["profileAvatar"];
  wellbeingStatus?: ClientWellbeingStatus;
  physicalStatus?: ClientPhysicalStatus;
  cognitiveStatus?: ClientCognitiveStatus;
  sleepStatus?: ClientSleepStatus;
}) {
  
  let profilePhotoURL = undefined;
  const {
    gender,
    wellbeingStatus,
    physicalStatus,
    cognitiveStatus,
    sleepStatus,
  } = props;

  if (props.profilePhotoURL) {
    profilePhotoURL = props.profilePhotoURL;
  } else if (props.profileAvatar) {
    profilePhotoURL = process.env.PUBLIC_URL + "/avatar/" + props.profileAvatar;
  } else if (!props.profilePhotoURL && !props.profileAvatar && gender) {
    if (gender === "M") {
      profilePhotoURL = process.env.PUBLIC_URL + "/male_avatar.png";
    } else {
      profilePhotoURL = process.env.PUBLIC_URL + "/female_avatar.png";
    }
  }

  return (
    <div className="hall-situation-container relative">
      {profilePhotoURL && (
        <div className="absolute cerntral-circle hall-client-profile-image-container">
          <img
            className="hall-client-profile-image"
            src={profilePhotoURL}
            alt=""
          ></img>
        </div>
      )}
      {!profilePhotoURL && (
        <div className="absolute cerntral-circle">
          <div className="status-circle-content items-center">
            <FontAwesomeIcon
              icon={["fas", "user"]}
              className="text-white"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        </div>
      )}
      <button onClick={()=>{props.statusClick('PHYSICAL')}}>
        <StatusCircle
          urgency={physicalStatus?.urgency}
          severity={physicalStatus?.status}
          type="PHYSICAL"
        />
      </button>
      <button onClick={()=>{props.statusClick('PHYSICAL')}}>
        <StatusCircle
          urgency={sleepStatus?.urgency}
          severity={sleepStatus?.status}
          type="SLEEP"
        />
      </button>
      <button onClick={()=>{props.statusClick('COGNITIVE')}}>
        <StatusCircle
          urgency={cognitiveStatus?.urgency}
          severity={cognitiveStatus?.status}
          type="COGNITIVE"
        />
      </button>
      <button onClick={()=>{props.statusClick('WELLBEING')}}>
        <StatusCircle
          urgency={wellbeingStatus?.urgency}
          severity={wellbeingStatus?.status}
          type="WELLBEING"
        />
      </button>
      <div className="hall-situation-diagonal-container">
        <div className="hall-situation-diagonal-container hall-situation-diagonal hall-situation-diagonal-1"></div>
      </div>
      <div className="hall-situation-diagonal-container">
        <div className="hall-situation-diagonal-container hall-situation-diagonal hall-situation-diagonal-2"></div>
      </div>
    </div>
  );
}
