/* LIST_DEVICES */

import { gql } from "@apollo/client";
import { Device } from "../../model/device.model";

export type LIST_DEVICES_TYPE = {
    listDevices: {
        items: Device[]
        nextToken?: string
    },
};
export const LIST_DEVICES = gql`
    query ListDevices($nextToken: String){
        listDevices(nextToken: $nextToken){
            nextToken
            items {
                deviceName
                clientId
                deviceId
                start_time
                end_time
                client{
                    anagraphic{
                        name
                        surname
                    }
                }
            }
        }
    }`;

export type DEVICE_DATA = {
    dateTime: string;
    type: string;
    subType: string;
    extra: string;
}
export type DEVICE_DATA_PARSED<T = unknown> = {
    dateTime: string;
    type: string;
    subType: string;
    extra: T;
}

export type LIST_DEVICE_DATA_TYPE = {
    getClient: {
        device: {
            data: DEVICE_DATA[];
        };
    };
};
export const LIST_DEVICE_DATA = gql`
  query GetClientDeviceData($clientId: String!, $deviceId: String!, $sinceDate: AWSDateTime!,$toDate: AWSDateTime!) {
  getClient(id: $clientId) {
    device(deviceId: $deviceId) {
      data(sinceDate: $sinceDate, toDate: $toDate) {
        extra
        dateTime
        subType
        type
      }
    }
  }
}
`;