import { gql } from "@apollo/client";

export const SET_EMERGENCY_CALL = gql`
  mutation setEmergencyContact( $clientId: ID!, $name: String!,  $phone: String!, $timestamp: AWSDateTime!) {
    setEmergencyContact(emergencyContact:{ name: $name, phone: $phone, timestamp: $timestamp}, clientId: $clientId) {
      name
      phone
      timestamp
    }
}`

export const DELETE_EMERGENCY_CALL = gql`
mutation deleteEmergencyContact( $name: String!, $phone:String!, $clientId: ID!, $timestamp: AWSDateTime!) {
  deleteEmergencyContact(emergencyContact:{name:$name, phone:$phone, timestamp: $timestamp}, clientId: $clientId)
}`