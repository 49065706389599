import { gql } from "@apollo/client";


/* 
mutation MyMutation2($clientId: ID!,$deviceId: String!, $name: DeviceName!, $timestamp: AWSDateTime!, $assignor: ID!) {
  assignDevice(clientId: $clientId, device : { deviceId: $deviceId, name: $name, timestamp: $timestamp, assignor: $assignor}) {
    deviceId
    name
    timestamp
    assignor
    id
  }
*/

export const ASSIGN_DEVICE = gql`
    mutation AssignDevice($clientId: ID!,$deviceId: String!, $deviceName: DeviceName!, $start_time: AWSDateTime!) {
        assignDevice(clientId: $clientId, device : { deviceId: $deviceId, deviceName: $deviceName, start_time: $start_time}) {
            deviceId
            clientId
        }
}`
export const UPDATE_DEVICE = gql`
mutation UpdateDevice($clientId: ID!,$deviceId: ID!, $end_time: AWSDateTime!) {
    updateDevice(clientId: $clientId, deviceId: $deviceId, device: {end_time: $end_time}) {
      deviceId
      clientId
      start_time
      end_time
    }
}
`;
export const DELETE_DEVICE = gql`
mutation DeleteDevice( $clientId: ID!,$deviceId: ID!) {
  deleteDevice(clientId: $clientId,deviceId: $deviceId)
}`