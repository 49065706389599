/*FERNANDA'S convertion */
import { useQuery } from "@apollo/client";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faMagnifyingGlass, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, Fab, InputAdornment, Modal, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Client } from "../../model/client.model";
import { listClients, LIST_CLIENTS, LIST_CLIENTS_ARGUMENTS_TYPE, LIST_CLIENTS_TYPE } from "../../services/bff/client-queries";
import { cellStyle, textBold } from "../../services/haal-mui-theme/haal-mui-theme";
import { useTranslationHook } from "../../translations/traductios";
import { CreateUpdateClient } from "../shared/CreateUpdateClient";
import Spinner from "../shared/LoadingSpinner";
import { ClientTableSingleRow } from "./pieces/ClientTableSingleRow";
import { UrgentSituations } from "./pieces/UrgentSituations";
import UsersList from "./pieces/UsersList";

interface TabPanelProps {//contains the name of all the properties along with their types.
    children?: React.ReactNode;
    index: number;
    value: number;
}
library.add(
    fab,
    faMagnifyingGlass,
    faStar,
)
export default function Home() {
    const [onlyPreferred, setOnlyPreferred] = useState(false);
    const { data, refetch, fetchMore, loading } = useQuery<LIST_CLIENTS_TYPE, LIST_CLIENTS_ARGUMENTS_TYPE>(LIST_CLIENTS, {
        variables: {
            onlyPreferred
        },
    });
    const clients = data?.listClients.items || [];
    const nextToken = data?.listClients.nextToken;
    const [patientsFiltered, setPatientsFiltered] = useState<Client[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentSearch = searchParams.get('currentSearch');
    const [createClientModalOpenState, setCreateClientModalOpenState] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(999);
    const [currentTab, setCurrentTab] = useState(0);

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    }

    const lowercaseSearch = currentSearch?.toLocaleLowerCase();
    useEffect(() => {
        if (!lowercaseSearch) {
            setPatientsFiltered(clients);
        } else {
            setPatientsFiltered(
                clients.filter(client =>
                    client.anagraphic.name.toLocaleLowerCase().includes(lowercaseSearch) ||
                    client.anagraphic.surname.toLocaleLowerCase().includes(lowercaseSearch)
                )
            );
        }

    }, [clients, lowercaseSearch]);/*la funzione verrà eseguita ogni volta che la variabile verrà aggiornata*/

    /*const [page, setPage] =useState(0)*/
    const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const t = useTranslationHook()

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} onChange={(event, newValue) => {
                    handleChange(event, newValue)
                }} aria-label="basic tabs example">
                    <Tab style={{ ...textBold }} label= {t("_urgentSituation")} {...a11yProps(0)} />
                    <Tab style={{ ...textBold }} label= {t("_listOfClients")} {...a11yProps(1)} />
                    <Tab style={{ ...textBold }} label= {t("_listOfCareProfessionals")}  {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
                <UrgentSituations />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <div className="my-5">
                    <TextField
                        autoComplete="off"
                        className="w-full"
                        label="Search" placeholder={t("_insertNameAnd/OrSurname")}
                        value={currentSearch || ''}
                        onChange={(e) =>
                            setSearchParams({
                                'currentSearch': e.currentTarget.value
                            })
                            //setSearchParams('currentSearch',e.currentTarget.value)
                            //currentSearch(e.currentTarget.value)
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                >
                                    <FontAwesomeIcon onClick={() => { window.location.href = `/home?currentSearch=${currentSearch}` }} icon={['fas', 'magnifying-glass']} />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div className="my-5">
                    <div className="flex justify-start h-full items-start">
                        <div className="flex items-center">
                            <div className="haal-subtitle font-bold"> {t("_filterByFocus")} </div>
                            <button className="ml-4" onClick={() => {
                                setOnlyPreferred(!onlyPreferred);
                                // refetch({ onlyPreferred })
                            }}>
                                <FontAwesomeIcon className={`h-7 w-7 ${onlyPreferred === false ? 'text-gray-200' : 'heart-icon-color'}`} icon={['fas', 'flag']} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="my-5">
                    <div className="w-100">
                        {/* { loading===true && (
                            <Spinner/>)
                        } */}
                        {loading===true ? 
                        <div className="w-full h-full flex items-center justify-center">
                        <Spinner/> 
                        </div> :
                        (
                            <TableContainer component={Paper}>
                                <Table className="haal-table" sx={{ minWidth: 650 }} style={{ fontWeight: 'bold' }} aria-label="simple table">
                                    <TableHead >
                                        <TableRow >
                                            <TableCell style={{ ...cellStyle }}> {t("_name")} </TableCell>
                                            <TableCell style={{ ...cellStyle }} align="center"> {t("_address/RoomNr")} </TableCell>
                                            <TableCell style={{ ...cellStyle }} align="center"> {t("_age")} </TableCell>
                                            {/* <TableCell style={{ ...cellStyle }} align="center"> {t("_weight")} </TableCell> */}
                                            <TableCell style={{ ...cellStyle }} align="center"> {t("_dementiaLevel")} </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {(this.state.rowsPerPage > 0
                                                ? this.state.patientsFiltered.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage) : this.state.patientsFiltered)
                                                .filter(client => client?.anagraphic.name?.toLocaleLowerCase().includes(this.state.currentSearch.toLocaleLowerCase()) === true)
                                                .map((client: Client) => (
                                                    <ClientTableSingleRow key={client.id} client={client} clientChanged={() => this.prepareClients()} />
                                                ))} */}
                                        {/* SECTIO TO HADLE INFINITE PAGINATION WITH THE NEXT TOKEN FIELD OF LISTCLIENTS CALL */}
                                        {patientsFiltered && patientsFiltered.map((client: Client) => (

                                            <ClientTableSingleRow key={client.id} client={client} />
                                        ))}

                                        {nextToken && (
                                            <TableRow>
                                                <TableCell colSpan={7} align="center">
                                                    <button onClick={() => {
                                                        alert(t("_loadMoreData"))
                                                    }}>
                                                        {t("_loadOthers" )}
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                        }   
                    </div>
                </div>
                <div className="haal-overlay-container">
                    <Fab color="primary" aria-label="add" onClick={() => {
                        setCreateClientModalOpenState(true)
                        //useState({ createClientModalOpenState: true })
                    }}>
                        <AddIcon />
                    </Fab>
                    <Modal
                        open={createClientModalOpenState}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <>
                            <CreateUpdateClient close={() => {
                                setCreateClientModalOpenState(false);
                                // refetch();
                            }} />
                        </>
                    </Modal>
                </div>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <div className="w-full h-full">
                    <UsersList />
                </div>
            </TabPanel>
        </>
    );
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
//export const HomeWithRouter = withRouter(Home);