import { useQuery } from "@apollo/client";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Device } from "../../model/device.model";
import { LIST_DEVICES, LIST_DEVICES_TYPE } from "../../services/bff/device-queries";
import { cellStyle } from "../../services/haal-mui-theme/haal-mui-theme";
import { useTranslationHook } from "../../translations/traductios";
import { deviceBackground, getImageNameFromDeviceName } from "../../utils/haal-specific-utils";
import { cleanEnum, enumToTranslate } from "../../utils/utils";

export function DevicesList() {

const t = useTranslationHook()

    const [devices, setDevices] = useState<Device[]>([])
    const { loading, error, data } = useQuery<LIST_DEVICES_TYPE>(LIST_DEVICES, {
        onCompleted: (data) => {
            const tmpDevices = data.listDevices.items
            setDevices(tmpDevices);

        },
        onError: (err) => {
            console.log(err)
        }
    });
    return (
        <div className="p-5">
            <div className="haal-title"> {t("_devicesList")} </div>
            <div className="mt-5">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} style={{ fontWeight: 'bold' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ ...cellStyle }}> {t("_deviceName")} </TableCell>
                                <TableCell style={{ ...cellStyle }} align="center"> {t("_client")} </TableCell>
                                <TableCell style={{ ...cellStyle }} align="center">{t("_assignedFrom")}</TableCell>
                                <TableCell style={{ ...cellStyle }} align="center">{t("_assignedTo")}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {devices.length === 0 &&

                                < TableRow className="bg-[#F7F7F7]">
                                    <TableCell colSpan={5} align="center">
                                        <div className="haal-title">
                                            {t("_noDevices")}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            }
                            {devices.map((e: Device) => {
                                console.log(e)
                                return (
                                    < TableRow className="bg-[#F7F7F7]">
                                        <TableCell>
                                            <Link to={`/single-profile/${e.clientId}?deviceId=${e.deviceId}`}>
                                                <div className="flex items-center">

                                                    <div className={`icon-wrapper h-12 w-12 border border-[#dedede] black ${deviceBackground(e.deviceName)} mr-5`}>
                                                        <img className="h-10" src={process.env.PUBLIC_URL + '/' + getImageNameFromDeviceName(e.deviceName)} alt="" />
                                                    </div>
                                                    <div className="haal-subtitle font-bold">
                                                        {t(enumToTranslate(e.deviceName))}
                                                    </div>
                                                </div>
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">{e?.client?.anagraphic.name} {e?.client?.anagraphic.surname}</TableCell>
                                        <TableCell align="center">{format(parseISO(e.start_time), "dd-MM-yyyy HH:mm:ss")}</TableCell>
                                        <TableCell align="center">{format(parseISO(e.end_time), "dd-MM-yyyy HH:mm:ss")}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div >

    )
}