import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal } from "@mui/material"
import { format } from "date-fns"
import { useState } from "react"
import { NoteOverided, NoteType } from "../../../model/client.model"
import { SingleClientT } from "../../../services/bff/client-queries"
import { useTranslationHook } from "../../../translations/traductios"
import { ClientNoteModal } from "./ClientNoteModal"

export function ClientNotes(props: {
    client: SingleClientT
    notes: NoteOverided[]
}) {

    const t = useTranslationHook()  
    const limitOfNoteOnListing = 4
    const noteTypes = [
        NoteType.GENERAL
    ]
    /*  let navigate = useNavigate();
     const params = useParams(); // Unpacking and retrieve id
     const id = params.clientId as Client['id'] */
    /* const [client, setClient] = useState<SingleClientT | undefined>(props.client || undefined) */
    const client: SingleClientT = props.client

    const [corePlanExpandedStatus, setCorePlanExpandedStatus] = useState<boolean>(false)
    const [expectedBehaviorExpandedStatus, setExpectedBehaviorExpandedStatus] = useState<boolean>(false)
    const [noteSelected, setNoteSelected] = useState<NoteOverided | undefined>(undefined)
    const [generalPlanExpandedStatus, setGeneralExpandedStatus] = useState<boolean>(false)
    const noteClient: NoteOverided[] = props.notes
    /* const [noteClient, setNoteClient] = useState<NoteOverided[]>(props.notes) */
    const [createClientNoteModalOpenState, setCreateClientNoteModalOpenState] = useState<{
        openState: boolean,
        type: NoteType,
    }>({
        openState: false,
        type: NoteType.GENERAL
    })

    const getNotes = (type: NoteType): NoteOverided[] => {
        /**
         ** Recupero le note per tiro 
         */
        let noteOdType = noteClient
            .filter((el: NoteOverided) => { return el.noteType === type })
            .sort(function (a, b) {
                return (a.timestamp > b.timestamp) ? -1 : ((a.timestamp < b.timestamp) ? 1 : 0);
            });
        return noteOdType || []
    }
    /* 
        const [corePlanExpandedStatus, setCorePlanExpandedStatus] = useState<boolean>(false)
    const [expectedBehaviorExpandedStatus, setExpectedBehaviorExpandedStatus] = useState<boolean>(false)
    const [generalPlanExpandedStatus, setGeneralExpandedStatus] = useState<boolean>(false)
    */
    const setNoteExpandedByType = (type: NoteType) => {

        switch (type) {
            case NoteType.CORE_PLAN:
                setCorePlanExpandedStatus(!corePlanExpandedStatus)
                break
            case NoteType.EXPECTED_BEHAVIOR:
                setExpectedBehaviorExpandedStatus(!expectedBehaviorExpandedStatus)
                break
            case NoteType.GENERAL:
                setGeneralExpandedStatus(!generalPlanExpandedStatus)
                break
        }
    }
    const getNumberOfNotesByType = (type: NoteType) => {
        return getNotes(type).length
    }
    const getExpandedStatusOfNoteSection = (noteType: NoteType): boolean => {

        switch (noteType) {
            case NoteType.CORE_PLAN:
                return corePlanExpandedStatus
            case NoteType.EXPECTED_BEHAVIOR:
                return expectedBehaviorExpandedStatus
            case NoteType.GENERAL:
                return generalPlanExpandedStatus
        }
    }
    return (<>
        {noteTypes.map(type => (
            <div key={type} className="relative shadow border border-[#dedede] rounded flex flex-col p-5 mt-5">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">

                        <div className="haal-title">
                            {t("_notes")}:
                        </div>
                        {getNotes(type).length > 2 &&

                            <div onClick={() => {
                                setNoteExpandedByType(type)
                            }} className="ml-2 haal-subtitle haal-text-underline">
                                {getExpandedStatusOfNoteSection(type) === false &&
                                    t("_viewMore")
                                }
                                {getExpandedStatusOfNoteSection(type) === true &&
                                    t("_viewLess")
                                }
                            </div>
                        }
                    </div>
                    <button onClick={() => {
                        setCreateClientNoteModalOpenState({
                            openState: true,
                            type
                        })

                    }}>
                        <div className="icon-wrapper h-10 w-10 border border-[#dedede] black mr-4" style={{ margin: 0 }}>
                            <FontAwesomeIcon icon={['fas', 'plus']} />
                        </div>
                    </button>
                </div>
                {getNotes(type).length === 0 &&
                    <div className="haal-subtitle"> {t("_noNotesFounded")}</div>
                }
                <div className={getExpandedStatusOfNoteSection(type) === false ? 'notes-list flex flex-col relative' : 'notes-list-expanded flex flex-col relative'}>
                    {
                        getNotes(type).map((nota: NoteOverided) => (
                            <div key={format(nota?.timestamp, 'dd-MM-yyyy HH:mm:ss')} className="flex items-start justify-between my-2">
                                <div className="inline-flex w-full">
                                    <div className="haal-list-decorator mr-2"></div>
                                    <div className="flex flex-col" style={{ width: "90%" }}>
                                        {/* <div className="inline-flex"> */}
                                        <div className="haal-text font-bold">
                                            {nota.description}
                                        </div>
                                        {/* </div> */}
                                        <div className="haal-text mt-3">
                                            {t("_from")} {nota?.author.username}
                                        </div>
                                        <div className="haal-text-small">
                                            {t("_on")} {
                                                format(nota?.timestamp as Date, 'dd-MM-yyyy HH:mm:ss')
                                            }
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <div className="icon-wrapper h-2 w-2 border border-[#dedede] black">
                                            <button onClick={() => {
                                                setNoteSelected({
                                                    ...nota
                                                })
                                                setCreateClientNoteModalOpenState({
                                                    openState: true,
                                                    type
                                                })
                                            }}>
                                                <FontAwesomeIcon className="align-middle h-3" icon={['fas', 'pencil']} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className={(getExpandedStatusOfNoteSection(type) === false && getNumberOfNotesByType(type) > limitOfNoteOnListing) ? 'notes-overlay' : ''} />
                </div>
            </div>
        ))}

        <Modal
            open={createClientNoteModalOpenState.openState}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ClientNoteModal
                clientId={client?.id as string}
                note={noteSelected}
                noteType={createClientNoteModalOpenState.type}
                close={() => {
                    setCreateClientNoteModalOpenState({
                        openState: false,
                        type: NoteType.GENERAL
                    })
                }} />
        </Modal>
    </>

    )
}