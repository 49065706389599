import { useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, FormControl, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import { User } from "../../model/user.model";
import {
  CREATE_CAREGIVER,
  CREATE_CAREGIVER_INPUT,
  CREATE_CAREGIVER_TYPE,
} from "../../services/bff/caregivers-queries";
import { ivalidateApolloCache } from "../../services/GraphQL/apollo-client";
import { modalMuiStyle } from "../../services/haal-mui-theme/haal-mui-theme";
import { useTranslationHook } from "../../translations/traductios";

export default function CreateUser(props: {
  close: (newUser?: Partial<User>) => void;
}) {
  const { close } = props;
  const [snackBar, setSnackBar] = useState<
    | {
      opened: boolean;
      message: string;
    }
    | undefined
  >(undefined);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [given_name, setName] = useState("");
  const [family_name, setSurname] = useState("");

  const t = useTranslationHook()

  let [createUser, { loading, error, data, called }] = useMutation<
    CREATE_CAREGIVER_TYPE,
    CREATE_CAREGIVER_INPUT
  >(CREATE_CAREGIVER, {
    onCompleted: (data) => {
      //ivalidateApolloCache()
      close({
        ...data.createCaregiver
      })
      // client.preferred = true;
  },
    onError: (error) => {
      setSnackBar({
        opened: true,
        message: t("_errorCreatingUserAssigned"),
      });
    },
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    createUser({
      variables: {
        username,
        email,
        given_name,
        family_name,
      },
    });
    //assignClient(clientId: ID!, userId: ID!, expire: AWSDateTime): Boolean
  };

  /* if (data && data.createCaregiver) {
    setSnackBar({
      opened: true,
      message: "User Created",
    });
    setTimeout(() => {
      close(data?.createCaregiver);
    }, 2500);
  } */

  return (
    <>
      <Box sx={modalMuiStyle}>
        <>
          <div className="flex justify-between items-center my-3">
            <div className="haal-title mr-4"> {t("_createCaregiver")} </div>
            <Button>
              <CloseIcon
                onClick={() => {
                  close();
                }}
              />
            </Button>
          </div>
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="grid grid-cols-2 gap-4">

              <FormControl fullWidth>
                <TextField
                  autoComplete="off"
                  required
                  disabled={loading}
                  className="w-full"
                  label= {t("_username")}
                  placeholder= {t("_username")}
                  name="username"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  autoComplete="off"
                  required
                  disabled={loading}
                  className="w-full"
                  label= {t("_email")}
                  placeholder= {t("_email")}
                  name="email"
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  autoComplete="off"
                  required
                  disabled={loading}
                  className="w-full"
                  label= {t("_name")}
                  placeholder= {t("_name")}
                  name="name"
                  value={given_name}
                  onChange={(event) => setName(event.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  autoComplete="off"
                  required
                  disabled={loading}
                  className="w-full"
                  label= {t("_surname")}
                  placeholder= {t("_surname")}
                  name="surname"
                  value={family_name}
                  onChange={(event) => setSurname(event.target.value)}
                />
              </FormControl>
            </div>

            <div className="w-full flex justify-end my-3">
              <Button
                disabled={
                  (called && !error) ||
                  username.length === 0 ||
                  email.length === 0
                }
                type="submit"
                variant="contained"
              >
                {t("_create")}
              </Button>
            </div>
          </form>
        </>
      </Box>
      {snackBar && (
        <Snackbar
          style={{
            backgroundColor: "#1976d2",
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackBar.opened}
          onClose={() => {
            setSnackBar({
              message: "",
              opened: false,
            });
          }}
          autoHideDuration={2000}
          message={snackBar.message}
        />
      )}
    </>
  );
}
