export function SignOn() {
    return (
        <>
            <div className="h-screen w-screen grid grid-cols-2">
                {/* <div className="w-full"> */}
                <div className="login-bg">
                    <div className="flex flex-col m-auto justify-center items-center h-full">
                        <img src={process.env.PUBLIC_URL + '/HAAL_logo_transparant.webp'} alt="" />
                    </div>
                </div>
                {/* </div> */}
                {/* <div className="w-full"> */}
                <div>

                    {/* <form onSubmit={(event) => doLogin(event)} className="flex flex-col h-full items-center justify-center">
                        <div className="flex flex-col w-50 justify-center mx-auto">
                            <div className="my-2">
                                <TextField
                                    type="email"
                                    name="email"
                                    onChange={(event) => setEmail(event.currentTarget.value)}
                                    label="Insert Email" placeholder="Insert Email"
                                />
                            </div>
                            <div className="flex justify-end my-2">
                                <button className="w-full">
                                    <div className="rounded p-2 shadow haal-b-bg">
                                        <div className="haal-text text-white font-bold py-1">Reset Password</div>
                                    </div>
                                </button>
                            </div>
                            <div className="flex justify-end my-2">
                                <a href="/login">Back to login</a>
                            </div>
                            <div>
                                {authError &&
                                    <div className="haal-text text-red-500 font-bold">
                                        {authError}
                                    </div>
                                }
                            </div>
                        </div>
                    </form> */}
                </div>
            </div>
        </>
    )
}