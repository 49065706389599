

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, InputAdornment, Snackbar, TextField } from "@mui/material";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const userPool = new CognitoUserPool({
  UserPoolId: "eu-west-1_xGRekyNvo",
  ClientId: "7p54eju5hv2002o92h8ebqn6at",
});


export function ForgotPassword() {
  const [resetPasswordFlowStatus, setResetPasswordFlowStatus] = useState<'INSERT_EMAIL' | 'INSERT_CODE_AND_PASSWORD'>('INSERT_EMAIL')
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [user, setUser] = useState(new CognitoUser({
    Pool: userPool,
    Username: username,
  }))
  const [requestPending, setRequestPending] = useState(false);
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const [snackBar, setSnackBar] = useState<{
    message: string,
    opened: boolean
  } | undefined>(undefined)
  const handleResetPassword = (event: any) => {
    event.preventDefault();
    setRequestPending(true)
    //inputVerificationCode: function (data) {
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        setRequestPending(false)
        setAuthError(undefined)
        setSnackBar({
          opened: true,
          message: "Password Changed Successfully",
          // shoudNavigateBackOnClose: true
        })
        setTimeout(()=>{
          navigate('/login')
        },2000)
      },
      onFailure(err) {
        setRequestPending(false)
        console.log('Password not confirmed!');
        setAuthError(err?.message || `Auth Error (${err?.name || 'Unknown'})`)
        console.log(err)
      },
    });
    //}
  }
  const handleSendCode = (event: any) => {
    event.preventDefault();
    user.forgotPassword({
      onSuccess: function (data) {
        setRequestPending(false)
        // successfully initiated reset password request
        console.log('CodeDeliveryData from forgotPassword: ' + data);
      },
      onFailure: function (err) {
        setRequestPending(false)
        setAuthError(err?.message || `Auth Error (${err?.name || 'Unknown'})`)
        console.log(err)
      },
      //Optional automatic callback
      inputVerificationCode: function (data) {
        setResetPasswordFlowStatus('INSERT_CODE_AND_PASSWORD')
      },
    });
  }
  useEffect(() => {
    setUser(new CognitoUser({
      Pool: userPool,
      Username: username,
    }))
  }, [username])
  /* const [snackBar, setSnackBar] = useState<SnackBar | undefined>(undefined); */

  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const handleClickShowConfirmPassword = () => setConfirmShowPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setConfirmShowPassword(!showConfirmPassword);
  return (
    <div className="h-screen w-screen grid grid-cols-2">
      <div className="login-bg">
        <div className="flex flex-col m-auto justify-center items-center h-full">
          <img src={process.env.PUBLIC_URL + '/HAAL_logo_transparant.webp'} alt="" />
        </div>
      </div>
      {resetPasswordFlowStatus === "INSERT_EMAIL" && (

        <div className="w-50 h-full">
          <div className="flex flex-col m-auto justify-center h-full login-container" style={{ width: "50%" }}>
            <div className="title-2 font-bold">Reset Password</div>
            <div className="subtitle-1">
              Enter your email so we can send you a verification code to reset it.</div>
            <form className="flex flex-col" onSubmit={(event) => handleSendCode(event)} action="">

              <TextField autoComplete="off" disabled={requestPending} onChange={(event) => setUsername(event.currentTarget.value.trim())} id="outlined-basic" label="Email" variant="outlined" className="!mt-2" />
              {requestPending && (
                <Button disabled={true} style={{ marginTop: "10px", minWidth: "100px" }} className="bg-1" variant="contained"><CircularProgress sx={{ color: "white" }} size="1.5rem" /></Button>
              )}
              {!requestPending && (
                <div className="flex justify-end mt-2">

                  <Button type="submit" disabled={!username || username === ''} variant="contained">Invia</Button>
                </div>
              )}
            </form>
            <div className="flex-col">
              <div className="text-2 !mt-2">
                Already have a valid code? <button onClick={() => {
                  setResetPasswordFlowStatus("INSERT_CODE_AND_PASSWORD")
                }} className="underline cursor-pointer font-bold">Go next</button>
              </div>
            </div>
            <div>
              {authError &&
                <div className="text-2 text-red-500 font-bold">
                  {authError}
                </div>
              }
              
            </div>
          </div>
        </div>

      )}
      {resetPasswordFlowStatus === "INSERT_CODE_AND_PASSWORD" && (
        <div className="w-50 h-full">
          <div className="flex flex-col m-auto justify-center h-full login-container" style={{ width: "50%" }}>
            <div className="title-2 font-bold">Reset Password</div>
            <div className="subtitle-1">Check your email in which we have sent a code to be entered below to reset your password.</div>
            <form className="flex flex-col" onSubmit={(event) => handleResetPassword(event)} action="">
              <TextField autoComplete="off" disabled={requestPending} onChange={(event) => setUsername(event.currentTarget.value)} id="outlined-basic" label="Email" variant="outlined" className="!mt-2" />
              <TextField autoComplete="off" disabled={requestPending} onChange={(event) => setVerificationCode(event.currentTarget.value)} id="outlined-basic" label="Code" variant="outlined" className="!mt-2" />
              <TextField autoComplete="off" 
                type={showPassword ? "text" : "password"}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }} 
                disabled={requestPending} onChange={(event) => setNewPassword(event.currentTarget.value)} id="outlined-basic" label="New Password" variant="outlined" className="!mt-2" />
              <TextField 
              autoComplete="off" 
              type={showConfirmPassword ? "text" : "password"}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }} 
              disabled={requestPending} onChange={(event) => setNewPasswordConfirmation(event.currentTarget.value)} id="outlined-basic" label="Confirm New Password" variant="outlined" className="!mt-2" />
              {requestPending && (
                <Button disabled={true} style={{ marginTop: "10px", minWidth: "100px" }} className="bg-1" variant="contained"><CircularProgress sx={{ color: "white" }} size="1.5rem" /></Button>
              )}
              {!requestPending && (
                <Button type="submit" disabled={!username || username === '' || !newPassword || newPassword === '' || newPassword !== newPasswordConfirmation || !verificationCode || verificationCode === ''} variant="contained" className="w-full !mt-2">Confirm</Button>
              )}
              <div className="text-2 !mt-2">
                Don't have the code yet? <strong onClick={() => {
                  setResetPasswordFlowStatus("INSERT_EMAIL")
                }} className="underline cursor-pointer">Request one</strong>
              </div>
            </form>
            <div className="flex justify-between">
            </div>
            <div>
              {authError &&
                <div className="text-2 text-red-500 font-bold">
                  {authError}
                </div>
              }
            </div>
            <div className="font-bold mt-1">
              The password must:
              <ul>
                <li>Be at least 6 characters long.</li>
                <li>Have at least one of the following criteria:
                  <div>Contain at least one numeric character</div>
                  <div>Contain at least one special character</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {snackBar && snackBar.opened &&
        <Snackbar
          style={{
            backgroundColor: '#1976d2',
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackBar.opened}
          onClose={() => {
            setSnackBar({
              ...snackBar,
              opened: false
            })
          }}
          autoHideDuration={2000}
          message={snackBar.message}
        />
      }
    </div>

  )
}


// import { Button, Snackbar } from "@mui/material";
// import TextField from "@mui/material/TextField";
// import {
//   CognitoUser,
//   CognitoUserPool,
//   CognitoUserSession
// } from "amazon-cognito-identity-js";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { getSession } from "../../services/auth/auth";
// import { useTranslationHook } from "../../translations/traductios";
// export default function ForgotPassword() {
//   const [resetPasswordFlowStatus, setResetPasswordFlowStatus] = useState<'INSERT_EMAIL' | 'INSERT_CODE_AND_PASSWORD'>('INSERT_EMAIL')

//   const [cognitoUserPool, setCognitoUserPool] = useState<CognitoUser | undefined>(undefined)
//   const [email, setEmail] = useState("");
//   const [authError, setAuthError] = useState(undefined);
//   const [emailInputDisabledStatus, setEmailInputDisabledStatus] = useState(false)
//   const [requestEmailVerificationStatus, setRequestEmailVerificationStatus] = useState(true)
//   const [requestPasswordRedStatus, setRequestPasswordResetStatus] = useState(true)
//   const [code, setCode] = useState("")
//   const [newPassword, setNewPassword] = useState("")
//   const [snackBar, setSnackBar] = useState<{
//     message: string,
//     opened: boolean
//   } | undefined>(undefined)
//   const navigate = useNavigate();

//   useEffect(() => {
//     getSession()
//       .then((cognitoUserSession: CognitoUserSession | undefined) => {
//         // add refresh?
//         if (cognitoUserSession?.isValid()) {
//           navigate('/');
//         }
//       })
//   }, [])
//   const t = useTranslationHook()

//   //Function to request a verification code for password reset
//   const requireVerificationCode = () => {
//     setEmailInputDisabledStatus(true)
//     const cognitoUser = new CognitoUser({
//       Username: email,
//       Pool: userPool
//     });

//     // call forgotPassword on cognitoUser
//     cognitoUser.forgotPassword({
//       onSuccess: function (result) {
//         setSnackBar({
//           message: t("_checkYourEmailToRetriveTheCode"),
//           opened: true,
//         })
//         setRequestEmailVerificationStatus(false)
//         setRequestPasswordResetStatus(false)
//       },
//       onFailure: function (err) {
//         setEmailInputDisabledStatus(false)
//         alert(err);
//       },
//       /*  inputVerificationCode() { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
//         var verificationCode = prompt('Please input verification code ', '');
//         var newPassword = prompt('Enter new password ', '');

//        } */
//     });
//     setCognitoUserPool(cognitoUser)
//   }
//   const requireNewPassword = () => {
//     setRequestPasswordResetStatus(true)
//     if (cognitoUserPool) {
//       cognitoUserPool.confirmPassword(code, newPassword, {
//         onSuccess: function (result) {
//           /* Mostro messaggio di successo e faccio redirect su login */
//           setSnackBar({
//             message: t("_passwordResetted"),
//             opened: true,
//           })
//           navigate('/login')
//         },
//         onFailure: function (err) {
//           setRequestPasswordResetStatus(false)
//           alert(err);
//         },
//       });
//     }
//   }
//   return (
//     <>
//       <div className="h-screen w-screen grid grid-cols-2">
//         {/* <div className="w-full"> */}
//         <div className="login-bg">
//           <div className="flex flex-col m-auto justify-center items-center h-full">
//             <img src={process.env.PUBLIC_URL + '/HAAL_logo_transparant.webp'} alt="" />
//           </div>
//         </div>
//         {/* </div> */}
//         {/* <div className="w-full"> */}
//         <div>

//           <form className="flex flex-col h-full items-center justify-center">
//             <div className="flex flex-col w-50 justify-center mx-auto">
//               {requestEmailVerificationStatus && (
//                 <div className="my-2">
//                   <TextField
//                     autoComplete="off"
//                     type="email"
//                     name="email"
//                     disabled={emailInputDisabledStatus}
//                     onChange={(event) => setEmail(event.currentTarget.value)}
//                     label={t("_insertEmail")} placeholder={t("_insertEmail")}
//                   />
//                 </div>
//               )}
//               {!requestEmailVerificationStatus && (
//                 <>
//                   <div className="my-2">
//                     <TextField
//                       autoComplete="off"
//                       type="code"
//                       name="code"
//                       disabled={requestPasswordRedStatus}
//                       onChange={(event) => setCode(event.currentTarget.value)}
//                       label={t("_insertCode")} placeholder={t("_insertCode")}
//                     />
//                   </div>
//                   <div className="my-2">
//                     <TextField
//                       autoComplete="off"
//                       type="newPassword"
//                       name="newPassword"
//                       disabled={requestPasswordRedStatus}
//                       onChange={(event) => setNewPassword(event.currentTarget.value)}
//                       label={t("_insertNewPassword")} placeholder={t("_insertNewPassword")}
//                     />
//                   </div>
//                 </>
//               )}
//               <div className="flex justify-end my-2">
//                 {requestEmailVerificationStatus && (
//                   <Button disabled={email === ''} type="button" variant="contained" onClick={(event) => {
//                     requireVerificationCode()
//                   }} >

//                     <div className="haal-text text-white font-bold py-1">{t("_requestCode")}</div>
//                   </Button>
//                 )}
//                 {!requestEmailVerificationStatus && (
//                   <Button disabled={code === '' || email === ''} type="button" variant="contained" onClick={(event) => {
//                     requireNewPassword()
//                   }} >
//                     <div className="haal-text text-white font-bold py-1">{t("_confirm")}</div>
//                   </Button>
//                 )}
//               </div>
//               <div className="flex justify-end my-2">
//                 <a href="/login">{t("_backToLogin")}</a>
//               </div>
//               <div>
//                 {authError &&
//                   <div className="haal-text text-red-500 font-bold">
//                     {authError}
//                   </div>
//                 }
//               </div>
//             </div>
//           </form>
//         </div>
//         {/* </div> */}
//       </div>
//       {snackBar && snackBar.opened &&
//         <Snackbar
//           style={{
//             backgroundColor: '#1976d2',
//           }}
//           anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//           open={snackBar.opened}
//           onClose={() => {
//             setSnackBar({
//               ...snackBar,
//               opened: false
//             })
//           }}
//           autoHideDuration={2000}
//           message={snackBar.message}
//         />
//       }
//     </>
//   );
// }