import { useMutation } from "@apollo/client";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Snackbar, TextField } from "@mui/material";
import format from "date-fns/format";
import { FormEvent, useState } from "react";
import { NoteOverided, NoteType } from "../../../model/client.model";
import { GET_CLIENT_QUERY } from "../../../services/bff/client-queries";
import { SET_NOTE } from "../../../services/bff/note-mutations";
import { modalMuiStyle } from "../../../services/haal-mui-theme/haal-mui-theme";
import { useTranslationHook } from "../../../translations/traductios";

export function ClientNoteModal(props: { close: () => void, note?: NoteOverided, noteType: NoteType, clientId: string }) {

    const t = useTranslationHook()
    
    const clientId = props.clientId
    const noteType = props.noteType
    const [note, setNote] = useState<NoteOverided|undefined>(props?.note)
    const [description, setNoteDescription] = useState<string>(note?.description || "");
    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)
    let [addClient, { data, loading, error }] = useMutation(SET_NOTE, {
        refetchQueries:[GET_CLIENT_QUERY], 
        onCompleted: (data) => {
            /* ivalidateApolloCache() */
            
            setSnackBar({
                opened: true,
                message: t("_noteCreated")
            })
            setTimeout(() =>{
                props.close()
            },2500)
        },
        onError: (error) => {
            setSnackBar({
                opened: true,
                message: t("_errorCreatingNote")
            })
        }
    });

    const disableUI = loading || data || error;
    const isNotValid = description === ""

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();


        //const timestamp = formatISO(new Date())
        //YYYY-MM-DDThh:mm:ss.sssZ
        const date = props.note?.timestamp || new Date()
        const timestamp = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
        const note = {
            clientId,
            timestamp,
            noteType,
            description
        }
        addClient(({
            variables: {
                ...note
            }
        }));
    }
    return (
        <>
            <Box sx={modalMuiStyle} >
                <div>
                    <div className="flex justify-between items-center my-3" >
                        <div className="haal-title mr-4"> {t("_createNote")}</div>
                        <Button>
                            <CloseIcon onClick={() => {
                                props.close()
                            }} />
                        </Button>
                    </div>
                    <form onSubmit={(event) => handleSubmit(event)} >
                        <div className="w-full">
                            <TextField
                                autoComplete="off"
                                required
                                disabled={disableUI}
                                className="w-full"
                                label={t("_description")} placeholder={t("_insertDescription")}
                                name="description"
                                value={description}
                                onChange={(event) => setNoteDescription(event.target.value)}
                            />
                        </div>
                        <div className="w-full flex justify-end my-3">
                            <Button disabled={isNotValid || disableUI} type="submit" variant="contained">{note === undefined ? t("_create") : t("_update")}</Button>
                        </div>
                    </form>
                </div>
            </Box >
            {snackBar && snackBar.opened &&
                <Snackbar
                    style={{
                        backgroundColor: '#1976d2',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackBar.opened}
                    onClose={() => {
                        setSnackBar({
                            message: '',
                            opened: false,
                        })
                    }}
                    autoHideDuration={2000}
                    message={snackBar.message}
                />
            }
        </>
    )
}