import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Snackbar, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Client } from "../../../model/client.model";
import { ClientNotificationSetup } from "../../shared/ClientNotificaionSetup";
import { PreferredButton } from "./ClientPreferredButton";
import { useTranslationHook } from "../../../translations/traductios";

/**
 * *Componente per gestione della singola riga del tabella
 */
export function ClientTableSingleRow({
  client,
}: {
  client: Client
}) {
  const [snackBar, setSnackBar] = useState<
    | {
        opened: boolean;
        message: string;
      }
    | undefined
  >(undefined);

  let gender = client.anagraphic.gender;
  let profilePhotoURL = undefined;
  if (client.anagraphic.profilePhotoURL) {
    profilePhotoURL = client.anagraphic.profilePhotoURL;
  } else if (client.anagraphic.profileAvatar) {
    profilePhotoURL =
      process.env.PUBLIC_URL + "/avatar/" + client.anagraphic.profileAvatar;
  } else if (!client.anagraphic.profilePhotoURL && gender) {
    if (gender === "M") {
      profilePhotoURL = process.env.PUBLIC_URL + "/male_avatar.png";
    } else {
      profilePhotoURL = process.env.PUBLIC_URL + "/female_avatar.png";
    }
  }
  const t = useTranslationHook()
  /* const setNotificationType = (notificationType: NotificationType) => {
        alert("Alert")
    } */
  return (
    <>
      <TableRow
        className="bg-[#F7F7F7]"
        key={client.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="client">
          <div className="flex items-center">
            <div className="icon-wrapper h-10 w-10 bg-[#858484]">
              {profilePhotoURL && (
                <div className="">
                  <img alt=""
                    className="hall-client-profile-image"
                    src={profilePhotoURL}
                  ></img>
                </div>
              )}
              {!profilePhotoURL && (
                <FontAwesomeIcon
                  icon={["fas", "user"]}
                  className="text-white"
                />
              )}
            </div>
            <Link
              to={`/single-profile/${client.id}`}
              className="ml-5 haal-subtitle font-bold underline"
            >
              {client.anagraphic.name} {client.anagraphic.surname}
            </Link>
            <PreferredButton client={client}/>
            <ClientNotificationSetup client={client}></ClientNotificationSetup>
          </div>
        </TableCell>

        <TableCell align="center">
          {client.anagraphic.location || t('_unknown')}
        </TableCell>
        <TableCell align="center">
          {client.anagraphic?.age || t('_unknown')}
        </TableCell>
        <TableCell align="center">
          {client.anagraphic?.weight || t('_unknown')}
        </TableCell>
        <TableCell align="center">
          {client.anagraphic?.dementiaLevel || t('_unknown')}
        </TableCell>
      </TableRow>
      {snackBar && (
        <Snackbar
          style={{
            backgroundColor: "#1976d2",
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackBar.opened}
          onClose={() => {
            setSnackBar({
              message: "",
              opened: false,
            });
          }}
          autoHideDuration={2000}
          message={snackBar.message}
        />
      )}
    </>
  );
}
