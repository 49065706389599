import { format, parseISO } from "date-fns";
import { linear } from "regression";
import { DEVICE_DATA } from "../../../../../services/bff/device-queries";
import {
  BarController,
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
export function BarPlusRegressionDailyCount({
  data,
  title,
}: {
  data: DEVICE_DATA[];
  title: string;
}) {
  // TODO FILL THE GAPS
  const dayMap = data.reduce((acc, curr) => {
    const date = format(parseISO(curr.dateTime), "yyyy-MM-dd");
    if (!acc[date]) {
      acc[date] = 0;
    }
    acc[date]++;
    return acc;
  }, {} as Record<string, number>);

  const regression = linear(
    Object.values(dayMap)
      .filter((val) => val && val > 0)
      .map((val, i) => [i, val])
  );
  const config: ChartData = {
    // type: 'scatter',
    datasets: [
      {
        data: regression.points.map(([, idx]) => regression.predict(idx)[0]),
        type: "line",
        label: "Linear Regression",
        borderColor: "red",
      },
      {
        data: Object.values(dayMap),
        type: "bar",
        label: "Events",
        backgroundColor: "blue",
      },
    ],
    labels: Object.keys(dayMap),
  };
  return (
    <Chart
      type="bar"
      options={{
        plugins: {
          title: {
            display: true,
            text: title,
          },
        },
      }}
      data={config}
    />
  );
}
