/* eslint-disable no-lone-blocks */
import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { getUserAttributes, logOutUser } from '../../../services/auth/auth';
import { User } from '../../../model/user.model';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useTranslationHook } from '../../../translations/traductios';
import { useNavigate } from 'react-router-dom';

{/*Mui Navigation Menu*/ }

export default function AccountMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const t = useTranslationHook()
    const navigate = useNavigate()
    const [caregiverName, setCaregiverName] = React.useState<User["family_name"]>(t("_u"));
    React.useEffect(() => {
        getUserAttributes().then((attrs) => {
            let concat = "";
            const familyNameAttr = attrs.find(
                (el: CognitoUserAttribute) => el.Name === t("_familyName")
            );
            if (familyNameAttr) {
                concat += familyNameAttr.Value[0];
            }
            const givenNameAttr = attrs.find(
                (el: CognitoUserAttribute) => el.Name === t("_givenName")
            );
            if (givenNameAttr) {
                concat += givenNameAttr.Value[0];
            }
            if (concat.length === 0) concat = t("_u"); //"U" is diminutive of User
            setCaregiverName(concat);
        });
    }, []);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title={caregiverName}>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}>{caregiverName}</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => {
                    navigate('profile')
                }}>
                    <Avatar /> {t("_profile")}
                </MenuItem>

                <Divider />
                <MenuItem
                    onClick={async () => {
                        logOutUser().then(() => {
                            window.location.reload();
                        });
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t("_logout")}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
