import { IconButton, Tooltip } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useEffect } from "react";
import usePushNotifications from "../../hooks/usePushNotifications";
import { useMutation } from "@apollo/client";
import { PushSubscriptionInput, SET_PUSH_SUBSCRIPTION } from "../../services/bff/notification-mutations";
import { useTranslationHook } from "../../translations/traductios";

export function PushNotificationSupport() {
  const {
    userConsent,
    pushNotificationSupported,
    askUserPermissionAction,
    susbribeToPushNotification,
    userSubscription,
    loading,
  } = usePushNotifications();

  const t = useTranslationHook()
  
  let [setPushSubsciption] = useMutation<
    boolean,
    PushSubscriptionInput
  >(SET_PUSH_SUBSCRIPTION);
  const isConsentGranted = userConsent === "granted";
  useEffect(() => {
    if (pushNotificationSupported && !isConsentGranted) {
      askUserPermissionAction();
    }
  }, [isConsentGranted, pushNotificationSupported]);
  useEffect(() => {
    if (pushNotificationSupported && isConsentGranted) {
      susbribeToPushNotification();
    }
  }, [pushNotificationSupported, susbribeToPushNotification, isConsentGranted]);
  useEffect(() => {
    if (userSubscription) {
      // useMutation
      const subscriptionJson = userSubscription.toJSON();
      setPushSubsciption({
        variables: {
          deviceId: subscriptionJson.endpoint as string,
          expirationTime: subscriptionJson.expirationTime || undefined,
          pushSubscriptionJSON: JSON.stringify(subscriptionJson)
        },
      });
    }
  }, [userSubscription, setPushSubsciption]);
  return (
    <>
      {!loading && !isConsentGranted && (
        <Tooltip title= {t("_missingAuthentication...")} >
          <IconButton size="small" sx={{ ml: 2 }}>
            <ReportProblemIcon
              sx={{ width: 32, height: 32, color: "#f7be4d" }}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
