//When the client is on the preferred section the flag turns red

import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Snackbar } from '@mui/material';
import { useState } from 'react';
import { Client } from '../../../model/client.model';
import { PREFERRED_CLIENT_ARGUMENTS_TYPE, PREFERRED_CLIENT_TYPE, SET_PREFERRED } from '../../../services/bff/client-mutations';
import { ivalidateApolloCache } from '../../../services/GraphQL/apollo-client';
import { useTranslationHook } from '../../../translations/traductios';
import Spinner from '../../shared/LoadingSpinner';


export function PreferredButton({
    client,
}: {
    client: Client
}) {
    const [snackBar, setSnackBar] = useState<
        | {
            opened: boolean;
            message: string;
        }
        | undefined
    >(undefined);

    const t = useTranslationHook()

    const [setClientOnPreferred, { loading }] = useMutation<
        PREFERRED_CLIENT_TYPE,
        PREFERRED_CLIENT_ARGUMENTS_TYPE
    >(SET_PREFERRED, {
        onCompleted: (data) => {
            ivalidateApolloCache()
            setSnackBar({
                opened: true,
                message: t("_operationMadeSuccessfully")
            })
        },
        onError: (error) => {
            setSnackBar({
                opened: true,
                message: t("_errorDuringOperation")
            })
        }
    });

    return (
        <>
            <button
                className="ml-2"
                onClick={() => {
                    setClientOnPreferred({
                        variables: {
                            clientId: client.id,
                            preferred: !client.preferred,
                        },
                    });
                }}
            >
                <>
                    {
                        loading && (
                            <Spinner />
                            // <FontAwesomeIcon
                            //     className={`h-6 w-6`}
                            //     icon={["fas", "spinner"]}
                            //     spin={true}
                            // />
                        )
                    }
                    {
                        !loading && client.preferred && (
                            <FontAwesomeIcon
                                className={`h-6 w-6 heart-icon-color`}
                                icon={["fas", "flag"]}
                            />
                        )
                    }
                    {
                        !loading && !client.preferred && (
                            <FontAwesomeIcon
                                className={`h-6 w-6 text-gray-300`}
                                icon={["fas", "flag"]}
                            />
                        )
                    }
                </>
            </button>
            {snackBar && (
                <Snackbar
                    style={{
                        backgroundColor: "#1976d2",
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={snackBar.opened}
                    onClose={() => {
                        setSnackBar({
                            message: "",
                            opened: false,
                        });
                    }}
                    autoHideDuration={2000}
                    message={snackBar.message}
                />
            )}
        </>
    );
};

export default PreferredButton;