import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Device } from "../../../../model/device.model";
import { DEVICE_DATA, DEVICE_DATA_PARSED } from "../../../../services/bff/device-queries";
import { useTranslationHook } from "../../../../translations/traductios";
import { LinePlusRegression } from "./generics/LinePlusRegression";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export function WhizPadData({
  data,
}: {
  device: Pick<
    Device,
    "clientId" | "deviceName" | "deviceId" | "start_time" | "end_time"
  >;
  from: Date;
  to: Date;
  data: DEVICE_DATA[];
}) {
  const t = useTranslationHook();
  const parsed = data.map<DEVICE_DATA_PARSED>(one => ({
    ...one,
    extra: JSON.parse(one.extra)
  }))
  return (
    <div className="shadow border h-fit border-[#dedede] bg-white rounded col-start-2 col-end-5 mt-5 p-5">
      <div className="hall-title font-bold">{t("_deviceData")}</div>
      <LinePlusRegression title="Sleep Efficency" data={parsed} extraField="sleepefficiency"></LinePlusRegression>
    </div>
  );
}
