import { useMutation } from "@apollo/client"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Snackbar, Tooltip } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { EmergencyContact } from "../../../model/client.model"
import { DELETE_CLIENT } from "../../../services/bff/client-mutations"
import { SingleClientT } from "../../../services/bff/client-queries"
import { ivalidateApolloCache } from "../../../services/GraphQL/apollo-client";
import { useTranslationHook } from "../../../translations/traductios"
import { CreateUpdateEmergencyContactModal } from "./CreateUpdateEmergencyContactModal"
library.add(
    faPhone
)
export function ClientInfo(props: { client: SingleClientT }) {

    const t = useTranslationHook()
    const client = props.client
    const [createEmergencyContactModalStatus, setCreateEmergencyContactModalStatus] = useState(false)
    const [emergencyContact, setEmergencyContact] = useState<EmergencyContact | undefined>(undefined)
    const [open, setOpen] = useState<boolean>(false);
    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)
    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const navigate = useNavigate();

    let [deleteClient, { data, loading, error }] = useMutation(DELETE_CLIENT, {
        onCompleted: (data) => {
            if (!data.deleteClient) {
                setSnackBar({
                    opened: true,
                    message: t("_errorDeletingClient")
                })
            } else {
                ivalidateApolloCache();
                setSnackBar({
                    opened: true,
                    message: t("_clientDeleted")
                })
                setTimeout(() => {
                    setOpen(false)
                }, 2500)
                navigate("/home")
            }
            //ivalidateApolloCache()

        },
        onError: (error) => {
            setSnackBar({
                opened: true,
                message: t("_errorDeletingClient")
            })
        }
    });
    const handleDeleteClient = async () => {
        /* ToDo: cancellare client */
        deleteClient(({
            variables: {
                clientId: client.id
            }
        }));
        setOpen(false);
        /* ToDo: tornare indietro */
    }
    return (
        <>
            <div className="shadow border border-[#dedede] bg-white rounded mt-5 py-5">
                <div className="w-full border-[#dedede] px-5">
                    {/* <div className="grid grid-cols-2 gap-3"> */}
                    <div className="flex-row">
                        <div className="flex items-center justify-between">
                            <div className="haal-title">{client.anagraphic.name} {client.anagraphic.surname}</div>
                            <button onClick={() => {
                                //setShowFakeMessage(true)
                                setOpen(true)
                            }}>
                                <div className="icon-wrapper h-10 w-10 border border-[#dedede] black">
                                    <FontAwesomeIcon className="inline-block align-middle haal-danger-text" icon={['fas', 'trash']} />
                                </div>
                            </button>
                        </div>
                        <div className="mt-4">
                            <div className="flex flex-row my-1">
                                <div className="haal-subtitle"> {t("_age")} </div>
                                <div className="haal-subtitle font-bold ml-2">{client?.anagraphic.age || t("_unknown")} </div>

                            </div>
                            {/*  <div className="flex flex-row my-1">
                                <div className="haal-subtitle"> {t("_weight")} </div>
                                <div className="haal-subtitle font-bold ml-2">{client?.anagraphic.weight ? client?.anagraphic.weight + t("_kg") : t("_unknown")}</div>
                            </div> */}
                            <div className="flex flex-col my-1">
                                <div className="haal-subtitle"> {t("_location")} </div>
                                <div className="haal-subtitle font-bold">{client?.anagraphic.location || t("_unknown")}</div>
                            </div>
                            <div className="mt-4">
                                <div className="flex flex-col">
                                    <div className="flex justify-between items-center">
                                        <div className="haal-subtitle font-bold"> {t("_emergencyContact")} </div>
                                        <button onClick={() => {
                                            //Prevent a cantact saved in the state
                                            setEmergencyContact(undefined);
                                            setCreateEmergencyContactModalStatus(true)
                                        }}>
                                            <div className="icon-wrapper h-7 w-7 border border-[#dedede] black mr-4" style={{ margin: 0 }}>
                                                <FontAwesomeIcon icon={['fas', 'plus']} />
                                            </div>
                                        </button>
                                    </div>
                                    <div className="mt-4">

                                        {(client.emergencyContacts === undefined || client.emergencyContacts.length === 0) &&
                                            (
                                                <>
                                                    <div className="haal-subtitle"> {t("_noEmergencyContacts")} </div>
                                                </>
                                            )
                                        }
                                        {client.emergencyContacts && client.emergencyContacts.map((contact) => {
                                            return (
                                                <div key={contact.timestamp} className="flex items-center mb-2">
                                                    <Tooltip title={`${contact.phone}`} style={{ padding: 0, fontSize: "unset" }}>
                                                        <IconButton>
                                                            <a href={`tel:${contact.phone}`}>
                                                                <FontAwesomeIcon icon={['fas', 'phone']} />
                                                            </a>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <button onClick={() => {
                                                        setEmergencyContact(contact)
                                                        setCreateEmergencyContactModalStatus(true)
                                                    }}>
                                                        <div className="ml-5 haal-subtitle underline">{contact.name}</div>
                                                    </button>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!!createEmergencyContactModalStatus &&
                (
                    <CreateUpdateEmergencyContactModal client={client} close={setCreateEmergencyContactModalStatus} emergencyContact={emergencyContact} />
                )
            }
            <Dialog
                open={open}
                /* TransitionComponent={Transition} */
                keepMounted
                onClose={() => { setOpen(false) }}
                aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle>{`Are you sure you want to delete all informations about ${client.anagraphic.name + " " + client.anagraphic.surname}?`}</DialogTitle> */}
                <DialogTitle>{t("_areYouSure...", { "name": client.anagraphic.name, "surname": client.anagraphic.surname })}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t("_thisActionWillAlso...")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false) }}> {t("_close")} </Button>
                    <Button className="haal-delete-button" onClick={() => { handleDeleteClient() }}> {t("_delete")} </Button>
                </DialogActions>
            </Dialog>
            {snackBar && snackBar.opened &&
                <Snackbar
                    style={{
                        backgroundColor: '#1976d2',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackBar.opened}
                    onClose={() => {
                        setSnackBar({
                            message: '',
                            opened: false,
                        })
                    }}
                    autoHideDuration={2000}
                    message={snackBar.message}
                />
            }

        </>
    )
}