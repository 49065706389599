import { gql } from "@apollo/client";
import { Client } from "../../model/client.model";
import { Situation } from "../../model/situation.model";
import { SingleClientT } from "./client-queries";

export const GET_SITUATIONS = gql`
    query GetSituations($onlyPreferred: Boolean!, $respondStatus: SituationStatus!){
        listClients(onlyPreferred: $onlyPreferred){
            items {
                id,
                anagraphic {
                    name
                    surname
                    age
                    location
                },
                situations(respondStatus: $respondStatus){
                    clientId
                    timestamp
                    eventType
                    respondStatus
                    description
                    author {
                    email
                    given_name
                    sub
                    family_name
                    username
                }
                    source
                }
            }
            nextToken
        }
        
    }
`
export type ClientForSituation = {
    id: Client['id'],
    anagraphic: {
        name: Client['anagraphic']['name'],
        surname: Client['anagraphic']['surname']
        age: Client['anagraphic']['age']
        location: Client['anagraphic']['location']
    }
}
export type ListClientsItem = {
    id: ClientForSituation['id'],
    anagraphic: ClientForSituation['anagraphic'],
    situations: {
        clientId: SingleClientT['id']
        timestamp: Situation['timestamp']
        eventType: Situation['eventType']
        respondStatus: Situation['respondStatus']
        description: Situation['description']
        author: Situation['author']
        source?: string
    }[]
}
export type GET_SITUATIONS_TYPE = {
    listClients: {
        items: ListClientsItem[]
    }
}