import { ApolloProvider } from "@apollo/client";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Callback from "./components/Auth/Callback";
import { ForgotPassword } from "./components/Auth/ForgotPassword";
import Login from "./components/Auth/Login";
import { SignOn } from "./components/Auth/SignOn";
import Desktop from "./components/Desktop/Desktop";
import TvMode from "./components/TvMode/TvMode";
import { LanguageContext } from "./context/LanguageContext";
import { getApolloClient } from "./services/GraphQL/apollo-client";
import { getSession } from "./services/auth/auth";
import { ThemeProvider, createTheme } from "@mui/material";
/* const userPool = new CognitoUserPool({
  UserPoolId: "eu-west-1_xGRekyNvo",
  ClientId: "7p54eju5hv2002o92h8ebqn6at",
}); */
const lang = "EN"
const theme = createTheme({
  typography: {
   "fontFamily": `"Montserrat"`,
  }
});
export function App(_props: any) {
  /* const theme = useContext(LanguageContext); */
  const [language, setLanguage] = useState(lang)
  const value = { language, setLanguage }
  return (
    <>
      <ThemeProvider theme={theme}>

        <LanguageContext.Provider value={value}>
          <ApolloProvider client={getApolloClient()}>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/sign-on" element={<SignOn />} />
                <Route path="/callback" element={<Callback />} />
                <Route path="/*" element={<ProtectedRoute redirectPath="/login" />} />
              </Routes>
            </BrowserRouter >
          </ApolloProvider>
        </LanguageContext.Provider>
      </ThemeProvider>
    </>

  )
}

function ProtectedRoute(props: { redirectPath?: string }) {
  const redirectPath = props.redirectPath || '/login';
  const [cognitoUserSession, setCognitoUserSession] = useState<CognitoUserSession | undefined>();
  const [sessionChecked, setSessionChecked] = useState<boolean>(false);
  const cognitoUserSessionValidity = cognitoUserSession?.isValid()

  useEffect(() => {
    getSession()
      .then((cognitoUserSession: CognitoUserSession | undefined) => {
        // add refresh?
        if (cognitoUserSession?.isValid()) {
          setCognitoUserSession(cognitoUserSession);
        }
        return cognitoUserSession
      })
      .then(_cognitoUserSession => new Promise(res => setTimeout(res, 1000)))
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSessionChecked(true);
      })
  }, []);

  return (
    <>
      {!sessionChecked &&
        <div style={{ height: "100vh", width: "100vw", }}>
          <div className="w-full h-full login-bg flex items-center justify-center">
            <div className="haal-text text-white font-bold py-1">We are checking your identity</div>
          </div>
        </div>
      }
      {sessionChecked && !cognitoUserSession?.isValid() && (
        <Navigate to={redirectPath} replace />
      )}
      {
        sessionChecked === true && cognitoUserSession?.isValid() &&
        <Routes>
          <Route path="/*" element={<Desktop />} />
          <Route path="/tv" element={<TvMode />} />
        </Routes>
      }

    </>
  )
  /*  return (
   <Routes>
     <Route path="/tv" element={<MultipleProfiles />} />
   </Routes>) */
}
/* const ProtectedRoute = ({
  userSession,
  redirectPath = '/login',
  children,
}) => {
  if (!userSession) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
}; */
export default App;
