import parseISO from "date-fns/fp/parseISO";

type GenericType = {
    [key: string]: any
}
/* *
* Function that clear a object from blank attributes
* @params obj: Generic object
*/
export function cleanObject(obj: GenericType) {
    for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
            delete obj[propName];
        }
    }
    return obj
}

export function cleanEnum(enumField: string) {
    return camelize(enumField.toLocaleLowerCase().replace("_", " "))
}
export function enumToTranslate(enumField: string) {
    let pieces = enumField.toLocaleLowerCase().replace("_", " ").split(' ')
    let translationLabel = '_'
    pieces.forEach((key, index) => {
        if (index === 0) {
            translationLabel += key
        } else {
            translationLabel += key.charAt(0).toUpperCase() + key.slice(1)

        }
    })
    return translationLabel
}
export function camelize(str: string) {
    const tmp = str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '').replace(/([A-Z]+)/g, ' $1').replace(/([A-Z]+)/g, ' $1').replace(/^ /, '')
    return tmp.charAt(0).toUpperCase() + tmp.slice(1);

}

export function calcYearsBetweenDates(endDate: Date, startDate: Date) {
    const ms = endDate.getTime() - startDate.getTime();
    const date = new Date(ms);
    /* console.log(Math.abs(date.getUTCFullYear() - 1970)) */
    return Math.abs(date.getUTCFullYear() - 1970);
}

export function deleteStrangeCharacters(string: string) {

    const tmp = string.replace(/_/g, ' ');
    return tmp;
}
type WithTimeStamp = {
    timestamp: string
}
type WithStartAndEnd = {
    start_time: string
    end_time?: string
}
export type OverwrittenWithTimestamp<T extends WithTimeStamp> = Omit<T, 'timestamp'> & { timestamp: Date }
export type OverwrittenWithStartAndEnd<T extends WithStartAndEnd> = Omit<T, 'start_time'> & { start_time: Date } & Omit<T, 'end_time'> & { end_time: Date | undefined }
export function overwriteTimeStamp<T extends WithTimeStamp>(arr: T[]): OverwrittenWithTimestamp<T>[] {
    const newArr = arr.reduce((accumulatore: OverwrittenWithTimestamp<T>[], current: T) => {

        accumulatore.push({
            ...current,
            timestamp: parseISO(current.timestamp)
        })

        return accumulatore;
    }, [] as OverwrittenWithTimestamp<T>[]);

    return newArr
}
export function overwriteStartAndEnd<T extends WithStartAndEnd>(arr: T[]): OverwrittenWithStartAndEnd<T>[] {
    const newArr = arr.reduce((accumulatore: OverwrittenWithStartAndEnd<T>[], current: T) => {

        accumulatore.push({
            ...current,
            start_time: parseISO(current.start_time),
            end_time: current.end_time !== undefined ? parseISO(current.end_time) : undefined
        })

        return accumulatore;
    }, [] as OverwrittenWithStartAndEnd<T>[]);

    return newArr
}

export function validatePhoneNumber(phoneNumber: string): boolean {
    const regex = /^((?![a-z]).)*$/g;
    if (regex.exec(phoneNumber) !== null) {
        // The result can be accessed through the `m`-variable.
        return true
    }

    return false
}

