import { useTranslationHook } from "../../translations/traductios"

export function Info() {
    const t = useTranslationHook()

    return (
        <>
            <div className="p-5">
                <div className="haal-title">  {t("_infoTitle")} </div>
                <div className="mt-5">  {t("_algorithmExplanation1")} </div>
                <div className="mt-5">  {t("_algorithmExplanation2")} </div>
                <div className="mt-5 !italic">  {t("_algorithmExplanation3")} </div>
                <div className="mt-5">  {t("_algorithmExplanation4")} </div>
                <div className="mt-5">  {t("_algorithmExplanation5")} </div>
                <div className="mt-5">  {t("_algorithmExplanation6")} </div>
            </div>

        </>

    )
}
