import { LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, Chart as ChartJS, LineController, BarController } from "chart.js";
import { format, subDays } from "date-fns";
import { Chart } from "react-chartjs-2";
import { ClientStatusValue } from "../../../model/client.model";
import { useTranslationHook } from "../../../translations/traductios";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
type BasicDataset = {
  type: "PHYSICAL" | "SLEEP" | "WELLBEING" | "COGNITIVE"
  items: {
    date: string,
    value: ClientStatusValue | undefined
  }[]
}
export function GenericActivityGraph(props: { dataset: BasicDataset, type: string }) {
  const t = useTranslationHook()
  let [searchParams, setSearchParams] = useSearchParams();
  const [isCurrentTypeActive, setIsCurrentTypeActive] = useState(false)
  useEffect(() => {
    const activeCode = searchParams.get('activityCode')?.toLowerCase()
    if (activeCode && activeCode === props.type.toLowerCase()) {
      setIsCurrentTypeActive(true)
    }
  }, [props.type, searchParams])
  const last30Days = []
  for (let i = 0; i < 30; i++) {
    const aa = subDays(new Date(), i * 1)
    const newDate = format(aa, 'yyyy-MM-dd')
    last30Days.push(newDate)
  }
  const last30DaysData = normalizeData(props.dataset)
  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        ticks: {
          callback: function (value: any, index: number, ticks: any) {
            switch (value) {
              case 0:
                return "No Data"
              case 1:
                return t('_badLevel')
              case 2:
                return t('_warningLevel')
              case 3:
                return t('_goodLevel')
              case 4:
                return t('_perfectLevel')

              default:
                return undefined
            };
          }
        }
      }
    }
  }
  const graphData = {
    labels: last30Days,
    type: "bar" as const,
    datasets: [

      {
        data: last30DaysData,
        backgroundColor: normalizeColors(props.dataset),
      }
    ],
  };
  return <>
    <div className={`${isCurrentTypeActive === true ? 'activity-graph-card-selected' : ''} shadow border h-fit border-[#dedede] bg-white rounded mt-5 p-5`}>
      <div className="hall-title font-bold">{t(`_${props.type}`)}</div>
      <Chart
        type="bar"
        data={graphData}
        options={options} />
    </div>
  </>

}

function normalizeData(dataset: BasicDataset) {
  const last30DaysDataset: (number)[] = []
  for (let i = 0; i < 30; i++) {
    const aa = subDays(new Date(), i * 1)
    const currDate = format(aa, 'yyyy-MM-dd')
    const finded = dataset.items
      .find((el) => el.date.includes(currDate))
    switch (finded?.value) {
      case "BAD":
        last30DaysDataset.push(1)
        break;
      case "WARNING":
        last30DaysDataset.push(2)
        break;
      case "GOOD":
        last30DaysDataset.push(3)
        break;
      case "PERFECT":
        last30DaysDataset.push(4)
        break;

      /* default:
        last30DaysDataset.push(null)
        break; */
    }
  }
  return last30DaysDataset
}
function normalizeColors(dataset: BasicDataset) {
  const last30DaysDatasetColors: (string)[] = []
  for (let i = 0; i < 30; i++) {
    const aa = subDays(new Date(), i * 1)
    const currDate = format(aa, 'yyyy-MM-dd')
    const finded = dataset.items
      .find((el) => el.date.includes(currDate))
    switch (finded?.value) {
      case "BAD":
        last30DaysDatasetColors.push("rgb(248 113 113)")
        break;
      case "WARNING":
        last30DaysDatasetColors.push("rgb(255 255 0)")
        break;
      case "GOOD":
        last30DaysDatasetColors.push("rgb(74 222 128)")
        break;
      case "PERFECT":
        last30DaysDatasetColors.push("rgb(25 94 164)")
        break;

      /*   default:
          last30DaysDatasetColors.push(null)
          break; */
    }
  }
  return last30DaysDatasetColors
}