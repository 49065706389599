import { gql } from "@apollo/client";

export const CREATE_SITUATION = gql`
  mutation CreateSituation($clientId: ID!, $eventType: String!, $respondStatus: SituationStatus!, $description: String, $timestamp: AWSDateTime) {
    createSituation(clientId: $clientId,  situation: {eventType: $eventType,  respondStatus: $respondStatus,description: $description, timestamp: $timestamp}  ) {
      clientId
      timestamp
      eventType
      description
    }
}`
export const SET_SITUATION = gql`
  mutation SetSituation($clientId: ID!, $eventType: String!, $respondStatus: SituationStatus!, $description: String, $timestamp: AWSDateTime!) {
    setSituation(clientId: $clientId,  situation: {eventType: $eventType,  respondStatus: $respondStatus,description: $description, timestamp: $timestamp}  ) {
      clientId
      timestamp
      eventType
      description
    }
}`  