import { useQuery as useQueryApollo } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { parse } from "date-fns";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Client, ClientStatusValue } from "../../../model/client.model";
import { GET_CLIENT_QUERY, GET_CLIENT_TYPE, SingleClientT } from "../../../services/bff/client-queries";
import { GenericActivityGraph } from "./GenericActivityGraph";
import { useTranslationHook } from "../../../translations/traductios";

type BasicDataset = {
  type: "PHYSICAL" | "SLEEP" | "WELLBEING" | "COGNITIVE"
  items: {
    date: string,
    value: ClientStatusValue | undefined
  }[]
}
export function Activities() {
  const t = useTranslationHook()

  const params = useParams();
  const [client, setClient] = useState<SingleClientT | undefined>(undefined)
  const [dataset, setDataSet] = useState<{ [key: string]: BasicDataset } | undefined>(undefined)  // Unpacking and retrieve id
  const id = params.clientId as Client['id']
  const [statusesSelected] = useState<string[]>(['physical', 'sleep', 'cognitive', 'wellbeing']);
  useEffect(() => {
    let wellbeingDataset: BasicDataset = {
      type: "WELLBEING",
      items: []
    }
    let cognitiveDataset: BasicDataset = {
      type: "COGNITIVE",
      items: []
    }
    let physicalDataset: BasicDataset = {
      type: "PHYSICAL",
      items: []
    }
    let sleepDataset: BasicDataset = {
      type: "SLEEP",
      items: []
    }


    wellbeingDataset.items = client?.wellbeingStatus.items.map((el) => {
      return {
        date: el.date,
        value: el?.status
      }
    }) || []
    cognitiveDataset.items = client?.cognitiveStatus.items.map((el) => {
      return {
        date: el.date,
        value: el?.status
      }
    }) || []
    physicalDataset.items = client?.physicalStatus.items.map((el) => {
      return {
        date: el.date,
        value: el?.status
      }
    }) || []
    sleepDataset.items = client?.sleepStatus.items.map((el) => {
      return {
        date: el.date,
        value: el?.status
      }
    }) || []
    setDataSet({
      wellbeingDataset,
      cognitiveDataset,
      physicalDataset,
      sleepDataset
    })
  }, [client]);
  const { error } = useQueryApollo<GET_CLIENT_TYPE>(GET_CLIENT_QUERY, {
    variables: { id },
    onCompleted: (data) => {
      let tmpClient = {
        ...data?.getClient
      }

      if (tmpClient.anagraphic?.born && typeof tmpClient.anagraphic?.born === "string") {
        const tmpBorn = parse(tmpClient.anagraphic?.born, "yyyy-MM-dd", new Date())
        setClient({
          ...tmpClient,
          anagraphic: {
            ...tmpClient.anagraphic,
            born: tmpBorn
          },
          accidents: [],
        })
      } else {
        setClient({
          ...tmpClient,
          accidents: [],
        })
      }
    }
  });
  return (
    <div className="p-5">
      <div className="flex flex-col">
        <div className="flex items-center">
          <div className="haal-title mr-4 pb-2">
            {t('_clientActivities')}
          </div>
        </div>

        <Divider />
        <div className={`grid lg:grid-cols-2 grid-cols-1 gap-2`}>
          {dataset && statusesSelected.map((statusType) => {
            return (
              <GenericActivityGraph dataset={dataset[`${statusType}Dataset`]} type={statusType} />
            )
          })}
        </div>
      </div>
    </div>
  )
}