import { useMutation } from "@apollo/client";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { differenceInHours, differenceInMinutes, format, intervalToDuration } from "date-fns";
import parseISO from "date-fns/fp/parseISO";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Situation, SituationStatus } from "../../../model/situation.model";
import { ivalidateApolloCache } from "../../../services/GraphQL/apollo-client";
import { SET_SITUATION } from "../../../services/bff/situation-mutations";
import { HaalTableColumn, HaalTableHead } from "../../../services/haal-mui-theme/haal-mui-theme";
import { useTranslationHook } from "../../../translations/traductios";
import { deviceBackground, getImageNameFromDeviceName } from "../../../utils/haal-specific-utils";
import { cleanEnum, enumToTranslate } from "../../../utils/utils";
import { HallSnackBar } from "../../shared/HallSnackBar/HallSnackBar";
library.add(
    fas,
    faCheck
)
export function UrgentSituationTable(props: {
    situations: Situation[],
    situationStatus: SituationStatus
}) {
    const t = useTranslationHook()

    const situations: Situation[] = props.situations
    const situationStatus = props.situationStatus
    let situationBg = ''
    const navigate = useNavigate();
    switch (situationStatus) {
        case t("_PENDING"):
            situationBg = 'bg-red-400'
            break;
        case t('_TAKENCHARGE'):
            situationBg = 'bg-green-400'
            break;
        default:
            situationBg = 'bg-trasparent'
            break;
    }

    const areAllPending = situations.filter(situation => situation.respondStatus === t("_pending")).length === situations.length

    const navigateToClient = (clientId: string) => {
        navigate("/single-profile/" + clientId)
    }
    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)
    /* setSituationToBeTakenCharge(situation) */
    const [situationToBeTakenCharge, setSituationToBeTakenCharge] = useState<Situation | undefined>(undefined);

    const getAuthorDenomination = (author: any): string => {
        const given_name = author.given_name || ""
        const family_name = author.family_name || ""
        const denom = (given_name + " " + family_name) !== " " ? given_name + " " + family_name : author.username
        return denom
    }
    const [showFakeMessage, setShowFakeMessage] = useState<boolean>(false)
    const calculateTimeBetween = (timestamp: string) => {
        if (timestamp) {
            const interval = intervalToDuration({
                start: parseISO(timestamp),
                end: new Date()
            });
            let intervalAsString = ''
            const fields = ['years', 'months', 'days', 'hours', 'minutes', 'seconds']
            fields.forEach((el) => {
                const value = interval[el as keyof Duration]
                if (value !== 0) {
                    intervalAsString = intervalAsString + `${value} ${el} `
                }
            })
            intervalAsString = intervalAsString + 'ago'
            return intervalAsString
            /* const date = parseISO(timestamp)
            const diffInMin = differenceInMinutes(new Date(), date)
            if (0 < diffInMin && diffInMin < 60) return `${diffInMin} minutes ago`
            const diffInHours = differenceInHours(new Date(), date)
            if (0 < diffInMin && diffInMin < 60) return `${diffInMin} minutes ago` */
        }
        return undefined
    }
    return (
        <>
            <TableContainer component={Paper}>
                <Table className="haal-table" sx={{ minWidth: 650 }} style={{ fontWeight: 'bold' }} aria-label="simple table">
                    <TableHead sx={{ ...HaalTableHead }}>
                        <TableRow>
                            <TableCell sx={{ ...HaalTableColumn, width: '16% !important' }}> {t("_name")} </TableCell>
                            <TableCell sx={{ ...HaalTableColumn }} align="center"> {t("_healthStatus")}  </TableCell>
                            <TableCell sx={{ ...HaalTableColumn, width: '16% !important' }} align="left"> {t("_situation")} </TableCell>
                            <TableCell sx={{ ...HaalTableColumn, width: '16% !important' }} align="center"> {t("_time")} </TableCell>
                            <TableCell sx={{ ...HaalTableColumn, width: '16% !important' }} align="center"> {t("_addressRoom")} </TableCell>
                            <TableCell sx={{ ...HaalTableColumn }} align="center"> {t("_respondStatus")}</TableCell>
                            {/* {areAllPending && ( */}
                            {/* )} */}
                            <TableCell sx={{ ...HaalTableColumn }} align="center"> {t("_createdBy")} </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {situations.length === 0 &&
                            <TableRow className="bg-[#F7F7F7]">
                                <TableCell colSpan={7} align="center">
                                    <div className="haal-title">
                                        {t("_noSituationAvailable", { "noSituationAvailable": (t(`_${situationStatus.replace('_', '')}`)).toLowerCase() })}
                                    </div>
                                </TableCell>
                            </TableRow>
                        }
                        {situations.map((situation, index) => {
                            const key = situation.timestamp as unknown as string
                            return (
                                <TableRow className="bg-[#F7F7F7] cursor-pointer" key={key} >
                                    <TableCell align="left">
                                        <Link to={"/single-profile/" + situation.client.id}>

                                            <div className="haal-subtitle hover:underline" style={{ fontWeight: "bold" }}>

                                                {situation.client.anagraphic.name + " " + situation.client.anagraphic.surname}
                                            </div>
                                        </Link>
                                    </TableCell>
                                    <TableCell onClick={() => { navigateToClient(situation.client.id) }} align="center">
                                        <div className="flex justify-between">
                                            <div className="flex-col">
                                                <div className="haal-pending-situations-table-situation-label">
                                                    {t("_wellbeing")}
                                                </div>
                                                <div className="haal-pending-situations-table-situation-common-circle haal-pending-situations-table-situation-circle-green"></div>
                                            </div>
                                            <div className="flex-col">
                                                <div className="haal-pending-situations-table-situation-label">
                                                    {t("_sleepStatus")}
                                                </div>
                                                <div className="haal-pending-situations-table-situation-common-circle haal-pending-situations-table-situation-circle-red"></div>
                                            </div>
                                            <div className="flex-col">
                                                <div className="haal-pending-situations-table-situation-label">
                                                    {t("_physicalActivity")}
                                                </div>
                                                <div className="haal-pending-situations-table-situation-common-circle haal-pending-situations-table-situation-circle-green"></div>
                                            </div>
                                            <div className="flex-col">
                                                <div className="haal-pending-situations-table-situation-label">
                                                    {t("_cognitiveStatus")}
                                                </div>
                                                <div className="haal-pending-situations-table-situation-common-circle haal-pending-situations-table-situation-circle-green"></div>
                                            </div>
                                        </div>
                                    </TableCell>

                                    <TableCell onClick={() => { navigateToClient(situation.client.id) }} align="left" className={situationBg + " " + (index === 0 ? 'haal-situation-table-border-radius-top' : '') + " " + (situations.length - 1 === index ? 'haal-situation-table-border-radius-bottom' : '')}>
                                        <div className="flex justify-between items-center">
                                            <div className="haal-subtitle">
                                                {t(`_${situation.eventType.toLowerCase()}`) + " " + t("_on") + " " + format(parseISO(situation.timestamp as unknown as string), "dd-MM-yyyy HH:mm:ss")}
                                            </div>
                                            <img className="hall-table-cell-icon" src={process.env.PUBLIC_URL + '/emptyFallenMan2.png'} alt="" />
                                        </div>
                                    </TableCell>
                                    <TableCell onClick={() => { navigateToClient(situation.client.id) }} align="center">
                                        <div className="haal-subtitle">
                                            {calculateTimeBetween(situation.timestamp as unknown as string) || t("_unknown")} ({t("_on")} {format(new Date(situation.timestamp), 'dd-MM-yyyy HH:mm:ss') || t("_unknown")})

                                        </div>
                                    </TableCell>
                                    <TableCell onClick={() => { navigateToClient(situation.client.id) }} align="center">
                                        <div className="haal-subtitle">
                                            {situation.client.anagraphic.location || t("_unknown")}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        {situation.respondStatus && (
                                            <div className="flex items-center justify-center">
                                                {situation.respondStatus === SituationStatus['PENDING'] && (//
                                                    <Button style={{ padding: "5px 10px 5px 10px" }} onClick={() => {
                                                        setShowFakeMessage(true)
                                                        //Mi salvo temporanemante la situazione che deve essere presa in carico
                                                        setSituationToBeTakenCharge(situation)
                                                        //setTakedOnCharge(situation)
                                                    }} variant="contained">
                                                        <div className="haal-text text-white font-bold py-1" style={{ fontSize: "0.5rem" }}>{t("_takeCharge")}</div>
                                                        {/* <button onClick={() => {
                                                            setShowFakeMessage(true)
                                                            //Mi salvo temporanemante la situazione che deve essere presa in carico
                                                            setSituationToBeTakenCharge(situation)
                                                            //setTakedOnCharge(situation)
                                                        }}>
                                                            <div className="icon-wrapper h-7 w-7 border border-[#dedede] black mr-4"></div>
                                                        </button> */}
                                                    </Button>
                                                )}
                                                {situation.respondStatus === SituationStatus['TAKEN_CHARGE'] && (//
                                                    <div className="icon-wrapper h-7 w-7 border border-[#dedede] black mr-4">
                                                        <FontAwesomeIcon icon={['fas', 'check']} />
                                                    </div>
                                                )}
                                                {situation.respondStatus !== SituationStatus['PENDING'] && (//
                                                    <div className="haal-subtitle">
                                                        {situation.respondStatus !== undefined ? t(enumToTranslate(situation.respondStatus)) : t("_unknown")}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </TableCell>
                                    {/* {areAllPending && ( */}
                                   
                                    {/*  )} */}
                                    <TableCell onClick={() => { navigateToClient(situation.client.id) }} align="center">
                                        <div className="flex justify-end">
                                            <div className="flex items-center auto">
                                                {situation.author && (
                                                    <Tooltip title={`${getAuthorDenomination(situation.author)}`} style={{ padding: 0, fontSize: "unset" }}>
                                                        <img className="h-12 w-12 hall-client-profile-image" src={process.env.PUBLIC_URL + '/garegiver-male.png'} alt=""></img>
                                                    </Tooltip>
                                                )}
                                                {situation.source && (

                                                    <Tooltip title={situation.source} style={{ padding: 0, fontSize: "unset" }}>
                                                        <div className={`icon-wrapper h-12 w-12 border border-[#dedede] black ${deviceBackground(situation.source)}`}><img className="h-10" src={getImageNameFromDeviceName(situation.source)} alt="" /></div>
                                                    </Tooltip>

                                                )}
                                                {!situation.author && !situation.source && (
                                                    <div className="haal-subtitle font-bold">
                                                        {t("_unknown")}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>

                </Table>
            </TableContainer>
            {
                snackBar && snackBar.opened &&
                <HallSnackBar
                    message={snackBar.message}
                    onClose={() => {
                        setSnackBar({
                            message: '',
                            opened: false,
                        })
                    }} />
            }
            {
                situationToBeTakenCharge && <DeleteSnackbar
                    setSituationToBeTakenCharge={setSituationToBeTakenCharge}
                    situation={situationToBeTakenCharge}
                    timeout={5}></DeleteSnackbar>

            }
        </>
    )
}



export function DeleteSnackbar({ situation, timeout, setSituationToBeTakenCharge }: { situation: Situation, timeout: number, setSituationToBeTakenCharge: Function }) {
    // const { language } = useContext(LanguageContext);
    // const t = transateLang(language);
    const t = useTranslationHook()
    const [countDownSeconds, setCountDownSeconds] = useState<number>(timeout);
    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)

    useEffect(() => {
        const timer = setInterval(() => {
            setCountDownSeconds((countDownSeconds) => --countDownSeconds)
        }, 1000)
        return () => clearInterval(timer);
    }, []);
    const [setSituation, { data, loading, error }] = useMutation(SET_SITUATION, {
        onCompleted: (data) => {
            ivalidateApolloCache()

            setSnackBar({
                opened: true,
                message: t("_situationTakenChargeSuccessfully")
            })
        },
        onError: (error) => {
            setSnackBar({
                opened: true,
                message: t("_errorOnTakingChargeSituation")
            })
        }
    });
    const timeoutEnded = countDownSeconds <= 0;
    useEffect(
        () => {
            if (timeoutEnded) {
                setSituation(({
                    variables: {
                        clientId: situation.clientId,
                        eventType: situation.eventType,
                        respondStatus: SituationStatus['TAKEN_CHARGE'], //
                        timestamp: situation.timestamp,
                        description: situation.timestamp
                    }
                }));
            }
        }, [timeoutEnded, setSituation, situation]
    );

    //`The situation will be taken care of in ${countDownSeconds} seconds`
    const message = !timeoutEnded ?
        t("_countDownSecondsSituations", { "countDownSeconds": countDownSeconds.toString() })
        : t("_deletingSituations")
    return (
        <>
            {!data && <Snackbar
                open={!!situation}
                message={message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}

                action={
                    <Button onClick={() => {
                        setSituationToBeTakenCharge(undefined)
                    }} color="inherit" size="small">
                        {t("_cancel")}
                    </Button>
                }
                sx={{ bottom: { xs: 90, sm: 0 } }}
            />
            }
            {
                snackBar && snackBar.opened &&
                <HallSnackBar
                    message={snackBar.message}
                    autoHideDuration={2000}
                    onClose={() => {
                        ivalidateApolloCache()
                        setSituationToBeTakenCharge(undefined)
                    }} />
            }</>
    )
}