import { useMutation } from "@apollo/client";
import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, Snackbar, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import format from "date-fns/format";
import { FormEvent, useState } from "react";
import { Client, DementiaLevel, Gender, LocationType } from "../../model/client.model";
import { CREATE_CLIENT, UPDATE_CLIENT } from "../../services/bff/client-mutations";
import { SingleClientT } from "../../services/bff/client-queries";
import { ivalidateApolloCache } from "../../services/GraphQL/apollo-client";
import { modalMuiStyle } from "../../services/haal-mui-theme/haal-mui-theme";
import { useTranslationHook } from "../../translations/traductios";
import { calcYearsBetweenDates, cleanObject } from "../../utils/utils";


export function CreateUpdateClient(props: {
    close: () => void, client?: SingleClientT
}) {

    const t = useTranslationHook()
    const dementiaLevelTypes = [
        {
            level: DementiaLevel.LOW,
            description: t("_earlyStage...")
        },
        {
            level: DementiaLevel.MEDIUM,
            description: t("_moderateDecline...")
        },
        {
            level: DementiaLevel.HIGH,
            description: t("_severeDecline...")
        },
        {
            level: DementiaLevel.UNKNOWN,
        },
    ]
    const [client, setClient] = useState<SingleClientT | undefined>(props.client)
    const [name, setName] = useState<string>(client?.anagraphic.name || "");
    const [surname, setSurname] = useState<string>(client?.anagraphic.surname || "");
    const [born, setBorn] = useState<Date | number | null>(client?.anagraphic?.born as Date || null); // I know that born is always a type Date
    const [gender, setGender] = useState<Gender.M | Gender.F | undefined>(client?.anagraphic?.gender || undefined); // I know that born is always a type Date
    const [weight, setWeight] = useState<number | undefined>(client?.anagraphic.weight || undefined);
    const [age, setAge] = useState<number | undefined>(client?.anagraphic.age || undefined);
    const [location, setLocation] = useState<SingleClientT['anagraphic']['location'] | undefined>(client?.anagraphic?.location || undefined)
    const [profilePhotoURL, setProfilePhotoURL] = useState<SingleClientT['anagraphic']['profilePhotoURL'] | undefined>(client?.anagraphic?.profilePhotoURL || undefined)
    const [profileAvatar, setProfileAvatar] = useState<string | undefined>(client?.anagraphic?.profileAvatar || undefined)
    const [uploadSetctionVisible, setUploadSetctionVisible] = useState<boolean>(false)
    const [dementiaLevel, setDementiaLevel] = useState<string | undefined>(client?.anagraphic.dementiaLevel || undefined);
    const [locationType, setLocationType] = useState<LocationType.INTRAMURAL | LocationType.EXTRAMURAL>(client?.anagraphic.locationType || LocationType.INTRAMURAL);
    const [locationTypeStatus, setLocationTypeStatus] = useState<boolean>(client?.anagraphic.locationType === 'INTRAMURAL' ? true : false);
    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)
    /* *
    * Handle Client Creation GraphQL request
    */
    let [addClient, createClientRes] = useMutation(CREATE_CLIENT, {
        onCompleted: (data) => {
            ivalidateApolloCache()
            setSnackBar({
                opened: true,
                message: t("_clientCreated")
            })
            setTimeout(() => {
                props.close()
            }, 2500)
        },
        onError: (erro) => {
            setSnackBar({
                opened: true,
                message: t("_errorCreatingClient")
            })
        }
    });
    let [updateClient, updateClientRes] = useMutation(UPDATE_CLIENT, {
        onCompleted: (data) => {
            ivalidateApolloCache()
            setSnackBar({
                opened: true,
                message: t("_clientUpdated")
            })
            setTimeout(() => {
                props.close()
            }, 2500)
        },
        onError: (erro) => {
            setSnackBar({
                opened: true,
                message: t("_errorUpdatingClient" )
            })
        }
    });

    const disableUI = createClientRes.loading || createClientRes.data || createClientRes.error;

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isModify = client !== undefined
        let clientForm = cleanObject({
            name: name.trim(),
            surname: surname.trim(),
            born: !born ? undefined : format(born, 'yyyy-MM-dd'),
            weight,
            age,
            dementiaLevel,
            locationType,
            location,
            gender,
            profilePhotoURL,
            profileAvatar
        }) as Client['anagraphic']
        if (isModify) {

            updateClient(({
                variables: {
                    clientId: client.id,
                    ...clientForm
                }
            }));
        } else {
            addClient(({ variables: { ...clientForm } }));
        }
    }

    /* *
    * Check that both: name and surname are setted
    */
    const isNotValid = name === "" || surname === ""

    return (
        <>
            <Box sx={modalMuiStyle} >
                <div>
                    <div className="flex justify-between items-center my-3" >
                        <div className="flex flex-col">

                            <div className="haal-title">{client ? t("_updateClient") : t("_createClient")}</div>
                            {!client && (
                                <div className="haal-subtitle mt-1" style={{ maxWidth: "70%" }}> 
                                {t("_theFollowingClientWill...")} 
                                </div>
                            )}
                        </div>
                        <Button>
                            <CloseIcon onClick={() => {
                                props.close()
                            }} />
                        </Button>
                    </div>
                    <form onSubmit={(event) => handleSubmit(event)} >
                        <div className="flex flex-col content-end my-2">
                            <div className="haal-subtitle font-bold"> {t("_locationType")} </div>
                            <FormControl fullWidth>
                                <FormControlLabel control={<Switch
                                    className="w-full"
                                    disabled={disableUI}
                                    /* labelId="location-type" */
                                    id="location-type"
                                    /* label="Location Type" */
                                    /* type="text" */
                                    /* value={locationType} */
                                    checked={locationTypeStatus}
                                    onChange={(event) => {
                                        /* console.log(event.target.checked) */
                                        setLocationTypeStatus(!locationTypeStatus)
                                        const locationType = event.target.checked === true ? LocationType.INTRAMURAL : LocationType.EXTRAMURAL
                                        setLocationType(locationType)
                                    }}
                                    defaultChecked />} label={t("_intramural")}/>
                            </FormControl>
                        </div>
                        <div className="grid grid-cols-3 2xl:grid-cols-2 gap-4">
                            <TextField
                                autoComplete="off"
                                required
                                disabled={disableUI}
                                className="w-full"
                                label= {t("_name")} placeholder= {t("_insertName")}
                                name="name"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                            <TextField
                                autoComplete="off"
                                required
                                disabled={disableUI}
                                className="w-full"
                                label={t("_surname")} placeholder= {t("_insertSurname")}
                                name="surname"
                                value={surname}
                                onChange={(event) => { setSurname(event.target.value) }
                                }
                            />
                            <FormControl fullWidth>
                                <InputLabel id="gender"> {t("_gender")} </InputLabel>
                                <Select
                                    autoComplete="off"
                                    className="w-full"
                                    disabled={disableUI}
                                    labelId="gender"
                                    id="gender"
                                    label= {t("_gender")} 
                                    type="text"
                                    value={gender}
                                    onChange={(event) => { setGender(event.target.value as Gender) }}
                                >
                                    <MenuItem value={undefined}> {t("_selectGender")} </MenuItem>
                                    <MenuItem value={Gender['M']}>{Gender['M']}</MenuItem>
                                    <MenuItem value={Gender['F']}>{Gender['F']}</MenuItem>

                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    disableMaskedInput={true}
                                    label= {t("_born(MM/dd/YYY)")}
                                    disabled={disableUI}
                                    maxDate={new Date()}
                                    inputFormat="dd/MM/yyyy"
                                    value={born}
                                    onChange={(event) => {
                                        if (event && event instanceof Date) {
                                            setBorn(event)
                                            const calculatedAges = calcYearsBetweenDates(new Date(), event)
                                            setAge(calculatedAges)
                                        }
                                    }
                                    }
                                    renderInput={(born) => <TextField disabled autoComplete="off" name="born" onKeyDown={(e)=>{e.preventDefault();}} {...born} />}
                                />
                            </LocalizationProvider>
                            <TextField
                                autoComplete="off"
                                className="w-full"
                                disabled={true}
                                label= {t("_age")} placeholder= {t("_age")}
                                name="age"
                                value={age}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                onChange={(event) => { setAge(parseInt(event.target.value)) }
                                }
                            />
                            <FormControl fullWidth>
                                <InputLabel id="dementia-level"> {t("_dementiaLevel")} </InputLabel>
                                <Select
                                    className="w-full"
                                    disabled={disableUI}
                                    labelId="dementia-level"
                                    id="dementia-level"
                                    label= {t("_dementiaLevel")}
                                    type="text"
                                    value={dementiaLevel}
                                    onChange={(event) => { setDementiaLevel(event.target.value) }}
                                >
                                    <MenuItem value={undefined}> {t("_selectOne")} </MenuItem>
                                    {dementiaLevelTypes.map(one => (
                                        <MenuItem key={one.level} value={one.level}>
                                            {/*  <div className="flex-col"> */}
                                            {one.level + " (" + (one?.description || t("_noDementiaLevelDescription" )) + ")"}
                                            {/* </div> */}</MenuItem>
                                    ))}
                                    {/* <MenuItem value='HIGH'>High</MenuItem>
                                        <MenuItem value="MEDIUM">Medium</MenuItem>
                                        <MenuItem value="LOW">LOW</MenuItem> */}
                                </Select>
                            </FormControl>
                            {/* <TextField
                                autoComplete="off"
                                className="w-full"
                                label={t("_weight")}
                                disabled={disableUI}
                                name="weight"
                                value={weight}
                                onChange={(event) => { setWeight(parseInt(event.target.value)) }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{t("_kg")}</InputAdornment>,
                                }}
                            /> */}
                            {/* <FormControl fullWidth>
                                <FormControlLabel control={<Switch defaultChecked />} label="Intramural" />
                            </FormControl> */}
                            {/* <FormControl fullWidth>
                                <InputLabel id="location-type">Location Type</InputLabel>
                                <Select
                                    className="w-full"
                                    disabled={disableUI}
                                    labelId="location-type"
                                    id="location-type"
                                    label="Location Type"
                                    type="text"
                                    value={locationType}
                                    onChange={(event) => { setLocationType(event.target.value) }}
                                    required
                                >
                                    <MenuItem key="Intramural (Room Number)" value="Intramural (Room Number)">
                                        Intramural (Room Number)
                                    </MenuItem>
                                    <MenuItem key="Extramural (Address)" value="Extramural (Address)">
                                        Extramural (Address)
                                    </MenuItem>
                                </Select>
                            </FormControl> */}
                            <TextField
                                autoComplete="off"
                                className="w-full"
                                disabled={disableUI}
                                label={locationType} placeholder={locationType}
                                name="location"
                                value={location}
                                type="text"
                                onChange={(event) => { setLocation(event.target.value) }
                                }
                            />
                            <TextField
                                autoComplete="off"
                                className="w-full"
                                disabled={disableUI}
                                label= {t("_profilePhotoURL")} placeholder= {t("_profilePhotoURL")}
                                name="profilePhotoURL"
                                value={profilePhotoURL}
                                type="text"
                                onChange={(event) => {
                                    setProfilePhotoURL(event.target.value)
                                }
                                }
                            />
                            <></>
                            <div className="col-start-1 col-end-4">
                                <div className="haal-subtitle mb-2 font-bold">
                                    {t("_avatar")}
                                </div>
                                <div className="haal-fake-input-label-container" >
                                    <div className="grid grid-cols-8">
                                        {/* Se ti stai facendo delle domande sul perchè questa cosa.... La risposta è che gli avatar vanno da 1 a 5 */}
                                        {[1, 2, 3, 4, 5].map(e => {
                                            const avatarImg = e + '.png'
                                            return (
                                                <button type="button" onClick={() => { setProfileAvatar(avatarImg) }} className="mx-auto mt-2">
                                                    <div className={`${avatarImg === profileAvatar ? 'active-avatar' : ''} icon-wrapper h-12 w-12 border border-[#dedede] black haal-b-bg`}>
                                                        <img className="h-10" src={process.env.PUBLIC_URL + '/avatar/' + avatarImg} alt="" />
                                                    </div>
                                                </button>

                                            )

                                        })}
                                        {[1, 2, 3, 4, 5].map(e => {
                                            const avatarImg = e + 'w.png'
                                            return (

                                                <button type="button" onClick={() => { setProfileAvatar(avatarImg) }} className="mx-auto mt-2">
                                                    <div className={`${avatarImg === profileAvatar ? 'active-avatar' : ''} icon-wrapper h-12 w-12 border border-[#dedede] black haal-b-bg`}>
                                                        <img className="h-10" src={process.env.PUBLIC_URL + '/avatar/' + avatarImg} alt="" />
                                                    </div>
                                                </button>
                                            )

                                        })}
                                        <button type="button" className="mx-auto mt-2" onClick={() => { setUploadSetctionVisible(true) }}>
                                            <div className={`icon-wrapper h-12 w-12 border border-[#dedede] black haal-b-bg`}>
                                                <div className="haal-title text-white">
                                                    {t("_+")}
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="haal-subtitle mb-2 font-bold">
                                    Avatar
                                </div> */}
                            {uploadSetctionVisible &&

                                <div className="col-start-1 col-end-4">
                                    <div className="flex justify-between items-center">
                                        <div className="haal-title">
                                            {t("_uploadPhoto")}
                                        </div>
                                        <Button

                                            variant="contained"
                                            component="label"
                                        >
                                            {t("_uploadPhoto")}
                                            <input
                                                type="file"
                                                hidden
                                            />
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="w-full flex justify-end my-3">
                            <Button disabled={isNotValid || disableUI} type="submit" variant="contained">
                                {client ? t("_update") : t("_create")}
                            </Button>
                        </div>
                    </form>
                </div>
            </Box >
            {snackBar &&
                <Snackbar
                    style={{
                        backgroundColor: '#1976d2',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackBar.opened}
                    onClose={() => {
                        setSnackBar({
                            message: '',
                            opened: false,
                        })
                    }}
                    autoHideDuration={2000}
                    message={snackBar.message}
                />
            }
        </>
    );
}