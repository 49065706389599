import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, Divider, Tooltip } from "@mui/material"
import { isBefore } from "date-fns"
import queryString from "query-string"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Client } from "../../../model/client.model"
import { Device } from "../../../model/device.model"
import { useTranslationHook } from "../../../translations/traductios"
import { deviceBackground, getImageNameFromDeviceName } from "../../../utils/haal-specific-utils"
import { overwriteStartAndEnd } from "../../../utils/utils"
import { ClientDevicePage } from "./ClientDevicePage"
import { CreateUpdateDevice } from "./CreateUpdateDevice"
export function useQuerRoutery() {
    const { search } = useLocation();

    return new URLSearchParams(search)
}
export function ClientDevices(props: {
    devices: Pick<Device, 'clientId' | 'deviceName' | 'deviceId' | 'start_time' | 'end_time'>[],
    updateDevices: () => void
}) {

    const t = useTranslationHook()
    const params = useParams(); // Unpacking and retrieve id
    const query = queryString.parse(window.location.search)
    const clientId = params.clientId as Client['id']
    const [deviceIdSelected, setDeviceIdSeletec] = useState<Device['deviceId'] | null>(typeof query['deviceId'] === 'string' ? query['deviceId'] : null)

    //Overwrite del timestamp dei dispositivi da stringa a Date
    const devices = overwriteStartAndEnd<Pick<Device, 'clientId' | 'deviceName' | 'deviceId' | 'start_time' | 'end_time'>>(props.devices || [])
    /* const [isOnPreference, setIsOnPreference] = useState<boolean>(false) */
    const [addDeviceModalOpenState, setAddDeviceModalOpenState] = useState<boolean>(false)
    /* const deviceSelected = devices.find((e: Device) =>
        e.deviceId === deviceIdSelected
    ) */
    const navigate = useNavigate();
    const [deviceSelected, setDeviceSelected] = useState<typeof props.devices[0] | undefined>(devices.find((e) =>
        e.deviceId === deviceIdSelected
    ))

    const [generalError, setGeneralError] = useState<string | undefined>(() => {
        if (!deviceSelected && deviceIdSelected) {
            return t("_deviceNotFounded")
        }
    })
    useEffect(()=>{
        const finded = devices.find((d)=>d.deviceId === deviceSelected?.deviceId)
        if(!finded){
            setDeviceSelected(undefined)
        }
        else{
            setDeviceSelected(finded)
        }
    },[props])
    return (
        <>
            <div className="flex justify-between">

                <div className="haal-title">
                    {t("_devices")}
                </div>
                <button onClick={() => {
                    setAddDeviceModalOpenState(true)
                }}>
                    <div className="icon-wrapper h-10 w-10 border border-[#dedede] black mr-4" style={{ margin: 0 }}>
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                    </div>
                </button>
            </div>
            {devices.length === 0 &&
                <div className="flex my-2">
                    <div className="haal-subtitle"> {t("_noDevicesAssigned")} </div>
                </div>
            }
            <div className="flex my-2">
                {devices.map((device) => {
                    if (device.end_time && isBefore(device.end_time, new Date())) {
                        return (
                            <div className="mr-5">
                                <Tooltip title={device.deviceId}>

                                    <Badge badgeContent={<FontAwesomeIcon icon={['fas', 'door-closed']} style={{ color: "white", height: "10px" }} />} color="primary">
                                        <button onClick={() => { setDeviceSelected(device) }} >
                                            <div className={`icon-wrapper h-12 w-12 border border-[#dedede] black ${deviceBackground(device.deviceName)} `}>
                                                <img className="h-10" src={process.env.PUBLIC_URL + '/' + getImageNameFromDeviceName(device.deviceName)} alt="" />
                                            </div>
                                        </button>
                                    </Badge>
                                </Tooltip>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="mr-5">
                                <Tooltip title={device.deviceId}>
                                    <button onClick={() => { setDeviceSelected(device) }} >
                                        <div className={`icon-wrapper h-12 w-12 border border-[#dedede] black ${deviceBackground(device.deviceName)} `}>
                                            <img className="h-10" src={process.env.PUBLIC_URL + '/' + getImageNameFromDeviceName(device.deviceName)} alt="" />
                                        </div>
                                    </button>
                                </Tooltip>
                            </div>
                        )
                    }
                })}
            </div>
            {generalError &&
                <>
                    <Divider />
                    <div className="rounded haal-error-section mt-5 p-5">
                        {generalError}
                    </div>
                </>
            }
            {deviceSelected &&
                <ClientDevicePage device={deviceSelected} clientId={clientId}/>
            }
            {addDeviceModalOpenState && (
                <CreateUpdateDevice clientId={clientId} close={() => { setAddDeviceModalOpenState(false) }} />
            )}
        </>

    )
}