import { gql } from "@apollo/client";
import { Client } from "../../model/client.model";
import { getApolloClient, ivalidateApolloCache } from "../GraphQL/apollo-client";

type CREATE_CLIENT_TYPE = {
    createClient: {
        id: string,
        name: string;
        surname: string;
    };
}
export const CREATE_CLIENT = gql`
    mutation CreateClient($name: String!, $surname: String!, $age: Int, $born: AWSDate, $dementiaLevel: DementiaLevel, $weight: Int, $location: String,$locationType: LocationType!,$gender: Gender, $profilePhotoURL: String, $profileAvatar: String) {
        createClient(clientAnagraphic: {name: $name, surname: $surname, age: $age, born: $born, dementiaLevel: $dementiaLevel, weight: $weight, location: $location,locationType: $locationType, gender:$gender, profilePhotoURL: $profilePhotoURL, profileAvatar: $profileAvatar}) {
            id,
            anagraphic{
                name,
    		    surname,
                age,
                born,
                dementiaLevel,
                weight,
                location,
                locationType,
                gender,
                profilePhotoURL,
                profileAvatar
            }
        }
}
`;
export const UPDATE_CLIENT = gql`
    mutation UpdateClient($clientId: ID!, $name: String!, $surname: String!, $age: Int, $born: AWSDate, $dementiaLevel: DementiaLevel, $weight: Int, $location: String,$locationType: LocationType!,$gender: Gender, $profilePhotoURL: String, $profileAvatar: String) {
        updateClient(clientId: $clientId, clientAnagraphic: {name: $name, surname: $surname, age: $age, born: $born, dementiaLevel: $dementiaLevel, weight: $weight, location: $location, locationType: $locationType,gender:$gender, profilePhotoURL: $profilePhotoURL, profileAvatar: $profileAvatar}) {
            id,
            anagraphic{
                name,
    		    surname,
                age,
                born,
                dementiaLevel,
                weight,
                location,
                locationType,
                gender,
                profilePhotoURL,
                profileAvatar
            }
        }
}
`;
export const DELETE_CLIENT = gql`
mutation DeleteClient($clientId: ID!) {
    deleteClient(clientId: $clientId)
}`;
export const ARCHIVE_CLIENT = gql`
mutation ArchiveClient($clientId: ID!) {
    archiveClient(clientId: $clientId)
}`;
/** 
* TODO: IMPLEMENTARE GIUSTA MUTAZIONE
*/
export const SET_CLIENT_ON_PREFERRED = gql`
    mutation CreateClient($name: String!, $surname: String!, $age: Int, $born: AWSDate, $dementiaLevel: DementiaLevel, $weight: Int) {
    createClient(clientAnagraphic: { name: $name, surname: $surname, age: $age, born: $born, dementiaLevel: $dementiaLevel, weight: $weight }) {
        id,
            anagraphic{
            name,
                surname
        }
    }
}
`;
/* 
mutation CreateClient($name: String!, $surname: String!, $age: Int, $born: AWSDate, $dementiaLevel: DementiaLevel, $weight: Int) {
createClient(clientAnagraphic: {name: $name, surname: $surname, age: $age, born: $born, dementiaLevel: $dementiaLevel, weight: $weight}) {
    id
}
}
*/
export const createClient = async (client: Client['anagraphic']): Promise<any> => {

    const { data, extensions, context } = await getApolloClient().mutate<CREATE_CLIENT_TYPE>({
        mutation: CREATE_CLIENT,
        variables: {
            name: client.name,
            surname: client.surname
        }
    })

    if (data) {
        const clientCreated = data.createClient
        ivalidateApolloCache()
        return {
            id: clientCreated.id,
            anagraphic: {
                name: clientCreated.name,
                surname: clientCreated.name,
            }
        }
    } else {
        throw new Error("eRORRE")
    }
}
export type PREFERRED_CLIENT_TYPE = {
    setClientPreferred: {
        preferred: boolean
    };
}
export type PREFERRED_CLIENT_ARGUMENTS_TYPE = {
    clientId: string,
    preferred: boolean
}

export const SET_PREFERRED = gql`
    mutation setClientPreferred($clientId: ID!, $preferred: Boolean!) {
    setClientPreferred(clientId: $clientId, preferred: $preferred)
} `
export const SET_NOTIFICATION_TYPE = gql`
    mutation SetClientNotificationType($clientId: ID!, $notificationType: NotificationType!) {
    setClientNotificationType(clientId: $clientId, notificationType: $notificationType){
        id
        notificationType
    }
} `


export async function setClientPreferred(clientId: Client['id'], preferred: Client['preferred']): Promise<any> {
    const { data, extensions, context } = await getApolloClient().mutate<PREFERRED_CLIENT_TYPE>({
        mutation: SET_PREFERRED,
        variables: {
            clientId,
            preferred
        }
    })
    if (data) {
        const preferred = data.setClientPreferred
        ivalidateApolloCache()
        return {
            preferred
        }
    } else {
        throw new Error("eRORRE")
    }
}