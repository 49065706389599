import {
  BarController,
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { format, parseISO } from "date-fns";
import { Chart } from "react-chartjs-2";
import { linear } from "regression";
import { DEVICE_DATA_PARSED } from "../../../../../services/bff/device-queries";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
export function LinePlusRegression({
  data,
  title,
  extraField,
}: {
  data: DEVICE_DATA_PARSED[];
  title: string;
  extraField: string;
}) {
  const dataVal = data
    .map((val, i) => [
      parseISO(val.dateTime).getTime(),
      (val.extra as any)[extraField] as number,
    ])
    .filter((one) => one[1] >= 0) as [number, number][];
  const regression = linear(dataVal);
  const scales = dataVal.map((one) => one[0]);
  const config: ChartData = {
    // type: 'scatter',
    labels: dataVal.map(([date]) => date),

    datasets: [
      {
        data: regression.points.map(([, idx]) => regression.predict(idx)[0]),
        type: "line",
        label: "Linear Regression",
        borderColor: "red",
      },
      {
        spanGaps: true,
        data: dataVal.map((one) => one[1]),
        type: "line",
        label: "Events",
        borderColor: "blue",
        backgroundColor: "blue",
        // segment(ctx, options) {
        // TODO in teoria serve epr colorare le righe
        //   console.log(ctx, options);
        //   const color = 'blue'
        //   return {
        //     ...ChartJS.defaults.elements.line
        //   } as LineOptions
        // },
        tension: 0.7,
      },
    ],
  };
  return (
    <Chart
      type="line"
      data={config}
      options={{
        plugins: {
          title: {
            display: true,
            text: title
          }
        },
        scales: {
          x: {
            ticks: {
              callback: (tickValue, idx) => {
                return format(new Date(scales[idx]), "yyyy-MM-dd");
              },
            },
          },
        },
      }}
    />
  );
}
