import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Device } from "../../../../model/device.model";
import {
  DEVICE_DATA,
  DEVICE_DATA_PARSED,
} from "../../../../services/bff/device-queries";
import { useTranslationHook } from "../../../../translations/traductios";
import { format, isSameDay, parseISO } from "date-fns";
import { BarPlusRegressionDailyAvg } from "./generics/BarPlusRegressionDailyAvg";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export function TiprData({
  data,
}: {
  device: Pick<
    Device,
    "clientId" | "deviceName" | "deviceId" | "start_time" | "end_time"
  >;
  from: Date;
  to: Date;
  data: DEVICE_DATA[];
}) {
  const t = useTranslationHook();
  const parsedData = data.map((one) => ({
    ...one,
    extra: JSON.parse(one.extra),
  })) as DEVICE_DATA_PARSED<{
    score: number;
    level: number;
    hand: "dx" | "sx";
    success_rate: number;
  }>[];
  const withScore = parsedData.filter((one) => one.extra.success_rate > 0);
  const sumSuccessRate = withScore.reduce(
    (acc, one) => one.extra.success_rate + acc,
    0
  );
  const avgSuccessRate = sumSuccessRate / withScore.length;
  const last = withScore[withScore.length - 1];
  const lastDiff =
    ((last.extra.success_rate - avgSuccessRate) / avgSuccessRate) * 100;
  const last3Before = withScore.filter(
    (one, idx) => idx >= withScore.length - 4 && idx < withScore.length - 1
  );
  const lastDate = parseISO(last.dateTime);
  const lastDay = withScore.filter((one) =>
    isSameDay(parseISO(one.dateTime), lastDate)
  );
  // TODO KPI
  return (
    <div className="shadow border h-fit border-[#dedede] bg-white rounded col-start-2 col-end-5 mt-5 p-5">
      <div className="hall-title font-bold">
        {t("_deviceData")} in selected period
      </div>
      <div className="relative shadow border border-[#dedede] rounded p-5 mt-5">
        <div className="flex flex-col items-center justify-between">
          <div className="haal-title">Last Game Level: {last.extra.level}</div>
          <div className="flex-row">
            <img className="h-11" src="/tipr.png" alt="" />
            <h4 className={(lastDiff < 0 ? "red" : "green") + " inline-block"}>
              {lastDiff > 0 ? "+" : ""}
              {lastDiff.toFixed(0)} %
            </h4>
            <p className="inline-block text-xs px-5">
              Score of the last compared to average client score for the period
            </p>
          </div>
          <ol>
            {last3Before.map((game) => (
              <li className="inline-block px-3" key={game.dateTime}>
                {game.extra.success_rate} %
              </li>
            ))}
          </ol>
        </div>
        <div className="flex flex-col items-center justify-between">
          <div className="haal-title">
            Day: {format(lastDate, "yyyy-MM-dd")}
          </div>
          <div className="flex-row">
            <div className="flex">
              <img className="h-11" src="/tipr.png" alt="" />
            </div>

            <h4 className={(lastDiff < 0 ? "red" : "green") + "inline-block"}>
              {lastDiff > 0 ? "+" : ""}
              {lastDiff.toFixed(0)} %
            </h4>
            <ol>
              {lastDay.map((game) => (
                <li className="inline-block px-3" key={game.dateTime}>
                  Score Level {game.extra.level}: {game.extra.success_rate} %
                </li>
              ))}
            </ol>
          </div>
          <ol>
            {last3Before.map((game) => (
              <li className="inline-block px-3" key={game.dateTime}>
                {game.extra.success_rate} %
              </li>
            ))}
          </ol>
        </div>
      </div>
      <BarPlusRegressionDailyAvg
        title="Average Score"
        data={withScore}
        extraField="score"
      ></BarPlusRegressionDailyAvg>
    </div>
  );
}
