import { alpha, createTheme, InputBase } from "@mui/material";

import { styled } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
})

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    margin: 0,
    width: '100%',
    display: "flex",
    flexWrap: "wrap",
    flex: "0 1 auto",
    flexFlow: "column-reverse",
    maxWidth: "100%",
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('a')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: "0px",
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
        border: "1px solid #333333",
        borderRadius: 20
    },
}));
export const modalMuiStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
};
export {
    theme,
    Search,
    SearchIconWrapper,
    StyledInputBase
};

export const HaalTable = {
    borderRadius: 15, border: "1px solid #dcdcdc !important"
}
export const HaalTableColumn = {
    fontWeight: 'bold',
}
export const HaalTableHead = {
    backgroundColor: '#DCDCDC'
}
export const textBold = {
    fontWeight: "bold",
    fontSize: "0.9rem"
}
export const cellStyle = {
    fontWeight: "bold",
    fontSize: "0.9rem"
}
/* const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip { ...props } classes = {{ popper: className }} />
)) (({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
})); */