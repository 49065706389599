import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getSession, userPool } from "../auth/auth";
import { setContext } from '@apollo/client/link/context';

/* const errorLink = onError(({ graphqlErrors, networkError }) => {
    if (graphqlErrors) {
        graphqlErrors.map(({ message, location, path }) => {
            alert(`Graphql error ${message}`);
        });
    }
}); */

const uri = "https://n7f7qvaxqzgrlk5zfhxcebpdfq.appsync-api.eu-west-1.amazonaws.com/graphql"
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            switch (err?.extensions?.code) {
                // Apollo Server sets code to UNAUTHENTICATED
                // when an AuthenticationError is thrown in a resolver
                /* FIXME.. non avremo il codice UNAUTHENTICATED*/
                case "UNAUTHENTICATED":
                    // Modify the operation context with a new token
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: userPool.getCurrentUser()?.getUserAttributes,
                        },
                    });
                    // Retry the request, returning the new observable
                    return forward(operation);
            }
        }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }
});

const link = from([
    errorLink,
    new HttpLink({ uri: uri }),
]);
const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const session = await getSession()
    const token = session?.getAccessToken().getJwtToken()
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `${token}` : "",
        }
    }
});
let client: ApolloClient<NormalizedCacheObject>;

export function getApolloClient(): ApolloClient<NormalizedCacheObject> {
    if (!client) {
        client = createNewClient();
    }
    return client;
}
export const ivalidateApolloCache = () : void=>{
    getApolloClient().cache.reset()
}
function createNewClient() {
    return new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(getLink()),
        resolvers: []
    });
}
function getLink() {
    return link;
}
function getErrorLink() {
    return errorLink;
}