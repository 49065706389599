import { useMutation, useQuery } from "@apollo/client";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormEvent, useEffect, useState } from "react";
import { Client } from "../../../model/client.model";
import { Situation, SituationStatus } from "../../../model/situation.model";
import { listClients } from "../../../services/bff/client-queries";
import { CREATE_SITUATION } from "../../../services/bff/situation-mutations";
import { GET_SITUATIONS, GET_SITUATIONS_TYPE, ListClientsItem } from "../../../services/bff/situation-querie";
import { modalMuiStyle } from "../../../services/haal-mui-theme/haal-mui-theme";
import { useTranslationHook } from "../../../translations/traductios";
import { HallSnackBar } from "../../shared/HallSnackBar/HallSnackBar";
import { UrgentSituationTable } from "./UrgentSituationTable";
/* 
    Funzione che ricostruisce la lista dei client per avere alla fine un array sisituazioni. La fuznione prende come secondo parametro il metodo per il setState delle situazioni.
    In questo modo posso riutilizzarla indipendentemente dalla tipologia delle situazioni. Nel caso in cui in futuro si aggiuge una nuova situazione, basta recuperare le situazioni
    in questione e utilizzare il clear sottostante passandogli i dati sporchi e il SET dello stato
    */
const situationsClenar = (listClientItems: ListClientsItem[]): Situation[] => {
    const situations: Situation[] = []
    listClientItems.forEach(client => {
        client.situations.forEach(situation => {
            // Ricostruisco l'oggetto
            const currSituation = {
                ...situation,
                client: {
                    id: client.id,
                    anagraphic: {
                        name: client.anagraphic.name,
                        surname: client.anagraphic.surname,
                        age: client.anagraphic.age,
                        location: client.anagraphic.location,
                    }
                },
                
            }
            situations.push(currSituation)
        })
    })
    return situations
}
export function UrgentSituations() {
    //respondStatus
    const [situationsPending, createSituationsPending] = useState<Situation[]>([])
    const [situationsTakenCharge, createSituationsTakenCharge] = useState<Situation[]>([])
    const [addSituationModalOpenState, setAddSituationModalOpenState] = useState<boolean>(false)
    //addSituationModalOpenState
    useEffect(() => {
        if (addSituationModalOpenState) {
            setDate(new Date())
            setHour(new Date())
        }
    }, [addSituationModalOpenState]);
    const listClientsForPendingSituations = useQuery<GET_SITUATIONS_TYPE>(GET_SITUATIONS, {
        variables: {
            onlyPreferred: false,
            respondStatus: SituationStatus['PENDING']
        },
        onCompleted: (data) => {
            createSituationsPending(situationsClenar(data.listClients.items))
        },
        onError: (error) => {
            console.log(error)
        }
    });
    const listClientsForTakenChargeSituations = useQuery<GET_SITUATIONS_TYPE>(GET_SITUATIONS, {
        variables: {
            onlyPreferred: false,
            respondStatus: SituationStatus['TAKEN_CHARGE']
        },
        onCompleted: (data) => {
            createSituationsTakenCharge(situationsClenar(data.listClients.items))

        },
        onError: (error) => {
            console.log(error)
        }
    });
    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)

    const t = useTranslationHook()

    const [clientId, setClientId] = useState<Situation["clientId"] | undefined>(undefined)
    const [clients, setClients] = useState<Client[]>([])
    const [eventType, setEventType] = useState<Situation["eventType"] | undefined>(undefined)
    const [respondStatus, setRespondStatus] = useState<Situation["respondStatus"] | undefined>(undefined)
    const [date, setDate] = useState<Date | null>(new Date())
    const [hour, setHour] = useState<Date | null>(new Date())
    const [description, setDescription] = useState<string>("")
    const [createSituation] = useMutation(CREATE_SITUATION, {
        refetchQueries: [GET_SITUATIONS],
        onCompleted: (data) => {
            /* ivalidateApolloCache() */

            setSnackBar({
                opened: true,
                message: t("_situationCreated")
            })
            resetForm()
            setTimeout(() => {
                setAddSituationModalOpenState(false)
            }, 2500)
        },
        onError: (error) => {
            setSnackBar({
                opened: true,
                message: t("_errorCreatingSituation")
            })
        }
    });
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // console.log([eventType, respondStatus, date, hour?.getHours()])
        createSituation(({
            variables: {
                clientId,
                eventType,
                respondStatus,
                timestamp: date,
                description
            }
        }));

    }
    const resetForm = () => {
        setSelectedClient(null)
        setClientId(undefined)
        setEventType(undefined)
        setRespondStatus(undefined)
        setDate(null)
        setHour(null)
        setDescription("")
    }
    useEffect(() => {
        listClients().then((res) => {
            setClients(res.items)
        });
    }, [])
    const disableUI = false
    const [selectedClient, setSelectedClient] = useState<Client | null>(null)
    const isNotValid = !eventType || !respondStatus || !clientId
    //const res = await listClients(this.state.onlyPreferred);
    return (
        <div className="my-5">
            <div className="flex mb-2 justify-end">
                <Button onClick={() => {
                    setAddSituationModalOpenState(true)
                }} variant="contained">
                    <div className="haal-text text-white font-bold py-1 underline">
                        {t("_createNew")}
                    </div>
                </Button>
            </div>
            <div className="w-100">
                {/* <UrgentSituationTable situations={situationsPending} situationStatus={SituationStatus["PENDING"]} /> */}
                <Accordion className="haal-expansion-panel" expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <div className="haal-title">
                            {t("_pendingSituations")}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UrgentSituationTable situations={situationsPending} situationStatus={SituationStatus["PENDING"]} />
                    </AccordionDetails>
                </Accordion>
                <Accordion className="haal-expansion-panel mt-3">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <div className="haal-title">
                            {t("_takenChargeSituations")}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UrgentSituationTable situations={situationsTakenCharge} situationStatus={SituationStatus["TAKEN_CHARGE"]} />
                    </AccordionDetails>
                </Accordion>
            </div>
            {addSituationModalOpenState && (

                <div>
                    <Box sx={modalMuiStyle} style={{ zIndex: 999 }}>
                        <div>
                            <div className="flex justify-between items-center my-3" >
                                <div className="haal-title mr-4">
                                    {t("_createUrgentSituation")}
                                </div>
                                <Button>
                                    <CloseIcon onClick={() => {
                                        resetForm()
                                        setAddSituationModalOpenState(false)
                                    }} />
                                </Button>
                            </div>

                            <form onSubmit={(event) => handleSubmit(event)} >
                                <div className="grid grid-cols-2 gap-4">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={disableUI}
                                            id="country-select-demo"
                                            sx={{ width: 300 }}
                                            options={clients}
                                            autoHighlight
                                            value={selectedClient}
                                            getOptionLabel={(client) => (client.anagraphic?.name || '') + (" " + client.anagraphic?.surname)}
                                            /*  getOptionDisabled={(client) => {
                                                const founded = caregiversAlreadyAssigned.find((e: User) => {
                                                return e.username === caregiver.username
                                                })
                                                return !!founded
                                             }} */
                                            onChange={(event, newValue) => {
                                                setSelectedClient(newValue)
                                                setClientId(newValue?.id)
                                            }}
                                            renderOption={(props, client) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {/* <div className="icon-wrapper h-7 w-7 bg-[#858484] mr-4">
                                                        <img className="hall-client-profile-image" src={process.env.PUBLIC_URL + '/garegiver-male.png'}></img>

                                                    </div> */}
                                                    <div className="haal-subtitle font-bold">
                                                        {(client.anagraphic?.name || '') + (" " + client.anagraphic?.surname)}
                                                    </div>
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("_chooseClient")}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="eventType"> {t("_selectEventType")} </InputLabel>
                                        <Select
                                            required
                                            className="w-full"
                                            disabled={false}
                                            labelId="eventType"
                                            id="eventType"
                                            label={t("_selectEventType")}
                                            type="text"
                                            value={eventType}
                                            onChange={(event) => {
                                                setEventType(event.target.value)
                                            }}
                                        >
                                            <MenuItem value={undefined}> {t("_selectOne")} </MenuItem>
                                            <MenuItem value={"FALL"}> {t("_fall")} </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="respondStatus"> {t("_selectRespondStatus")} </InputLabel>
                                        <Select
                                            required
                                            className="w-full"
                                            disabled={false}
                                            labelId="respondStatus"
                                            id="respondStatus"
                                            label= {t("_selectRespondStatus")} 
                                            type="text"
                                            value={respondStatus}
                                            onChange={(event) => {
                                                setRespondStatus(event.target.value as unknown as SituationStatus)
                                            }}
                                        >
                                            <MenuItem value={undefined}> {t("_selectOne")} </MenuItem>
                                            <MenuItem value={SituationStatus['PENDING']}> {t("_pending")} </MenuItem>
                                            <MenuItem value={SituationStatus['TAKEN_CHARGE']}> {t("_takenCharge")}</MenuItem>
                                            <MenuItem value={SituationStatus['MONITORING']}> {t("_monitoring")} </MenuItem>
                                            {/* <MenuItem value={SituationStatus['SOLVED']}>Solved</MenuItem> */}
                                        </Select>
                                    </FormControl>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker

                                            label={t("_situationOf")}
                                            disabled={false}
                                            inputFormat="dd/MM/yyyy"
                                            value={date}
                                            maxDate={new Date()}
                                            onChange={(event) => {
                                                if (event && event instanceof Date) {
                                                    setDate(event)
                                                }
                                            }
                                            }
                                            renderInput={(assignedOn) => <TextField autoComplete="off" name="assignedOn" {...assignedOn} />}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            label={t("_hour")}
                                            value={hour}
                                            ampm={false}
                                            onChange={(newHour) => {
                                                console.log(newHour)
                                                setHour(newHour);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        autoComplete="off"
                                        type="desription"
                                        name="desription"
                                        disabled={disableUI}
                                        onChange={(event) => setDescription(event.currentTarget.value)}
                                        value={description}
                                        label={t("_addMoreEventInfo")} placeholder={t("_addMoreEventInfo")}
                                    />

                                </div>
                                <div className="w-full flex justify-end my-3">
                                    <Button disabled={isNotValid} type="submit" variant="contained">
                                        {t("_createSituation")}
                                    </Button>
                                </div>
                            </form>


                        </div>
                    </Box >
                    {
                        snackBar && snackBar.opened &&
                        <HallSnackBar
                            message={snackBar.message}
                            onClose={() => {
                                setSnackBar({
                                    message: '',
                                    opened: false,
                                })
                            }} />
                    }
                </div >
            )
            }
        </div>
    )
}