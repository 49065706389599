import { Situation } from "./situation.model"
import { User } from "./user.model"
export type AttentiveTheme = {
    name: string,
    modeName: string,
    lastStatus: string,
    isOnPreference: boolean,
    fields: {
        id: number,
        name: string,
        isOnMark: boolean,
        showArrow: boolean, value: number, label: string
    }[]
}
export type Accident = {
    event: string,
    situation: string,
    time: string,
    location: number,
    resStatus: string,
}
export type Client = {

    id: string,
    anagraphic: {
        name: string,
        surname: string,
        age?: number,
        weight?: number,
        disease?: string,
        born?: Date | string
        gender?: Gender
        dementiaLevel?: DementiaLevel
        location?: string
        locationType?: LocationType
        profilePhotoURL?: string
        profileAvatar?: string
    }
    situations: Situation[],
    preferred?: boolean
    notificationType?: NotificationType
}

export type ClientStatus = {
    type: "WELLBEING" | "PHYSICAL" | "COGNITIVE" | "SLEEP",
    date: Date,
    status?: ClientStatusValue
    urgency?: ClientStatusUrgency,
}
export type ClientWellbeingStatus = ClientStatus & {
    type: "WELLBEING",
}
export type ClientPhysicalStatus = ClientStatus & {
    type: "PHYSICAL",
}
export type ClientCognitiveStatus = ClientStatus & {
    type: "COGNITIVE",
}
export type ClientSleepStatus = ClientStatus & {
    type: "SLEEP",
}

export enum ClientStatusUrgency {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export enum ClientStatusValue {
    BAD = "BAD",
    WARNING = "WARNING",
    GOOD = "GOOD",
    PERFECT = "PERFECT"
}
export enum Gender {
    M = "M",
    F = "F",
}
export type Note = {
    noteType: NoteType
    timestamp: string
    description: string
    author: User
    client: Client
}
export type NoteOverided = Omit<Note, 'timestamp'> & { timestamp: Date }

export enum DementiaLevel {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
    UNKNOWN = "UNKNOWN",
}
export enum LocationType {
    INTRAMURAL = "INTRAMURAL",
    EXTRAMURAL = "EXTRAMURAL",
}
export enum NoteType {
    CORE_PLAN = "CORE_PLAN",
    EXPECTED_BEHAVIOR = "EXPECTED_BEHAVIOR",
    GENERAL = "GENERAL",
}
export type EmergencyContact = {
    name: string,
    phone: string,
    timestamp: string
}
export type EmergencyContactOverided = Omit<EmergencyContact, 'timestamp'> & { timestamp: Date }
export enum NotificationType {
    NULL = "NULL",
    ALL = "ALL",
    ONLY_ALARM = "ONLY_ALARM"
}