import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import { useTranslationHook } from '../../../translations/traductios';

function LanguagesMenu() {

    const t = useTranslationHook()
    const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
        null
    );
    const openLang = Boolean(anchorElLang);
    const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElLang(event.currentTarget);
    };
    const handleLanguageClose = () => {
        setAnchorElLang(null);
    };
    const { language, setLanguage } = React.useContext(LanguageContext);
    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t("_changeLanguage")}>
                <IconButton
                    onClick={handleLanguageClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={openLang ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openLang ? 'true' : undefined}
                >
                    <Avatar sx={{ width: 32, height: 32 }}>
                        <Button
                            id="basic-button"
                            aria-controls={openLang ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openLang ? "true" : undefined}
                            onClick={handleLanguageClick}
                        >
                            <img
                                alt={language + " flag"}
                                className="h-6 w-6 hall-client-profile-image"
                                src={process.env.PUBLIC_URL + "/" + language + ".png"}
                            ></img>
                        </Button>
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorElLang}
                open={openLang}
                onClose={handleLanguageClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={(event) => {
                    console.log("click it")
                    setLanguage("EN");
                    
                }}>
                    <IconButton>
                        <img alt=''
                            className="h-6 w-6 hall-client-profile-image"
                            src={process.env.PUBLIC_URL + "/en.png"}
                            ></img>
                    </IconButton>
                </MenuItem>
                <MenuItem onClick={(event) => {
                    console.log("click it")
                    setLanguage("IT");
                    
                }}>
                    <IconButton>
                        <img alt=''
                            className="h-6 w-6 hall-client-profile-image"
                            src={process.env.PUBLIC_URL + "/it.png"}
                            ></img>
                    </IconButton>
                </MenuItem>
                <MenuItem onClick={handleLanguageClose}>
                    <IconButton
                        onClick={() => {
                            console.log("click nl")
                            setLanguage("NL");
                        }}
                    >
                        <img alt=''
                            className="h-6 w-6 hall-client-profile-image"
                            src={process.env.PUBLIC_URL + "/nl.png"}
                        ></img>
                    </IconButton>
                </MenuItem>
                <MenuItem onClick={handleLanguageClose}>
                    <IconButton
                        onClick={() => {
                            setLanguage("TW");
                            console.log("click tw")
                        }}
                    >
                        <img alt=''
                            className="h-6 w-6 hall-client-profile-image"
                            src={process.env.PUBLIC_URL + "/tw.png"}
                        ></img>
                    </IconButton>
                </MenuItem>
            </Menu>
        </Box>
    );
}
export default LanguagesMenu;