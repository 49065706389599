import { useMutation } from "@apollo/client"
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, TextField } from "@mui/material"
import { format } from "date-fns"
import parseISO from "date-fns/fp/parseISO"
import { FormEvent, useEffect, useState } from "react"
import { EmergencyContact } from "../../../model/client.model"
import { SingleClientT } from "../../../services/bff/client-queries"
import { DELETE_EMERGENCY_CALL, SET_EMERGENCY_CALL } from "../../../services/bff/emergency-contant-mutations"
import { ivalidateApolloCache } from "../../../services/GraphQL/apollo-client"
import { modalMuiStyle } from "../../../services/haal-mui-theme/haal-mui-theme"
import { validatePhoneNumber } from "../../../utils/utils"
import { HallSnackBar } from "../../shared/HallSnackBar/HallSnackBar"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useTranslationHook } from "../../../translations/traductios"
export function CreateUpdateEmergencyContactModal(props: { contact?: EmergencyContact; client: SingleClientT, emergencyContact?: EmergencyContact, close: Function }) {

    const t = useTranslationHook()

    const client = props.client
    const emergencyContact = props.emergencyContact
    const [createEmergencyContactModalStatus, setCreateEmergencyContactModalStatus] = useState(false)
    const [name, setName] = useState<EmergencyContact['name'] | undefined>(emergencyContact?.name || undefined)
    const [phone, setPhone] = useState<EmergencyContact['phone'] | undefined>(emergencyContact?.phone || undefined)
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false)
    const [snackBarMessage, setSnackBarMessage] = useState<string>("")
    useEffect(() => {
        console.log(t("_changed"), snackBarOpen);
    }, [snackBarOpen])
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        /* TODO CONTROLLO CHE IL NUMERO DI TELEFONO ABBIA SOLO NUMERI E AL MASSIMO IL + */
        if (phone && !validatePhoneNumber(phone)) {
            setSnackBarOpen(true)
            setSnackBarMessage(t("_invalidPhoneNumber"))
            return
        }
        const date = emergencyContact?.timestamp !== undefined ? parseISO(emergencyContact?.timestamp) : new Date()
        const timestamp = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
        setEmergencyContact(({
            variables: {
                name,
                phone,
                timestamp,
                clientId: client.id
            }
        }));

    }
    let [setEmergencyContact, { data, loading, error }] = useMutation(SET_EMERGENCY_CALL, {
        onCompleted: (data) => {
            ivalidateApolloCache()
            setSnackBarOpen(true)
            setSnackBarMessage(t("_emergencyContactSaved"))
            /* setSnackBar({
                opened: true,
                message: "Note created"
            }) */
            setTimeout(() => {
                props.close()
            }, 2500)
        },
        onError: (error) => {
            setSnackBarOpen(true)
            setSnackBarMessage(t("_errorSavingContact"))

        }
    });

    let [deleteEmergencyContact, deleterionRes] = useMutation(DELETE_EMERGENCY_CALL, {
        onCompleted: (data) => {
            ivalidateApolloCache()
            setSnackBarOpen(true)
            setSnackBarMessage(t("_emergencyContactDeleted"))
            /* setSnackBar({
                opened: true,
                message: "Note created"
            }) */
            setTimeout(() => {
                props.close()
            }, 2500)
        },
        onError: (error) => {
            setSnackBarOpen(true)
            setSnackBarMessage(t("_errorDeletingContact"))

        }
    });
    const disableUI = loading || data || error || deleterionRes.data || deleterionRes.loading || deleterionRes.error;
    const isNotValid = name === "" || phone === ""
    return (
        <>
            <Box sx={modalMuiStyle} style={{ zIndex: 999 }} >
                <div>
                    <div className="flex justify-between items-center my-3" >
                        <div className="haal-title mr-4">{emergencyContact !== undefined ? t("_modifyEmergencyContact") : t("_createEmergencyContact")}</div>
                        <Button>
                            <CloseIcon onClick={() => {
                                props.close(false)
                            }} />
                        </Button>
                    </div>

                    <form onSubmit={(event) => handleSubmit(event)} >
                        <div className="grid grid-cols-2 gap-4">
                            <TextField
                                autoComplete="off"
                                required
                                disabled={disableUI}
                                className="w-full"
                                label={t("_name")} placeholder={t("_name")}
                                name="name"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                            
                            <PhoneInput
                                className="haal-phone-number-style"
                                autoComplete="off"
                                required
                                label={t("_phoneNumber")}
                                placeholder={t("_phone")}
                                name="phone"
                                value={phone}
                                onChange={(event) => {
                                    
                                    setPhone(event)
                                }} />
                                
                            {/* 
                            <TextField
                                autoComplete="off"
                                required
                                type="tel"
                                disabled={disableUI}
                                className="w-full"
                                label="Phone number"
                                placeholder="Phone"
                                name="name"
                                value={phone}
                                onChange={(event) => {
                                    
                                    setPhone(event.target.value)
                                }}
                            />
                                */}


                        </div>
                        <div className="w-full flex justify-between my-3">
                            {emergencyContact &&
                                <Button onClick={() => {
                                    deleteEmergencyContact(({
                                        variables: {
                                            name,
                                            phone,
                                            timestamp: emergencyContact.timestamp,
                                            clientId: client.id
                                        }
                                    }))
                                }} style={{ backgroundColor: "red" }} variant="contained">
                                    {t("_delete")}
                                </Button>
                            }
                            <Button disabled={isNotValid || disableUI} type="submit" variant="contained">
                                {emergencyContact !== undefined ? t("_save") : t("_create")}
                            </Button>
                        </div>
                    </form>


                </div>
            </Box >
            {snackBarOpen &&

                <HallSnackBar
                    message={snackBarMessage}
                    onClose={() => {
                        setSnackBarOpen(false);
                    }} />
            }
        </>
    )
}