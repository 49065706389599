import { Snackbar, SnackbarProps } from "@mui/material"

export function HallSnackBar(props: SnackbarProps) {
    const message = props.message
    /* const props.autoHideDuration = props.autoHideDuration || 2000; */
    return (
        <Snackbar
            style={{
                backgroundColor: '#1976d2',
            }
            }
            {...props}
            open={true}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={props.autoHideDuration || 2000}
            message={props.message}
        />
    )
}