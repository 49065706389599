import { Button, IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { getSession, setCognitoUser, userPool } from "../../services/auth/auth";
import { translate, useTranslationHook } from "../../translations/traductios";
import { Visibility, VisibilityOff } from "@mui/icons-material";


export default function Login() {

  const t = useTranslationHook()

  const { language } = useContext(LanguageContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(new CognitoUser({
    Pool: userPool,
    Username: username,
  }))
  const [newPassword, setNewPassword] = useState("");
  const [requiredAttributes, setRequiredAttributes] = useState("");
  const [authError, setAuthError] = useState(undefined);
  const [status, setStatus] = useState<"login" | "mfa" | "changePassword">(
    "login"
  );
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const navigate = useNavigate();
  useEffect(() => {
    getSession()
      .then((cognitoUserSession: CognitoUserSession | undefined) => {
        // add refresh?
        if (cognitoUserSession?.isValid()) {
          navigate('/');
        }
      })
  }, [])

  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });
  useEffect(() => {
    setUser(new CognitoUser({
      Pool: userPool,
      Username: username,
    }))
  }, [username])


  const doLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    user.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        setCognitoUser(userPool.getCurrentUser())
        navigate('/');
      },
      onFailure: (err) => {
        setAuthError(err?.message || `Auth Error (${err?.name || 'Unknown'})`)
        console.log(err)
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        setRequiredAttributes(requiredAttributes)
        // store userAttributes on global variable
        setStatus("changePassword");
        setAuthError(undefined)
      },
    });
  };
  const doChangePassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    user.completeNewPasswordChallenge(newPassword, requiredAttributes, {
      onSuccess: (result) => {
        navigate('/');
      },
      onFailure: (err) => console.log(err),
    });
  };
  return (
    <>
      <div className="h-screen w-screen md:grid md:grid-cols-2">
        {/* <div className="w-full"> */}
        <div className="hidden md:block md:w-50 login-bg">
          <div className="md:w-50 flex flex-col m-auto justify-center items-center h-full">
            <img src={process.env.PUBLIC_URL + '/HAAL_logo_transparant.webp'} alt="" />
          </div>
        </div>
        <div className="w-full h-full md:w-50">
          {status === "login" && (
            <form onSubmit={(event) => doLogin(event)} className="flex flex-col h-full items-center justify-center">
              <img className="md:hidden" src={process.env.PUBLIC_URL + '/HAAL_logo_transparant.webp'} alt="" />
              <div className="flex flex-col w-50 justify-center mx-auto">
                <div className="my-2">
                  <TextField
                    className="w-full"
                    autoComplete="off"
                    name={translate("_username", language)}
                    onChange={(event) => setUsername(event.currentTarget.value)}
                    label={t("_username")} placeholder={t("_insertUsername")}
                  />
                </div>
                <div className="my-2">
                  <TextField
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    name="password"
                    onChange={(event) => setPassword(event.currentTarget.value)}
                    label={t("_password")} placeholder={t("_insertPassword")}
                  />
                </div>
                <div className="flex justify-end my-2">

                  <Button disabled={username === '' || password === ''} type="submit" variant="contained">
                    <div className="haal-text text-white font-bold py-1">{t("_confirm")}</div>
                  </Button>

                </div>
                <div className="flex justify-end my-2">
                  <a href="/forgot-password">{t("_forgotPassword")}</a>
                </div>
                {/* <div className="flex justify-end my-2">
                  <a href="/sign-on">{t("_signOn")}</a>
                </div> */}
                <div>
                  {authError !== undefined &&
                    <div className="haal-text text-red-500 font-bold">
                      {authError}
                    </div>
                  }
                </div>
              </div>
            </form>
          )}
          {status === "changePassword" && (
            <form onSubmit={(event) => doChangePassword(event)} className="flex flex-col h-full items-center justify-center">
              <div className="flex flex-col w-50 justify-center mx-auto">
                <div className="my-2">
                  <TextField
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    InputProps={{ // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    name="newPassword"
                    onChange={(event) => setNewPassword(event.currentTarget.value)}
                    label={t("_NewPassword")} placeholder={("_insertNewPassword")}
                  />
                </div>
                <div className="flex justify-end my-2">
                  <Button disabled={password === ''} type="submit" variant="contained">
                    <div className="haal-text text-white font-bold py-1">{t("_savePassword")}</div>
                  </Button>
                </div>
                <div>
                  {authError &&
                    <div className="haal-text text-red-500 font-bold">
                      {authError}
                    </div>
                  }
                </div>
              </div>
              {/*  <input name="newPassword"
                onChange={(event) => setNewPassword(event.currentTarget.value)} /> */}
            </form>
          )}
        </div>
      </div>
    </>
  );
}
