import { useMutation, useQuery } from "@apollo/client";
import ArchiveIcon from "@mui/icons-material/Archive";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import { format } from "date-fns";
import { useEffect } from "react";
import usePushNotifications from "../../hooks/usePushNotifications";
import { CaregiverNotification } from "../../model/user.model";
import {
  ARCHIVE_CAREGIVER_NOTIFICATIONS,
  ARCHIVE_CAREGIVER_NOTIFICATION_TYPE,
  LIST_CAREGIVER_NOTIFICATIONS,
  LIST_CAREGIVER_NOTIFICATIONS_TYPE
} from "../../services/bff/caregivers-queries";
import { useTranslationHook } from "../../translations/traductios";

export function NotificationList() {
  const { data, loading, refetch } =
    useQuery<LIST_CAREGIVER_NOTIFICATIONS_TYPE>(LIST_CAREGIVER_NOTIFICATIONS);
  const { lastMessage } = usePushNotifications();
  const [archiveSubscription, mutation] = useMutation<
    ARCHIVE_CAREGIVER_NOTIFICATION_TYPE,
    {
      timestamp: string;
    }
  >(ARCHIVE_CAREGIVER_NOTIFICATIONS, {
    refetchQueries: [LIST_CAREGIVER_NOTIFICATIONS]
  });
  useEffect(() => {
    if (lastMessage) {
      refetch();
    }
  }, [lastMessage, refetch]);

  const t = useTranslationHook()
  return (
    <>
      <div className="flex justify-between items-center py-3">
        <div className="haal-title text-white"> {t("_clientsNotification")} </div>
        <Tooltip title={t("_youWillOnlySee...")}>
          <InfoIcon className="menu-links"/>
        </Tooltip>
      </div>
      <div className="scroll">
      {!loading && data?.listNotifications.items.length === 0 && (
        <div className="haal-subtitle text-white text-center mt-5">
          {t("_noNotificationAvailable")}
        </div>
      )}
      {!loading &&
        data?.listNotifications.items
          // .slice(0, limit)
          .map((caregiverNotification: CaregiverNotification) => {
            return (
              <div
                key={caregiverNotification.timestamp}
                className="shadow bg-white rounded inline-flex w-full mt-3 p-2"
              >
                {/* <div className="h-full items-center">
                                            <div className="icon-wrapper h-7 w-7 bg-gradient-to-r from-red-600 to-red-500">
                                                <Link to="/"><FontAwesomeIcon icon={['fas', 'user']} className="text-white" /></Link>
                                            </div>
                                        </div> */}
                <div className="flex w-full justify-between">
                  <div className="flex flex-col">
                    <div className="ml-3 inline-grid text-black">
                      {/* <div className="haal-text-small font-bold">{caregiverNotification?.anagraphic?.name || "UNKNOWN"} {caregiverNotification?.anagraphic?.surname}</div> */}
                      {/* <div className="haal-text mt-1">Urgent</div> */}
                      <div className="haal-text-small font-bold">
                        {caregiverNotification.title}
                        <p>{caregiverNotification.description}</p>
                      </div>
                      <div className="haal-text-small">
                        {t( "_on")}:{" "}
                        {format(
                          new Date(caregiverNotification.timestamp),
                          "dd-MM-yyyy HH:mm:ss"
                        ) || t("_unknown")}
                      </div>
                    </div>
                  </div>

                  <Tooltip
                    className="haal-text-color-primary"
                    title={t("_archive")}
                    onClick={() => archiveSubscription({
                      variables: {
                        timestamp: caregiverNotification.timestamp
                      }
                    })}
                  >
                    <ArchiveIcon />
                  </Tooltip>
                </div>
              </div>
            );
          })}
      </div>
      {/* {this.state.caregiverNotifications.length > limit && (
        <div className="flex justify-center mt-3">
          <p>{this.state.caregiverNotifications.length - limit} more ...</p>
        </div>
      )} */}


      {/* {!isOpen && (
        <div className="my-3">
          <div className="flex items-center justify-center w-7 h-7 rounded-full bg-gradient-to-r from-red-600 to-red-500">
            <div className="haal-title text-white">
              {data?.listNotifications?.items?.length}
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}
