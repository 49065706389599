import { useMutation } from "@apollo/client"
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, IconButton, InputAdornment, Tooltip } from "@mui/material"
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"
import { useState, FormEvent } from "react"
import { Device, DeviceName } from "../../../model/device.model"
import { ASSIGN_DEVICE } from "../../../services/bff/device-mutation"
import { ivalidateApolloCache } from "../../../services/GraphQL/apollo-client"
import { modalMuiStyle } from "../../../services/haal-mui-theme/haal-mui-theme"
import { HallSnackBar } from "../../shared/HallSnackBar/HallSnackBar"
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslationHook } from "../../../translations/traductios"

export function CreateUpdateDevice(props: {
    clientId: String
    close: () => void
}) {

    const t = useTranslationHook()
    const clientId = props.clientId
    // FORM STATE
    const [deviceId, setDeviceId] = useState<Device["deviceId"]>('');
    /* Controllo che tra i dispositivi del cliebt c'è il dispositivi presente nei query params */
    const [deviceName, setDeviceName] = useState<DeviceName | undefined>(undefined);
    const [assignedOn, setAssignedOn] = useState<Date>(new Date());
    const invalidateModal = () => {
        setDeviceId('')
        setDeviceName(undefined)
        setAssignedOn(new Date())
    }
    const [snackBar, setSnackBar] = useState<{
        opened: boolean,
        message: string
    } | undefined>(undefined)
    let [assignDevice, { data, loading, error }] = useMutation(ASSIGN_DEVICE, {
        onCompleted: (data) => {
            ivalidateApolloCache()
            invalidateModal()
            setSnackBar({
                opened: true,
                message: t("_deviceCreated")
            })
            setTimeout(() => {
                props.close()
            }, 2500)
            data = undefined
        },
        onError: (error) => {
            setSnackBar({
                opened: true,
                message: t("_errorCreatingDevice")
            })
        }
    });
    // Disabilito il form del device se stò effettuando l'operazione di assginDevice
    const disableUI = loading;
    const isNotValid = deviceId === "" || deviceName === undefined

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const start_time = format(assignedOn as Date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
        const device = {
            clientId,
            deviceId: deviceName === DeviceName.WHIZ_PAD ? `WhizPad_RC-${deviceId}`: deviceId,
            deviceName,
            start_time
        }
        assignDevice(({
            variables: {
                ...device
            }
        }));
    }
    return (
        <div>
            <Box sx={modalMuiStyle} style={{ zIndex: 999 }}>
                <div>
                    <div className="flex justify-between items-center my-3" >
                        {/* <div className="flex flex-col" style={{maxWidth: "70%"}}> */}
                        <div className="haal-title">{t("_assignDevice")}</div>
                        {/* </div> */}
                        <Button>
                            <CloseIcon onClick={() => {
                                props.close()
                            }} />
                        </Button>
                    </div>
                    {/* <div className="haal-subtitle mb-5"><strong>Code: </strong>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vel, commodi!.</div> */}

                    <form onSubmit={(event) => handleSubmit(event)} >
                        <div className="grid grid-cols-2 gap-4">
                            <FormControl fullWidth>
                                <InputLabel id="deviceName">{t("_selectDeviceType")}</InputLabel>
                                <Select
                                    required
                                    className="w-full"
                                    disabled={disableUI}
                                    labelId="deviceName"
                                    id="deviceName"
                                    label={t("_deviceName")}
                                    type="text"
                                    value={deviceName}
                                    onChange={(event) => {
                                        setDeviceName(event.target.value as DeviceName)
                                    }}
                                >
                                    <MenuItem value={undefined}>{t("_selectOne")}</MenuItem>
                                    <MenuItem value={DeviceName.SENSARA}>{t("_sensara")}</MenuItem>
                                    <MenuItem value={DeviceName.COMPAAN}>{t("_compaan")}</MenuItem>
                                    <MenuItem value={DeviceName.KOMPY_PICO}>{t("_kompyPico")}</MenuItem>
                                    <MenuItem value={DeviceName.WHIZ_PAD}>{t("_whizPad")}</MenuItem>
                                    <MenuItem value={DeviceName.WHIZ_TOYS}>{t("_whizToys")}</MenuItem>
                                    <MenuItem value={DeviceName.COGVISAI}>{t("_cogvisAI")}</MenuItem>
                                    <MenuItem value={DeviceName.TIPR}>{t("_tipr")}</MenuItem>
                                    <MenuItem value={DeviceName.TESSA}>{t("_tessa")}</MenuItem>
                                </Select>
                            </FormControl>


                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label={t("_assignedOn(MM/dd/YYY):")}
                                    disabled={disableUI}
                                    inputFormat="dd/MM/yyyy"
                                    value={assignedOn}
                                    onChange={(event) => {
                                        if (event && event instanceof Date) {
                                            setAssignedOn(event)
                                        }
                                    }
                                    }
                                    renderInput={(assignedOn) => <TextField name="assignedOn" {...assignedOn} />}
                                />
                            </LocalizationProvider>
                            <TextField
                                autoComplete="off"
                                required
                                disabled={disableUI || !deviceName}
                                className="w-full"
                                label={t("_code")} placeholder={t("_insertDeviceCode")}
                                name="deviceName"
                                value={deviceId}
                                InputProps={ deviceName === DeviceName.WHIZ_PAD ? {
                                    startAdornment: <InputAdornment position="start">WhizPad_RC-</InputAdornment>,
                                } : {}}
                                onChange={(event) => setDeviceId(event.target.value)}
                                /* InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vel, commodi!.">
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>

                                        </InputAdornment>
                                    )
                                }} */ />
                            <div className="flex items-center">

                                {!deviceName && (
                                    <div className="haal-subtitle font-bold">{t("_selectDeviceToKnowMore...")}</div>
                                )}
                                {deviceName && deviceName === DeviceName.SENSARA && (
                                    <div className="haal-subtitle font-bold">{t("_sensaraDeviceCode")}</div>
                                )}
                                {deviceName && deviceName === DeviceName.COMPAAN && (
                                    <div className="haal-subtitle font-bold">{t("_compaanDeviceCode")}</div>
                                )}
                                {deviceName && deviceName === DeviceName.KOMPY_PICO && (
                                    <div className="haal-subtitle font-bold">{("_kompyPicoDeviceCode")}</div>
                                )}
                                {deviceName && deviceName === DeviceName.WHIZ_PAD && (
                                    <div className="haal-subtitle font-bold">{t("_whizPadDeviceCode")}</div>
                                )}
                                {deviceName && deviceName === DeviceName.WHIZ_TOYS && (
                                    <div className="haal-subtitle font-bold">{t("_whizToysDeviceCode")}</div>
                                )}
                                {deviceName && deviceName === DeviceName.TESSA && (
                                    <div className="haal-subtitle font-bold">{t("_tessaDeviceCode")}</div>
                                )}
                            </div>

                        </div>
                        <div className="w-full flex justify-end my-3">
                            <Button disabled={isNotValid || disableUI} type="submit" variant="contained">
                                {t("_assign")}
                            </Button>
                        </div>
                    </form>


                </div>
            </Box >
            {snackBar && snackBar.opened &&
                <HallSnackBar
                    message={snackBar.message}
                    onClose={() => {
                        setSnackBar({
                            message: '',
                            opened: false,
                        })
                    }} />
            }
        </div>
    )
}