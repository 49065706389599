import translations_IT from './translations_IT.json';
import translations_EN from './translations_EN.json';
import translations_TW from './translations_TW.json';
import translations_NL from './translations_NL.json';
import { useCallback, useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
type Translation = {
    [key: string]: string
}
type Interpolation = {
    [key: string]: string
}
export const translate = (label: string, lang: string = 'EN', args: Interpolation = {}): string => {
    let translation = "Unknown Translation"
    let dataSource: Translation = {};
    switch (lang) {
        case 'IT':
            dataSource = translations_IT
            break;
        case 'TW':
            dataSource = translations_TW
            break;
        case 'NL':
            dataSource = translations_NL
            break;
        case 'EN':
            dataSource = translations_EN
            break;
    }
    if (dataSource) {
        // @ts-ignore
        translation = dataSource[label] || translations_EN[label];
    } else {
        // @ts-ignore
        translation = translations_EN[label]
    }

    // Prints "name Jean-Luc Picard" followed by "rank Captain"
    Object.entries(args).forEach(entry => {
        const [key, value] = entry;
        translation = translation.replace(`{{${key}}}`, value)
    });
    return translation
}

export const translateLang = (lang: string) => (label: string, args: Interpolation = {}) => translate(label, lang, args);


export function useTranslationHook() {
    const { language } = useContext(LanguageContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const func = useCallback(translateLang(language), [language])
    return func;
}